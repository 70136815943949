/**
 * Text React component
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import * as React from "react";
import ReactMarkdown from "react-markdown";

import { ClassList, StyleSheet, TTextProps } from "../";

import { FONT_SIZE_CLASS, FONT_SIZE_CLASS_default } from "../Enums/FONT_SIZE_CLASS";
import { TEXT_ALIGN_CLASS } from "../Enums/TEXT_ALIGN_CLASS";
import { getStringEnumValue } from "../Functions/enumHelpers";
import { TElementProps } from "../Types/TElementProps";
import { FORCE_RENDER_default, IBaseProps, IEventProps, IRenderProps } from "./props";
import { useStyleSheetRegistry } from "../Hooks/useStyleSheetRegistry";
import { TEXT_OVERFLOW, TEXT_OVERFLOW_default } from "../Enums/TEXT_OVERFLOW";
import { COMPONENT_MODE, SCHEMA_VALUE_TYPE } from "@hexio_io/hae-lib-blueprint";
import { TTextPropsModelNode } from "../SharedSchemas/Text";
import { Key } from "ts-key-enum";
import { isValidObject } from "@hexio_io/hae-lib-shared";

/**
 * Markdown inline types
 */
export const markdownInlineTypes = [
	"root",
	"text",
	"break",
	"emphasis",
	"strong",
	"delete",
	"link",
	"linkReference",
	"image",
	"imageReference",
	"inlineCode"
];

/**
 * Text props
 */
export interface ITextProps extends IBaseProps, IRenderProps, IEventProps, TTextProps {
	/** Default tag name, used when markdown is not enabled */
	tagName?: string;

	/** Props model node */
	propsModelNode?: TTextPropsModelNode;
}

/**
 * Text component
 */
export const Text: React.FunctionComponent<ITextProps> = (props) => {
	const {
		value,
		align,
		style,
		fontSize,
		foregroundColor,
		overflow,
		markdown,
		// html,
		componentPath,
		componentMode,
		forceRender = FORCE_RENDER_default,
		tagName = "div",
		propsModelNode,
		onClick
	} = props;

	const html = false; // Currently disabled

	const elementRef = React.useRef<HTMLElement>();
	const innerElementRef = React.useRef<HTMLElement>();

	const elementProps: TElementProps = {
		ref: elementRef
	};

	const innerElementProps: TElementProps = {
		ref: innerElementRef,
		className: markdown || html ? "text__inner rich-text" : "text__inner"
	};

	// Editable

	const editable = React.useMemo(() => {
		return (
			componentMode === COMPONENT_MODE.EDIT &&
			propsModelNode?.props.value.type === SCHEMA_VALUE_TYPE.CONST &&
			!markdown &&
			!html
		);
	}, [ componentMode, propsModelNode?.props.value.type, markdown, html ]);

	const saveCurrentValue = React.useMemo(() => {
		if (!editable) {
			return;
		}

		const valueConst = propsModelNode.props.value.constant;

		return (notify = false) => {
			if (isValidObject(innerElementRef.current)) {
				const { textContent } = innerElementRef.current;

				if (textContent !== value) {
					valueConst.schema.setValue(valueConst, textContent, notify);
				}
			}
		};
	}, [ value, editable, innerElementRef.current ]);

	if (editable) {
		innerElementProps.contentEditable = true;
		innerElementProps.suppressContentEditableWarning = true;

		innerElementProps.onInput = () => saveCurrentValue();
		innerElementProps.onBlur = () => saveCurrentValue(true);
		innerElementProps.onKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
			if (event.key === Key.Enter) {
				event.preventDefault();
			}
		};
	}

	// Class list

	const { classList, idClassName } = ClassList.getElementClassListAndIdClassName("text", componentPath, {
		componentClassList: props.classList
	});

	classList.add(getStringEnumValue(TEXT_ALIGN_CLASS, align));

	if (fontSize && fontSize in FONT_SIZE_CLASS) {
		classList.add(getStringEnumValue(FONT_SIZE_CLASS, fontSize, FONT_SIZE_CLASS_default));
	}

	if (style) {
		Object.keys(style)
			.filter((item) => style[item])
			.forEach((item) => {
				classList.add(`text--style-${item}`);
			});
	}

	const overflowValue = getStringEnumValue(TEXT_OVERFLOW, overflow, TEXT_OVERFLOW_default);

	classList.addModifiers({
		overflow: overflowValue
	});

	const styleSheetRegistry = useStyleSheetRegistry();

	const styleSheet = React.useMemo(() => {
		const result = new StyleSheet();

		// Foreground color

		if (foregroundColor) {
			result.addColorProperties({
				selector: `.${idClassName}`,
				name: "element-foreground-color",
				value: foregroundColor
			});
		}

		// Font size

		if (fontSize && !(fontSize in FONT_SIZE_CLASS)) {
			result.addString(`.${idClassName}`, `font-size: ${fontSize} !important;`);
		}

		return result;
	}, [ idClassName, foregroundColor, fontSize ]);

	styleSheetRegistry.add(idClassName, styleSheet);

	// Markdown

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let markdownProps: any;

	if (markdown) {
		markdownProps = {
			source: value,
			escapeHtml: !html,
			unwrapDisallowed: true
			// linkTarget: (url, _text, _title) => isExternal(url) ? "_blank" : undefined
		};

		if (!markdown.blocks) {
			markdownProps.allowedTypes = markdownInlineTypes;
		}
	}

	// Overflow & scrolling

	if (overflowValue === TEXT_OVERFLOW.ELLIPSIS) {
		elementProps.title = value;
	}

	React.useEffect(() => {
		const element = elementRef.current;
		const innerElement = innerElementRef.current;

		if (!element) {
			return;
		}

		function clearStyles() {
			element.style.removeProperty("--element-inner-height");

			if (innerElement) {
				innerElement.style.maxHeight = "";
			}
		}

		if (overflowValue === TEXT_OVERFLOW.NONE || !innerElement) {
			clearStyles();

			return;
		}

		const resizeObserver = new ResizeObserver((entries) => {
			entries.forEach((item) => {
				const isInnerElement = item.target === innerElement;

				if (entries.length > 1 || isInnerElement) {
					clearStyles();
				}

				if (isInnerElement) {
					innerElement.style.opacity = "0";

					setTimeout(() => {
						element.style.setProperty(
							"--element-inner-height",
							`${innerElement.getBoundingClientRect().height}px`
						);

						innerElement.style.maxHeight = "100%";
						innerElement.style.opacity = "";
					});
				}
			});
		});

		resizeObserver.observe(element, { box: "border-box" });
		resizeObserver.observe(innerElement, { box: "border-box" });

		return () => {
			resizeObserver.disconnect();
		};
	}, [ value, overflowValue ]);

	if (!value && !forceRender) {
		return null;
	}

	// Event handlers

	if (typeof onClick === "function") {
		classList.add("text--event-click");

		elementProps.tabIndex = 0;

		elementProps.onClick = () => {
			onClick();
		};
	}

	// Resolve Class list

	elementProps.className = classList.toClassName();

	return React.createElement(
		tagName,
		elementProps,
		markdown
			? React.createElement(tagName, innerElementProps, <ReactMarkdown {...markdownProps} />)
			: html
			? React.createElement(tagName, {
					...innerElementProps,
					dangerouslySetInnerHTML: { __html: value }
			  })
			: React.createElement(tagName, innerElementProps, value)
	);
};

/**
 * Button Group HAE component props
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP } from "@hexio_io/hae-lib-blueprint";

import {
	dimensionPattern,
	dimensionWithConstantPattern,
	ForegroundColorSchema,
	getValuesFromStringEnum,
	ORIENTATION,
	ORIENTATION_default,
	ORIENTATION_opts,
	propGroups,
	SPACING,
	termsEditor as HAELibComponentsTerms
} from "@hexio_io/hae-lib-components";

import { termsEditor } from "../../terms";
import { HAEComponentButton_Props } from "../Button/props";

/**
 * Button Group Item Inherited props
 */
const ButtonGroupItemInheritedProps = {
	itemOverrideStyle: BP.Prop(
		BP.Boolean({
			...termsEditor.schemas.buttonGroup.contentItemUseParentStyle,
			default: true,
			fallbackValue: true,
			constraints: {
				required: true
			}
		}),
		0
	)
};

/**
 * Button Group Item Inherited props type
 */
export type TButtonGroupItemInheritedProps = typeof ButtonGroupItemInheritedProps;

/**
 * Button Group props
 */
export const HAEComponentButtonGroup_Props = {
	content: BP.Prop(
		BP.ComponentList({
			...termsEditor.schemas.buttonGroup.content,
			alias: "buttonGroupContent",
			allowComponentNames: [ "button", "menuButton" ],
			inheritedProps: {
				...ButtonGroupItemInheritedProps
			}
		}),
		0,
		propGroups.common
	),

	typeData: BP.Prop(
		BP.OneOf({
			...termsEditor.schemas.buttonGroup.typeData,
			typeValueOpts: {
				...termsEditor.schemas.buttonGroup.typeData
			},
			defaultType: "NONE",
			types: {
				NONE: {
					...termsEditor.schemas.buttonGroup.typeDataValues.none,
					value: BP.Void({})
				},
				TOGGLE: {
					...termsEditor.schemas.buttonGroup.typeDataValues.toggle,
					value: BP.Void({})
				},
				SWITCH: {
					...termsEditor.schemas.buttonGroup.typeDataValues.switch,
					value: BP.Object({
						props: {
							activeRequired: BP.Prop(
								BP.Boolean({
									...termsEditor.schemas.buttonGroup.activeRequired,
									default: false,
									fallbackValue: false,
									constraints: {
										required: true
									}
								}),
								10
							)
						},
						editorOptions: {
							layoutType: "passthrough"
						}
					})
				}
			}
		}),
		10,
		propGroups.common
	),

	orientation: BP.Prop(
		BP.Enum.String({
			...HAELibComponentsTerms.schemas.common.orientation,
			options: getValuesFromStringEnum(
				ORIENTATION,
				HAELibComponentsTerms.schemas.common.orientationValues,
				ORIENTATION_opts
			),
			default: ORIENTATION_default,
			fallbackValue: ORIENTATION_default,
			constraints: {
				required: true
			},
			editorOptions: {
				controlType: "buttons"
			}
		}),
		20,
		propGroups.common
	),

	styleData: BP.Prop(HAEComponentButton_Props.styleData.schema, 0, propGroups.style),

	borderRadiusGroup: BP.Prop(
		BP.Boolean({
			...termsEditor.schemas.buttonGroup.borderRadiusGroup,
			default: true,
			fallbackValue: true,
			constraints: {
				required: true
			}
		}),
		10,
		propGroups.style
	),

	separatorColor: BP.Prop(
		ForegroundColorSchema({
			...termsEditor.schemas.buttonGroup.separatorColor,
			alias: "foregroundColor_buttonGroupSeparator",
			default: "TRANSPARENT"
		}),
		20,
		propGroups.style
	),

	separatorSize: BP.Prop(
		BP.StringWithConst({
			...termsEditor.schemas.buttonGroup.separatorSize,
			constants: getValuesFromStringEnum(SPACING, HAELibComponentsTerms.schemas.common.spacingValues),
			default: "",
			constraints: {
				...HAELibComponentsTerms.schemas.common.spacingConstraints,
				pattern: dimensionWithConstantPattern
			}
		}),
		30,
		propGroups.style
	)
};

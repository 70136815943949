/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	ISchemaFlowNodeTypeOutputDefinitionMap,
	TGetBlueprintSchemaSpec
} from "@hexio_io/hae-lib-blueprint";
import { NODE_OUTPUTS, NODE_OUTPUT_NAMES, NODE_TYPES } from "./BlueprintNode";
import { termsEditor } from "../../terms";

export const BlueprintTransformNode = {
	name: NODE_TYPES.TRANSFORM,
	label: "Transform",
	icon: "mdi/language-javascript",
	editorOptions: {
		displayInPalette: true
	},
	opts: BP.Object({
		label: termsEditor.nodes.common.options.label,
		description: termsEditor.nodes.common.options.description,
		props: {
			code: BP.Prop(
				BP.String({
					label: termsEditor.nodes.transform.code.label,
					description: termsEditor.nodes.transform.code.description,
					allowInterpolation: false,
					allowTranslate: false,
					editorOptions: {
						controlType: "codeJavaScript"
					}
				})
			)
		}
	}),
	resolveOutputs: (): ISchemaFlowNodeTypeOutputDefinitionMap => ({
		[NODE_OUTPUT_NAMES.ON_SUCCESS]: NODE_OUTPUTS.ON_SUCCESS,
		[NODE_OUTPUT_NAMES.ON_ERROR]: NODE_OUTPUTS.ON_ERROR,
		[NODE_OUTPUT_NAMES.ON_FAIL]: NODE_OUTPUTS.ON_FAIL
	})
};

export type TBlueprintTransformNodeOptsSchemaSpec = TGetBlueprintSchemaSpec<
	typeof BlueprintTransformNode["opts"]
>;

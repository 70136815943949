/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	ISchemaFlowNodeTypeOutputDefinitionMap,
	TGetBlueprintSchemaSpec
} from "@hexio_io/hae-lib-blueprint";
import { NODE_OUTPUTS, NODE_OUTPUT_NAMES, NODE_TYPES } from "./BlueprintNode";

export const BlueprintStartNode = {
	name: NODE_TYPES.START,
	label: "Start",
	icon: "mdi/play",
	opts: BP.Const.Object({ props: {} }),
	editorOptions: {
		displayInPalette: false
	},
	resolveOutputs: (): ISchemaFlowNodeTypeOutputDefinitionMap => ({
		[NODE_OUTPUT_NAMES.ON_START]: NODE_OUTPUTS.ON_START
	})
};

export type TBlueprintStartNodeOptsSchemaSpec = TGetBlueprintSchemaSpec<typeof BlueprintStartNode["opts"]>;

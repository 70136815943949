/**
 * Popup React component
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";
import ReactDOM from "react-dom";

import FocusTrap from "focus-trap-react";

import { Key } from "ts-key-enum";

import { isBrowser, isFunction, isNonEmptyString } from "@hexio_io/hae-lib-shared";

import { IViewport, Viewport } from "../Classes/Viewport";
import { ClassList } from "../Classes/ClassList";
import { POPUP_POSITION, POPUP_POSITION_default, POPUP_POSITION_string } from "../Enums/POPUP_POSITION";
import { getStringEnumValue } from "../Functions/enumHelpers";

import { HAEComponentMainContext } from "../HAEComponent/HAEComponentContext";
import { IBaseProps } from "./props";

/**
 * Resolves offset style
 *
 * @param element Target element
 * @param anchor Anchor element
 * @param width Width
 * @param viewport Viewport element
 * @param positionValue Anchor
 * @param anchorVisible Anchor visible
 */
function fixPositionStyle(
	element: HTMLElement,
	anchor: HTMLElement,
	width: number,
	viewport: IViewport,
	positionValue: POPUP_POSITION,
	anchorVisible: boolean
): boolean {
	element.style.width = "";
	element.style.minWidth = "";
	element.style.maxWidth = "";

	const elementRect = element.getBoundingClientRect();
	const { width: elementWidth } = elementRect;

	let { height: elementHeight } = elementRect;

	const elementStyle = getComputedStyle(element);

	if (elementWidth === 0 || elementHeight === 0 || elementStyle.getPropertyValue("display") === "none") {
		return false;
	}

	// Not set currently, but keeping it here for possible future use
	const elementViewportMargin = 0;

	const { left: rootLeft, top: rootTop } = viewport.getRootElement().getBoundingClientRect();

	const { height: viewportHeight, width: maxWidth } = viewport.getProperties();

	// @todo Needs fix for horizontal scrolling (& check if anchor element has a fixed position)
	//const scrollLeft = viewport.getScrollLeft();
	const scrollTop = viewport.getScrollTop();

	const anchorRect = anchor.getBoundingClientRect();

	const anchorLeft = anchorRect.left - rootLeft;
	const anchorLeftAndWidth = anchorLeft + anchorRect.width;
	const anchorWidth =
		anchorLeftAndWidth < maxWidth ? anchorRect.width : anchorRect.width - (anchorLeftAndWidth - maxWidth);

	const anchorTop = anchorRect.top - rootTop;
	const anchorBottom = anchorRect.bottom - rootTop;

	const spaceTop = anchorTop - scrollTop;
	const spaceBottom = viewportHeight - (anchorBottom - scrollTop);

	let left: number;
	let top: number;
	let maxHeight: number;

	if (anchorVisible) {
		maxHeight =
			spaceTop > spaceBottom ? spaceTop - elementViewportMargin : spaceBottom - elementViewportMargin;

		if (elementHeight > maxHeight) {
			elementHeight = maxHeight;
		}
	}

	const elementHeightWithMargin = elementHeight + elementViewportMargin;

	// Top coordinate

	switch (positionValue) {
		case POPUP_POSITION.TOP:
		case POPUP_POSITION.TOP_LEFT:
		case POPUP_POSITION.TOP_CENTER:
		case POPUP_POSITION.TOP_RIGHT: {
			top =
				spaceTop >= elementHeightWithMargin
					? anchorTop - elementHeight
					: spaceBottom >= elementHeightWithMargin
					? anchorBottom
					: scrollTop + elementViewportMargin;

			break;
		}

		case POPUP_POSITION.BOTTOM:
		case POPUP_POSITION.BOTTOM_LEFT:
		case POPUP_POSITION.BOTTOM_CENTER:
		case POPUP_POSITION.BOTTOM_RIGHT: {
			// top = (spaceBottom >= elementHeightWithMargin) ?
			// 	anchorBottom :
			// 	(
			// 		(spaceTop >= elementHeightWithMargin) ?
			// 			(anchorTop - elementHeight) :
			// 			(scrollTop + viewportHeight - elementHeightWithMargin)
			// 	);

			top = anchorBottom;

			break;
		}
	}

	// Left coordinate

	switch (positionValue) {
		case POPUP_POSITION.TOP:
		case POPUP_POSITION.TOP_LEFT:
		case POPUP_POSITION.BOTTOM:
		case POPUP_POSITION.BOTTOM_LEFT: {
			left = anchorLeft;

			break;
		}

		case POPUP_POSITION.TOP_CENTER:
		case POPUP_POSITION.BOTTOM_CENTER: {
			left = anchorLeft - (elementWidth - anchorWidth) / 2 - elementViewportMargin;

			break;
		}

		case POPUP_POSITION.TOP_RIGHT:
		case POPUP_POSITION.BOTTOM_RIGHT: {
			left = anchorLeft - (elementWidth - anchorWidth) - elementViewportMargin;

			break;
		}
	}

	left = Math.max(Math.min(left, anchorLeft, maxWidth - width), 0);

	if (left === null || top === null) {
		return false;
	}

	element.style.left = `${left}px`;
	element.style.top = `${top}px`;

	if (positionValue === POPUP_POSITION.TOP || positionValue === POPUP_POSITION.BOTTOM) {
		element.style.width = `${width}px`;
	} else {
		element.style.minWidth = `${width}px`;
	}

	element.style.maxWidth = `${maxWidth}px`;
	element.style.maxHeight = maxHeight ? `${maxHeight}px` : "";

	return true;
}

/**
 * Popup State
 */
export interface IPopupState {
	/** Popup anchor */
	anchor?: HTMLElement;

	/** Popup visibility */
	visible?: boolean;

	/** Popup width */
	width?: number;
}

/**
 * Initial Popup State
 */
export const initialPopupState: IPopupState = {
	anchor: null,
	visible: false,
	width: null
};

/**
 * Popup ref props
 */
export interface IPopupRefProps {
	/**
	 * Shows popup
	 *
	 * @param target Target anchor element
	 */
	show: (target: HTMLElement) => void;

	/**
	 * Hides popup
	 */
	hide: () => void;

	/**
	 * Toggles popup
	 */
	toggle: (target: HTMLElement) => boolean;

	/**
	 * Checks whether popup is visible
	 */
	isVisible: () => boolean;

	/**
	 * Fixes popup position
	 */
	fixPosition: () => void;

	/**
	 * Returns element
	 */
	element: HTMLDivElement;
}

/**
 * Popup props
 */
export interface IPopupProps extends IBaseProps {
	/** Position */
	position?: POPUP_POSITION_string;

	/** Role */
	role?: React.AriaRole;

	/** Show delay in milliseconds */
	showDelay?: number;

	/** Hide on Esc key press, defaults to "true" */
	hideOnEsc?: boolean;

	/** Hide on click outside of element, defaults to "true" */
	hideOnClickOutside?: boolean;

	/** Focus, defaults to "false", can be selector as well */
	focus?: boolean | string;

	/** Force anchor to be always visible (not covered by popup) */
	anchorVisible?: boolean;

	/** Children */
	children?: unknown;

	/** Key Down Capture handler */
	onKeyDownCapture?: React.KeyboardEventHandler<HTMLDivElement>;

	/** Key Up Capture handler */
	onKeyUpCapture?: React.KeyboardEventHandler<HTMLDivElement>;

	/** Show handler */
	onShow?: () => void;

	/** Hide handler */
	onHide?: () => void;

	/** Active some element parent */
	setActive?: (value: boolean) => void;
}

/**
 * Popup component
 */
export const Popup = React.forwardRef<IPopupRefProps, IPopupProps>((props, ref) => {
	const {
		position,
		role,
		showDelay = 0,
		hideOnEsc = true,
		hideOnClickOutside = true,
		focus = false,
		anchorVisible = false,
		children = null,
		componentPath,
		onKeyDownCapture,
		onKeyUpCapture,
		onShow,
		onHide,
		setActive
	} = props;

	const componentMainContext = React.useContext(HAEComponentMainContext);
	const { viewport } = componentMainContext;

	const [ state, setState ] = React.useReducer(
		(prevState: IPopupState, nextState: IPopupState) => ({ ...prevState, ...nextState }),
		initialPopupState
	);

	const elementRef = React.useRef<HTMLDivElement>();

	const { classList } = ClassList.getElementClassListAndIdClassName("popup", componentPath, {
		componentClassList: props.classList
	});

	classList.addModifiers({
		visible: state.visible,
		hidden: !state.visible
	});

	const positionValue = getStringEnumValue(POPUP_POSITION, position, POPUP_POSITION_default);

	const getWidthFromAnchor = React.useCallback(
		(anchor: HTMLElement) => {
			if (
				anchor &&
				(positionValue === POPUP_POSITION.TOP ||
					positionValue === POPUP_POSITION.TOP_LEFT ||
					positionValue === POPUP_POSITION.TOP_CENTER ||
					positionValue === POPUP_POSITION.TOP_RIGHT ||
					positionValue === POPUP_POSITION.BOTTOM ||
					positionValue === POPUP_POSITION.BOTTOM_LEFT ||
					positionValue === POPUP_POSITION.BOTTOM_CENTER ||
					positionValue === POPUP_POSITION.BOTTOM_RIGHT)
			) {
				const { width } = anchor.getBoundingClientRect();

				if (Number.isFinite(width)) {
					return width;
				}
			}

			return null;
		},
		[ positionValue ]
	);

	let timeout: NodeJS.Timeout;

	// Exposed methods

	const show = React.useCallback(
		(target: HTMLElement) => {
			if (timeout) {
				clearTimeout(timeout);
			}

			if (!target) {
				return;
			}

			const newState: IPopupState = {
				anchor: target,
				width: getWidthFromAnchor(target) || initialPopupState.width
			};

			if (!showDelay) {
				newState.visible = true;
			}
			// Already visible "somewhere else" - reset
			else if (state.visible) {
				newState.visible = initialPopupState.visible;
			}

			setState(newState);

			if (showDelay) {
				timeout = setTimeout(() => {
					setState({ visible: true });
				}, showDelay);
			}
		},
		[ showDelay, state.visible, timeout, getWidthFromAnchor ]
	);

	const hide = React.useCallback(() => {
		if (showDelay && timeout) {
			clearTimeout(timeout);
		}

		setState({ anchor: initialPopupState.anchor });
	}, [ showDelay, timeout ]);

	const isVisible = React.useCallback(() => state.visible, [ state.visible ]);

	const toggle = React.useCallback(
		(target: HTMLElement) => {
			if (!isVisible()) {
				show(target);

				return true;
			}

			hide();

			return false;
		},
		[ state.visible, timeout ]
	);

	const fixPosition = React.useCallback(() => {
		if (!state.anchor || !state.visible || !elementRef.current) {
			return;
		}

		if (
			!fixPositionStyle(
				elementRef.current,
				state.anchor,
				state.width,
				viewport,
				positionValue,
				anchorVisible
			)
		) {
			setState({ anchor: initialPopupState.anchor });
		}
	}, [
		elementRef.current,
		state.anchor,
		state.visible,
		state.width,
		viewport,
		positionValue,
		anchorVisible
	]);

	React.useImperativeHandle(ref, () => ({
		show,
		hide,
		toggle,
		isVisible,
		fixPosition,
		element: elementRef.current
	}));

	// Show popup logic

	React.useEffect(() => {
		if (!elementRef.current || !state.anchor || !state.visible) {
			return;
		}

		fixPosition();

		elementRef.current.style.opacity = "1";

		function _windowEditorViewportUpdateHandler() {
			const anchorWidth = getWidthFromAnchor(state.anchor);

			if (anchorWidth) {
				setState({ width: anchorWidth });
			}

			fixPosition();
		}

		function _windowEditorViewportScrollHandler() {
			fixPosition();
		}

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		function _windowComponentContainerScrollHandler(event: any) {
			const { idClassName } = event.detail;

			if (!state.anchor) {
				return;
			}

			if (!idClassName || !!state.anchor.closest(`.${idClassName}`)) {
				hide();
				// fixPosition(); // buggy
			}
		}

		window.addEventListener(Viewport.UPDATE_EVENT_TYPE, _windowEditorViewportUpdateHandler);
		window.addEventListener(Viewport.SCROLL_EVENT_TYPE, _windowEditorViewportScrollHandler);
		window.addEventListener("hae_component_container_scroll", _windowComponentContainerScrollHandler);

		let _windowKeyDownHandler: (event: KeyboardEvent) => void;
		let _windowClickHandler: (event: MouseEvent) => void;

		if (hideOnEsc) {
			_windowKeyDownHandler = (event) => {
				if (event.key === Key.Escape) {
					hide();
				}
			};

			window.addEventListener("keydown", _windowKeyDownHandler);
		}

		if (hideOnClickOutside) {
			_windowClickHandler = (event) => {
				const { target } = event;

				if (
					target instanceof Element &&
					!elementRef.current.contains(target) &&
					!state.anchor.contains(target)
				) {
					hide();
					if (isFunction(setActive)) {
						setActive(false);
					}
				}
			};

			window.addEventListener("click", _windowClickHandler);
		}

		if (isFunction(onShow)) {
			onShow();
		}

		return () => {
			window.removeEventListener(Viewport.UPDATE_EVENT_TYPE, _windowEditorViewportUpdateHandler);
			window.removeEventListener(Viewport.SCROLL_EVENT_TYPE, _windowEditorViewportScrollHandler);
			window.removeEventListener(
				"hae_component_container_scroll",
				_windowComponentContainerScrollHandler
			);

			if (_windowKeyDownHandler) {
				window.removeEventListener("keydown", _windowKeyDownHandler);
			}

			if (_windowClickHandler) {
				window.removeEventListener("click", _windowClickHandler);
			}
		};
	}, [ state.visible, onShow, viewport, positionValue, getWidthFromAnchor, fixPosition ]); // state.anchor omitted on purpose

	// Hide popup logic

	React.useEffect(() => {
		if (state.anchor || !state.visible) {
			return;
		}

		setState({ visible: initialPopupState.visible });

		if (isFunction(onHide)) {
			onHide();
		}
	}, [ state.anchor, onHide ]); // state.visible omitted on purpose

	const visible = state.visible && isBrowser();

	React.useEffect(() => {
		if (
			elementRef.current &&
			visible &&
			isNonEmptyString(focus) &&
			(!document.activeElement || !elementRef.current.contains(document.activeElement))
		) {
			const target = elementRef.current.querySelector(focus);

			if (target instanceof HTMLElement && isFunction(target.focus)) {
				target.focus();
			}
		}
	}, [ elementRef.current, visible ]);

	let element = (
		<div
			ref={elementRef}
			role={role}
			className={classList.toClassName()}
			tabIndex={-1}
			aria-modal={true}
			onKeyDownCapture={onKeyDownCapture}
			onKeyUpCapture={onKeyUpCapture}
		>
			{focus && visible ? (
				<button type="button" className="popup__focus-button" onClick={() => hide()} />
			) : null}
			<div className="popup__content">{children}</div>
		</div>
	);

	if (focus && visible) {
		element = (
			<FocusTrap
				active={focus && visible}
				focusTrapOptions={{
					escapeDeactivates: hideOnEsc,
					clickOutsideDeactivates: hideOnClickOutside,
					allowOutsideClick: true
				}}
			>
				{element}
			</FocusTrap>
		);
	}

	return visible ? ReactDOM.createPortal(element, viewport.getRootElement()) : element;
});

/**
 * Custom Chart HAE component
 *
 * @package hae-ext-components-pro
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5flow from "@amcharts/amcharts5/flow";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import * as am5map from "@amcharts/amcharts5/map";
import * as am5radar from "@amcharts/amcharts5/radar";
import * as am5venn from "@amcharts/amcharts5/venn";
import * as am5wc from "@amcharts/amcharts5/wc";
import * as am5xy from "@amcharts/amcharts5/xy";

import { BP, defineElementaryComponent, Type } from "@hexio_io/hae-lib-blueprint";

import {
	ClassList,
	THAEComponentDefinition,
	THAEComponentReact,
	propGroups,
	HAEComponentMainContext,
	termsEditor as HAELibComponentsTerms
} from "@hexio_io/hae-lib-components";
import { termsEditor } from "../../terms";
import { useChart } from "@hexio_io/hae-ext-components-base/src/Hooks/useChart";
import { isFunction } from "@hexio_io/hae-lib-shared";
import { createCleanUpQueue, runCleanUp } from "@hexio_io/hae-ext-components-base/src/Functions/chartHelpers";
import {
	CUSTOM_CHART_DEFAULT_DATA,
	CUSTOM_CHART_DEFAULT_SETUP,
	CUSTOM_CHART_DEFAULT_UPDATE
} from "./defaults";

interface HAEComponentCustomChart_State {}

/**
 * Custom HTML props
 */
const HAEComponentCustomChart_Props = {
	setup: BP.Prop(
		BP.String({
			...termsEditor.schemas.customChart.setup,
			default: CUSTOM_CHART_DEFAULT_SETUP,
			fallbackValue: "",
			constraints: {
				required: true
			}
		}),
		0,
		propGroups.common
	),

	update: BP.Prop(
		BP.String({
			...termsEditor.schemas.customChart.update,
			default: CUSTOM_CHART_DEFAULT_UPDATE
		}),
		10,
		propGroups.common
	),

	/*data: BP.Prop(BP.Any({
		...termsEditor.schemas.customChart.data,
		defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING,
	}), 20, propGroups.common)*/

	data: BP.Prop(
		BP.Expression({
			...termsEditor.schemas.customChart.data,
			default: CUSTOM_CHART_DEFAULT_DATA
		}),
		20,
		propGroups.common
	),

	animate: BP.Prop(
		BP.Boolean({
			...HAELibComponentsTerms.schemas.common.animate,
			default: false
		}),
		30,
		propGroups.common
	)
};

const HAEComponentCustomChart_Events = {};

const HAEComponentCustomChart_Definition = defineElementaryComponent<
	typeof HAEComponentCustomChart_Props,
	HAEComponentCustomChart_State,
	typeof HAEComponentCustomChart_Events
>({
	...termsEditor.components.customChart.component,

	name: "customChart",

	category: "charts",

	icon: "mdi/code-braces",

	docUrl: "...",

	order: 1000,

	props: HAEComponentCustomChart_Props,

	events: HAEComponentCustomChart_Events,

	resolve: (spec, state) => {
		return state || {};
	},

	getScopeData: (spec, state) => {
		return {};
	},

	getScopeType: () => {
		return Type.Object({
			props: {}
		});
	}
});

const HAEComponentCustomChart_React: THAEComponentReact<typeof HAEComponentCustomChart_Definition> = ({
	props,
	state,
	setState,
	componentInstance,
	reactComponentClassList
}) => {
	const { setup, update, data, animate } = props;

	const componentMainContext = React.useContext(HAEComponentMainContext);
	const viewportRootElement = componentMainContext.viewport.getRootElement();

	const elementRef = React.useRef<HTMLDivElement>();

	const chartSetupDataRef = React.useRef<unknown>();

	const { classList } = ClassList.getElementClassListAndIdClassName(
		"cmp-custom-chart",
		componentInstance.safePath,
		{ componentClassList: reactComponentClassList }
	);

	// Chart init

	const { rootRef, colorRef, colorValuesString, styleRef, styleValuesString } = useChart(
		true,
		elementRef,
		viewportRootElement,
		animate
	);

	const baseDependencies: React.DependencyList = [ setup, animate, colorValuesString, styleValuesString ];

	// Setup

	React.useLayoutEffect(() => {
		chartSetupDataRef.current = null;

		const root = rootRef.current;
		const color = colorRef.current;
		const style = styleRef.current;

		if (!setup || !root) {
			return;
		}

		const cleanUpQueue = createCleanUpQueue(root);

		try {
			const setupFunction = new Function(`return (
				{ am5, am5flow, am5hierarchy, am5map, am5radar, am5venn, am5wc, am5xy },
				{ root, cleanUpQueue, color, style },
				data
			) => { ${setup} }`)();

			if (isFunction(setupFunction)) {
				chartSetupDataRef.current = setupFunction(
					{ am5, am5flow, am5hierarchy, am5map, am5radar, am5venn, am5wc, am5xy },
					{ root, cleanUpQueue, color, style },
					data
				);
			}
		} catch (error) {
			console.warn("Custom Chart Setup:", error);
		}

		return () => {
			runCleanUp(cleanUpQueue);
		};
	}, [ ...baseDependencies ]);

	// Data

	React.useLayoutEffect(() => {
		const root = rootRef.current;
		const color = colorRef.current;
		const style = styleRef.current;

		if (!update || !root) {
			return;
		}

		try {
			const updateFunction = new Function(`return (
				{ am5, am5flow, am5hierarchy, am5map, am5radar, am5venn, am5wc, am5xy },
				{ root, color, style },
				data,
				setupData
			) => { ${update} }`)();

			if (isFunction(updateFunction)) {
				updateFunction(
					{ am5, am5flow, am5hierarchy, am5map, am5radar, am5venn, am5wc, am5xy },
					{ root, color, style },
					data,
					chartSetupDataRef.current
				);
			}
		} catch (error) {
			console.warn("Custom Chart Update:", error);
		}
	}, [ ...baseDependencies, update, data ]);

	return <div ref={elementRef} className={classList.toClassName()} />;
};

export const HAEComponentCustomChart: THAEComponentDefinition<typeof HAEComponentCustomChart_Definition> = {
	...HAEComponentCustomChart_Definition,
	reactComponent: HAEComponentCustomChart_React
};

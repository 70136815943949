/**
 * Color Field HAE component
 *
 * @package hae-ext-components-base
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import { BP, defineElementaryComponent, COMPONENT_MODE, Type } from "@hexio_io/hae-lib-blueprint";

import {
	ClassList,
	Label,
	propGroups,
	THAEComponentDefinition,
	THAEComponentReact
} from "@hexio_io/hae-lib-components";

import { isNonEmptyArray, isNonEmptyString, isString, isValidValue } from "@hexio_io/hae-lib-shared";

import { termsEditor } from "../../terms";
import { FieldBaseProps } from "./props";
import { FieldLabelInfo } from "./FieldLabelInfo";
import { FieldInfo } from "./FieldInfo";
import { useField, getFieldStateProps } from "./useField";
import { IFieldState } from "./state";
import { HAEComponentField_Events } from "./events";
import { createFieldClassListModifiers } from "./createFieldClassListModifiers";

interface HAEComponentColorField_State extends IFieldState {
	initialValue: string;
	value: string;
}

const HEX_COLOR_PATTERN = "^#[0-9a-f]{6}$";
const HEX_PATTERN_ERROR = "The value must be in seven-character hexadecimal notation (#000000)";

const HAEComponentColorField_Props = {
	...FieldBaseProps,

	value: BP.Prop(
		BP.String({
			...termsEditor.schemas.colorField.value,
			default: "",
			fallbackValue: null,
			constraints: {
				required: true,
				pattern: HEX_COLOR_PATTERN,
				patternErrorMessage: HEX_PATTERN_ERROR
			}
		}),
		0,
		propGroups.common
	),

	presets: BP.Prop(
		BP.Array({
			...termsEditor.schemas.colorField.presets,
			items: BP.Object({
				props: {
					value: BP.Prop(
						BP.String({
							...termsEditor.schemas.colorField.presetValue,

							constraints: {
								required: true,
								pattern: HEX_COLOR_PATTERN,
								patternErrorMessage: HEX_PATTERN_ERROR
							}
						})
					),

					description: BP.Prop(
						BP.String({
							...termsEditor.schemas.colorField.presetDescription
						})
					)
				}
			}),
			default: [],
			fallbackValue: []
		}),
		10,
		propGroups.common
	),

	allowCustomValue: BP.Prop(
		BP.Boolean({
			...termsEditor.schemas.colorField.allowCustomValue,
			default: false,
			fallbackValue: false,
			constraints: {
				required: true
			}
		}),
		20,
		propGroups.common
	)
};

const HAEComponentColorField_Events = {
	...HAEComponentField_Events
};

const HAEComponentColorField_Definition = defineElementaryComponent<
	typeof HAEComponentColorField_Props,
	HAEComponentColorField_State,
	typeof HAEComponentColorField_Events
>({
	...termsEditor.components.colorField.component,

	name: "colorField",

	category: "form",

	icon: "mdi/palette",

	docUrl: "...",

	order: 200,

	props: HAEComponentColorField_Props,

	events: HAEComponentColorField_Events,

	resolve: (spec, state, updateStateAsync) => {
		const initialValue = isValidValue(spec.value) ? spec.value : state?.initialValue;
		const value =
			state?.initialValue === initialValue
				? isValidValue(state?.value)
					? state.value
					: spec.value
				: spec.value;

		function setValue(value: string) {
			updateStateAsync((prevState) => ({ ...prevState, value }));
		}

		function clearValue(initial = false) {
			updateStateAsync((prevState) => ({ ...prevState, value: !initial ? "" : initialValue }));
		}

		return {
			value,
			initialValue,
			...getFieldStateProps(value, initialValue, state, spec.validate),
			setValue,
			clearValue
		};
	},

	getScopeData: (spec, state) => {
		return {
			initialValue: spec.value,
			value: state.value,
			valid: state.valid,
			setValue: state.setValue,
			clearValue: state.clearValue
		};
	},

	getScopeType: (spec, state, props) => {
		return Type.Object({
			props: {
				initialValue: Type.String({ ...termsEditor.schemas.colorField.initialValue }),
				value: props.props.value.schema.getTypeDescriptor(props.props.value),
				valid: Type.Boolean({ ...termsEditor.schemas.field.valid }),
				setValue: Type.Method({
					...termsEditor.schemas.field.setValue,
					argRequiredCount: 1,
					argSchemas: [ BP.String({}) ],
					argRestSchema: null,
					returnType: Type.Void({})
				}),
				clearValue: Type.Method({
					...termsEditor.schemas.field.clearValue,
					argRequiredCount: 0,
					argSchemas: [ BP.Boolean({ default: false }) ],
					argRestSchema: null,
					returnType: Type.Void({})
				})
			}
		});
	}
});

const HAEComponentColorField_React: THAEComponentReact<typeof HAEComponentColorField_Definition> = ({
	props,
	state,
	setState,
	componentInstance,
	reactComponentClassList
}) => {
	const {
		labelText,
		labelIcon,
		descriptionText,
		//hidden,
		readOnly,
		enabled,
		validate,
		required,
		allowCustomValue
	} = props;

	const { value, empty, touched, changed, valid } = state;

	const { componentMode } = componentInstance;

	const elementReadOnly = readOnly || componentMode !== COMPONENT_MODE.NORMAL;

	const presets = Array.isArray(props.presets)
		? props.presets.filter((item) => isNonEmptyString(item?.value))
		: [];
	const hasPresets = isNonEmptyArray(presets);

	const componentPath = componentInstance.safePath;

	// Classlists

	const { classList, idClassName: id } = ClassList.getElementClassListAndIdClassName(
		"cmp-field",
		componentPath,
		{ componentInstance, componentClassList: reactComponentClassList }
	);

	classList.addModifiers({
		color: true,
		validate
	});
	classList.addModifiers(
		createFieldClassListModifiers(classList, { enabled, empty, touched, changed, valid }),
		false
	);

	if (allowCustomValue) {
		classList.add("cmp-field--color-custom-value");
	}

	const { idClassName: dataListIdClassName } = ClassList.getElementClassListAndIdClassName(
		"cmp-field__datalist",
		componentPath,
		{ componentInstance, componentClassList: reactComponentClassList }
	);

	const { setValue, setTouched } = useField<string>(
		{
			id,
			state,
			readOnly,
			validate,
			validationDependencies: [ enabled, required ],
			onChange:
				!elementReadOnly && componentInstance.eventEnabled.change
					? componentInstance.eventTriggers.change
					: undefined
		},
		setState
	);

	const inputProps: React.HTMLProps<HTMLInputElement> = {
		value: isString(value) ? value : "",
		readOnly: elementReadOnly,
		disabled: !enabled,
		required
	};

	if (hasPresets) {
		inputProps["list"] = dataListIdClassName;
	}

	// Event handlers

	const _inputBlurHandler = React.useCallback(() => {
		setTouched();
	}, [ setTouched ]);

	const _inputChangeHandler = React.useCallback(
		(event: React.FormEvent<HTMLInputElement>) => {
			setValue(event.currentTarget.value);
		},
		[ setValue ]
	);

	return (
		<div className={classList.toClassName()}>
			<Label
				text={{ ...labelText, tagName: "span" }}
				icon={{ ...labelIcon, size: "SMALL" }}
				tagName="label"
				htmlFor={id}
				classList={new ClassList("cmp-field__label")}
				componentPath={[ ...componentPath, "label" ]}
				componentMode={componentMode}
			>
				<FieldLabelInfo required={required} />
			</Label>

			<div className="cmp-field__content">
				<input
					{...inputProps}
					{...(allowCustomValue ? {} : { id })}
					type="color"
					className="cmp-field__input"
					onBlur={_inputBlurHandler}
					onChange={_inputChangeHandler}
				/>

				{allowCustomValue ? (
					<input
						{...inputProps}
						id={id}
						className="cmp-field__input cmp-field__custom-input"
						onBlur={_inputBlurHandler}
						onChange={_inputChangeHandler}
						pattern={HEX_COLOR_PATTERN}
					/>
				) : null}

				{hasPresets ? (
					<datalist id={dataListIdClassName}>
						{presets.map((preset) => (
							<option key={preset.value} value={preset.value}>
								{preset.description}
							</option>
						))}
					</datalist>
				) : null}
			</div>

			<FieldInfo
				descriptionText={descriptionText}
				componentPath={[ ...componentPath, "info" ]}
				componentMode={componentMode}
			/>
		</div>
	);
};

export const HAEComponentColorField: THAEComponentDefinition<typeof HAEComponentColorField_Definition> = {
	...HAEComponentColorField_Definition,
	reactComponent: HAEComponentColorField_React
};

/**
 * Hexio App Engine Integration extensions base library.
 *
 * @package hae-ext-integrations-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { SYSTEM_ERRORS } from "./errorCodes/system";
import { ERROR_NAMES } from "./ErrorNames";
import { TErrors } from "./IError";

/**
 * Returns error name
 *
 * @param error Error
 * @param defaultName Default error name
 * @param errorsList Error list
 * @param nameList
 * @returns
 */
export function getErrorName(error: any, defaultName: string): string {
	// Network Error
	if ([ "connect", "getaddrinfo" ].includes(error?.syscall) && SYSTEM_ERRORS[error?.code]) {
		return ERROR_NAMES.NETWORK;
	}

	return defaultName;
}

export function getErrorMessage(error: any, defaultMessage: string, errorsList: TErrors[] = []): string {
	// Network Error Message
	if (SYSTEM_ERRORS[error?.code]) {
		return SYSTEM_ERRORS[error.code];
	}

	if (!errorsList) {
		return defaultMessage;
	}

	for (const errors of Object.values(errorsList)) {
		if (errors[error?.code]) {
			return errors[error?.code];
		}
	}

	return defaultMessage;
}

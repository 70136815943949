/**
 * Container React component
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import {
	COMPONENT_MODE,
	ISchemaComponentList,
	ISchemaComponentListSpec,
	ISchemaOneOfSpec,
	TGetBlueprintSchemaModel,
	TSchemaOneOfTypes
} from "@hexio_io/hae-lib-blueprint";

import { isNonEmptyArray, isNonEmptyObject, isNonEmptyString, isValidObject } from "@hexio_io/hae-lib-shared";

import { ClassList } from "../Classes/ClassList";
import { StyleSheet } from "../Classes/StyleSheet";

import {
	BACKGROUND_IMAGE_DEFAULT_POSITION,
	BACKGROUND_IMAGE_DEFAULT_REPEAT
} from "../SharedSchemas/BackgroundImages";
import { TContainerProps } from "../SharedSchemas/Container";
import { createItemStyle } from "../SharedSchemas/ItemComponent";

import { HAEComponentList, IHAEComponentListProps } from "../HAEComponent/HAEComponentList";
import { useComponentMainContext } from "../HAEComponent/HAEComponentContext";
import { DROP_ZONE_MODE } from "../Editor/useComponentListDnD";

import { BACKGROUND_ATTACHMENT, BACKGROUND_ATTACHMENT_default } from "../Enums/BACKGROUND_ATTACHMENT";
import { BACKGROUND_SIZE } from "../Enums/BACKGROUND_SIZE";
import { BOX_SHADOW } from "../Enums/BOX_SHADOW";
import { CONTAINER_ROLE, CONTAINER_ROLE_default } from "../Enums/CONTAINER_ROLE";
import { CONTAINER_FLOW, CONTAINER_FLOW_default } from "../Enums/CONTAINER_FLOW";
import { CONTAINER_ITEM_FLEX, CONTAINER_ITEM_FLEX_inherit } from "../Enums/CONTAINER_ITEM_FLEX";
import { SPACING } from "../Enums/SPACING";

import { getStringEnumCssValue, getStringEnumValue } from "../Functions/enumHelpers";
import { mapResponsiveValue, isResponsiveValueSet } from "../Functions/responsiveValueHelpers";

import { useDnDStateChangeHandler } from "../Hooks/useDnDStateChangeHandler";
import { useLoading } from "../Hooks/useLoading";

import { IBaseProps, IEventProps } from "./props";

import {
	TContainerItemInheritedProps,
	handleResizeBegin,
	handleResizeEnd,
	handleResizeUpdate,
	IResizeInitialState,
	modifyModelOnDrop,
	resolveAllowResize,
	resolveChildInlineStyle
} from "../Functions/containerEditModeFunctions";

import { TElementProps } from "../Types/TElementProps";
import { HORIZONTAL_ALIGN } from "../Enums/HORIZONTAL_ALIGN";
import { VERTICAL_ALIGN } from "../Enums/VERTICAL_ALIGN";
import { OVERFLOW, OVERFLOW_default } from "../Enums/OVERFLOW";
import { LoadingInfo } from "./LoadingInfo";
import { useStyleSheetRegistry } from "../Hooks/useStyleSheetRegistry";
import { getMedia } from "../Functions/componentHelpers";
import { useEditContext } from "../Editor/EditContext";
import {
	CONTAINER_DEFAULT_HORIZONTAL_ALIGN,
	CONTAINER_DEFAULT_VERTICAL_ALIGN
} from "../SharedSchemas/Container";
import {
	positionInsetKeys,
	POSITION_DEFAULT_INSET,
	TPositionProps,
	TPositionSpec
} from "../SharedSchemas/Position";
import { POSITION, POSITION_default, POSITION_inherit } from "../Enums/POSITION";
import { Z_INDEX } from "../Enums/Z_INDEX";

/**
 * Container props
 */
export interface IContainerProps extends IBaseProps, IEventProps, TContainerProps {
	/** Custom StyleSheet */
	styleSheet?: StyleSheet;

	/** Model node of container content */
	contentModelNode?: TGetBlueprintSchemaModel<ISchemaComponentList<TContainerItemInheritedProps>>;
}

/**
 * Container component
 */
export const Container: React.FunctionComponent<IContainerProps> = (props) => {
	const {
		content,
		flow,
		size,
		horizontalAlign,
		verticalAlign,
		backgroundColor,
		backgroundImages,
		foregroundColor,
		borderColor,
		borderWidth,
		borderRadius,
		boxShadow,
		padding,
		spacing,
		overflow,
		loading,
		role,
		componentPath,
		componentMode,
		contentModelNode,
		onClick
	} = props;

	const componentMainContext = useComponentMainContext();
	const inEditor = componentMainContext.rCtx.isInEditor();

	const editContext = useEditContext();
	const media = getMedia(inEditor ? editContext : undefined);

	const elementRef = React.useRef<HTMLElement>();

	let tagName = "div";

	const elementProps: TElementProps = {
		ref: elementRef
	};

	const { classList, idClassName } = ClassList.getElementClassListAndIdClassName(
		"container",
		componentPath,
		{ componentClassList: props.classList, componentMode }
	);

	// Values & specific classNames

	const flowValue = getStringEnumValue(CONTAINER_FLOW, flow, CONTAINER_FLOW_default);
	const horizontalAlignValue = getStringEnumValue(
		HORIZONTAL_ALIGN,
		horizontalAlign,
		CONTAINER_DEFAULT_HORIZONTAL_ALIGN
	);
	const verticalAlignValue = getStringEnumValue(
		VERTICAL_ALIGN,
		verticalAlign,
		CONTAINER_DEFAULT_VERTICAL_ALIGN
	);
	const overflowValue = getStringEnumValue(OVERFLOW, overflow, OVERFLOW_default);
	const roleValue = getStringEnumValue(CONTAINER_ROLE, role, CONTAINER_ROLE_default);

	const sizeSet = !!(size && isResponsiveValueSet(size));

	switch (roleValue) {
		case CONTAINER_ROLE.FORM:
			tagName = "form";

			elementProps.onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
				event.preventDefault();
			};

			break;

		case CONTAINER_ROLE.SECTION:
			tagName = "section";
			break;

		case CONTAINER_ROLE.ARTICLE:
			tagName = "article";
			break;

		case CONTAINER_ROLE.HEADER:
		case CONTAINER_ROLE.HEADER_BANNER:
			tagName = "header";

			if (roleValue === CONTAINER_ROLE.HEADER_BANNER) {
				elementProps.role = "banner";
			}

			break;

		case CONTAINER_ROLE.FOOTER:
		case CONTAINER_ROLE.FOOTER_CONTENTINFO:
			tagName = "footer";

			if (roleValue === CONTAINER_ROLE.FOOTER_CONTENTINFO) {
				elementProps.role = "contentinfo";
			}

			break;
	}

	classList.addModifiers({
		flow: flowValue,
		"horizontal-align": horizontalAlignValue,
		"vertical-align": verticalAlignValue,
		overflow: overflowValue,
		role: roleValue,
		size: sizeSet,
		wrap: sizeSet,
		loading: !!loading
	});

	const [ loadingState, loadingElementRef ] = useLoading(!!loading);

	const stateInfoClassList = new ClassList("state-info").addModifiers({
		mode: componentMode === COMPONENT_MODE.EDIT ? "edit" : "normal"
	});

	// Stylesheet

	const { selector, innerSelector, contentSelector, itemSelector } = React.useMemo(() => {
		const selector = `.${idClassName}`;
		const innerSelector = `${selector} > .container__inner`;
		const contentSelector = `${innerSelector} > .container__content`;
		const itemSelector = `${contentSelector} > .container__item`;

		return {
			selector,
			innerSelector,
			contentSelector,
			itemSelector
		};
	}, [ idClassName ]);

	const sizeResponsiveValue = sizeSet ? mapResponsiveValue(size) : null;

	const styleSheetRegistry = useStyleSheetRegistry();

	const styleSheet = React.useMemo(() => {
		const result = new StyleSheet();

		// Merge stylesheets, do not update previous instance

		if (props.styleSheet) {
			result.add(...props.styleSheet);
		}

		// Size

		if (sizeResponsiveValue) {
			result.addResponsiveValue(
				sizeResponsiveValue,
				itemSelector,
				(sizeValue: number) => {
					return `flex: 0 0 ${(100 / sizeValue).toFixed(
						4
					)}%; flex-basis: calc(100% / ${sizeValue});`;
				},
				media
			);
		}

		// Background

		if (isNonEmptyArray(backgroundImages)) {
			const backgroundImagesValue = [];

			backgroundImages
				.filter((item) => isNonEmptyObject(item))
				.forEach((item: ISchemaOneOfSpec<TSchemaOneOfTypes>) => {
					const itemValue = item.value[item.type];

					if (!isNonEmptyString(itemValue?.source)) {
						return;
					}

					let backgroundImage = `url("${itemValue.source}") ${
						itemValue.position || BACKGROUND_IMAGE_DEFAULT_POSITION
					}`;

					if (itemValue.size) {
						backgroundImage += ` / ${
							getStringEnumValue(BACKGROUND_SIZE, itemValue.size) || itemValue.size
						}`;
					}

					backgroundImage += ` ${itemValue.repeat || BACKGROUND_IMAGE_DEFAULT_REPEAT}`;

					if (itemValue.attachment) {
						backgroundImage += ` ${getStringEnumValue(
							BACKGROUND_ATTACHMENT,
							itemValue.attachment,
							BACKGROUND_ATTACHMENT_default
						)}`;
					}

					backgroundImagesValue.push(backgroundImage);
				});

			if (backgroundImagesValue.length) {
				result.addString(
					selector,
					`--element-background: ${backgroundImagesValue.join(", ")} !important;`
				);
			}
		}

		// Background color

		if (backgroundColor) {
			result.addColorProperties({ selector, name: "element-background-color", value: backgroundColor });
		}

		// Foreground color

		if (foregroundColor) {
			result.addColorProperties({ selector, name: "element-foreground-color", value: foregroundColor });
		}

		// Border color

		if (borderColor) {
			result.addString(selector, `--element-border-color: ${borderColor} !important;`);
		}

		// Border width

		if (borderWidth) {
			result.addString(selector, `--element-border-width: ${borderWidth} !important;`);
		}

		// Border radius

		if (borderRadius) {
			result.addString(selector, `--element-border-radius: ${borderRadius} !important;`);
		}

		// Box shadow

		if (boxShadow) {
			const boxShadowValue = getStringEnumCssValue(BOX_SHADOW, boxShadow, "box-shadow-");

			result.addString(selector, `--element-box-shadow: ${boxShadowValue} !important;`);
		}

		// Padding

		if (padding) {
			const paddingValue = getStringEnumCssValue(SPACING, padding, "spacing-");

			result.addString(innerSelector, `--element-padding: ${paddingValue};`);
		}

		// Spacing

		if (spacing) {
			const spacingValue = getStringEnumCssValue(SPACING, spacing, "spacing-");

			result.addString(itemSelector, `--element-spacing: ${spacingValue};`);
			result.addString(itemSelector, `--element-content-spacing: ${spacingValue};`);

			if (
				(overflowValue === OVERFLOW.NONE || overflowValue === OVERFLOW.VISIBLE) &&
				!spacingValue.endsWith("%")
			) {
				result.addString(contentSelector, `--element-offset-margin: ${spacingValue};`);
			}
		}

		return result;
	}, [
		selector,
		innerSelector,
		contentSelector,
		itemSelector,
		props.styleSheet,
		overflowValue,
		sizeResponsiveValue,
		backgroundColor,
		backgroundImages,
		foregroundColor,
		borderColor,
		borderWidth,
		borderRadius,
		boxShadow,
		padding,
		spacing,
		media
	]);

	// Items classlists & stylesheet

	const { itemsClassLists, itemsStyleSheet } = React.useMemo(() => {
		const resultClassLists: ClassList[] = [];
		const resultStyleSheet = new StyleSheet();

		if (isNonEmptyArray(content)) {
			content.forEach((item, index) => {
				const { classList: itemClassList, idClassName: itemIdClassName } =
					ClassList.getElementClassListAndIdClassName("container__item", item.safePath, {
						componentMode
					});

				const itemIdSelector = `${itemSelector}.${itemIdClassName}`;

				const itemStyle = createItemStyle(itemIdSelector, item.inheritedProps, media);

				itemClassList.add(
					`container__item--name-${item.componentName}`,
					...itemStyle.classList,
					`item--flow-${flowValue}`
				);
				resultStyleSheet.add(...itemStyle.styleSheet);

				// Item flex

				const itemFlexValue = mapResponsiveValue(item.inheritedProps.containerItemFlex);

				if (isNonEmptyArray(itemFlexValue)) {
					resultStyleSheet.addResponsiveValue(
						itemFlexValue,
						itemIdSelector,
						(flex: string) => {
							if (flex !== CONTAINER_ITEM_FLEX_inherit) {
								const flexValue = getStringEnumCssValue(
									CONTAINER_ITEM_FLEX,
									flex,
									"element-flex-"
								);

								return `--element-flex: ${flexValue} !important;`;
							}
						},
						media
					);
				}

				// Item position

				const itemPositionValue = mapResponsiveValue(item.inheritedProps.containerItemPosition);

				if (isNonEmptyArray(itemPositionValue)) {
					resultStyleSheet.addResponsiveValue(
						itemPositionValue,
						itemIdSelector,
						(positionData: TPositionSpec) => {
							let result = "";

							if (isValidObject(positionData)) {
								const positionValue = getStringEnumCssValue(POSITION, positionData.type);
								const positionDataValue = positionData.value[positionData.type];

								if (positionValue !== POSITION_inherit) {
									result += `--element-position: ${positionValue};`;

									if (isValidObject(positionDataValue)) {
										// Margin

										result += `--element-margin: ${getStringEnumCssValue(
											SPACING,
											positionDataValue.margin,
											"spacing-"
										)};`;

										// Inset

										const fixed = positionValue === POSITION.FIXED;

										positionInsetKeys.forEach((item) => {
											const insetValue = positionDataValue[item];

											if (isNonEmptyString(insetValue)) {
												result += `--element-inset-${item}: ${getStringEnumCssValue(
													SPACING,
													insetValue,
													"spacing-"
												)};`;
											}

											// Inset adjustment for viewport in editor in case of FIXED position, reset in all the other cases
											const insetVar = `var(--element-inset-${item})`;
											const viewportVar = fixed ? `var(--viewport-${item})` : null;

											result += `--element-${item}: ${
												viewportVar ? `calc(${viewportVar} + ${insetVar})` : insetVar
											};`;
										});

										// Z-index

										result += `--element-z-index: ${getStringEnumCssValue(
											Z_INDEX,
											positionDataValue.zIndex,
											"z-index-"
										)};`;
									}
								}
							}

							return result;
						},
						media
					);
				}

				resultClassLists[index] = itemClassList;
			});
		}

		return {
			itemsClassLists: resultClassLists,
			itemsStyleSheet: resultStyleSheet
		};
	}, [ itemSelector, content, flowValue, media ]);

	const allStyleSheets = React.useMemo(() => {
		return new StyleSheet(...styleSheet, ...itemsStyleSheet);
	}, [ styleSheet.toString(), itemsStyleSheet.toString() ]);

	styleSheetRegistry.add(idClassName, allStyleSheets);

	// Event handlers

	if (typeof onClick === "function") {
		classList.add("container--event-click");

		elementProps.tabIndex = 0;

		elementProps.onClick = () => {
			onClick();
		};
	}

	// Overflow scroll event

	const _scrollHandler = React.useCallback(() => {
		window.dispatchEvent(
			new CustomEvent("hae_component_container_scroll", {
				detail: { idClassName }
			})
		);
	}, [ idClassName ]);

	if (overflowValue !== OVERFLOW.NONE) {
		elementProps.onScroll = _scrollHandler;
	}

	// Edit mode

	// eslint-disable-next-line max-len
	let componentListEditProps: Omit<
		IHAEComponentListProps<TContainerItemInheritedProps, never>,
		"components" | "componentPath" | "componentMode"
	> = {};

	if (componentMode === COMPONENT_MODE.EDIT) {
		componentListEditProps = {
			childInlineStyle: (element, index) => resolveChildInlineStyle(element, index, media),
			allowResize: (componentInstance, index) =>
				resolveAllowResize(componentInstance, index, media, flowValue),
			onResizeBegin: (subject, resizeOffset) => handleResizeBegin(subject, resizeOffset, media),
			onResizeUpdate: handleResizeUpdate,
			onResizeEnd: (subject, resizeOffset, initialState) => {
				return handleResizeEnd(subject, resizeOffset, initialState as IResizeInitialState, media);
			},
			modifyModelOnDrop: (itemModel, element) => modifyModelOnDrop(itemModel, element, media),
			modelNode: contentModelNode,
			dropZoneMode:
				flowValue === CONTAINER_FLOW.ROW ? DROP_ZONE_MODE.HORIZONTAL : DROP_ZONE_MODE.VERTICAL
		};
	}

	// Drag & drop container fix

	useDnDStateChangeHandler(editContext, (active) => {
		if (!elementRef.current) {
			return;
		}

		if (active) {
			elementRef.current.classList.add("container--dnd-active");
		} else {
			elementRef.current.classList.remove("container--dnd-active");
		}
	});

	// Resolve Class list

	elementProps.className = classList.toClassName();

	return React.createElement(
		tagName,
		elementProps,
		<>
			{componentMode === COMPONENT_MODE.EDIT || !loading || loading.renderContent ? (
				<div className="container__inner">
					<HAEComponentList<TContainerItemInheritedProps>
						components={content as ISchemaComponentListSpec<TContainerItemInheritedProps>}
						componentPath={[ ...componentPath, "component-list" ]}
						componentMode={componentMode}
						classList={new ClassList("container__content")}
						childClassList={(element) =>
							element?.type === "component"
								? itemsClassLists[element.srcIndex]
								: new ClassList("container__item", "item", `item--flow-${flowValue}`)
						}
						childComponentClassList={(element) =>
							new ClassList("item__component", `item__component--type-${element.type}`)
						}
						{...componentListEditProps}
					/>
				</div>
			) : null}

			{loadingState ? (
				<div ref={loadingElementRef} className={stateInfoClassList.toClassName()}>
					<LoadingInfo
						iconSizeClass={componentMode !== COMPONENT_MODE.NORMAL ? "SMALL" : undefined}
					/>
				</div>
			) : null}
		</>
	);
};

/**
 * Gauge Chart HAE component
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5radar from "@amcharts/amcharts5/radar";
import * as am5xy from "@amcharts/amcharts5/xy";

import {
	ClassList,
	THAEComponentDefinition,
	THAEComponentReact,
	HAEComponentMainContext,
	getStringEnumValue
} from "@hexio_io/hae-lib-components";

import { isFunction, isString, isValidObject, isValidValue, toNumber } from "@hexio_io/hae-lib-shared";

import { HAEComponentGaugeChart_Definition } from "./definition";
import { useChart } from "../../Hooks/useChart";
import { createCleanUpQueue, getChartColor, runCleanUp } from "../../Functions/chartHelpers";
import { HAEComponentGaugeChart_State, TGaugeChartRange } from "./state";
import { createAngularRanges } from "./createAngularRanges";
import { createLabel } from "./createLabel";
import { createHand } from "./createHand";
import { createAxis } from "./createAxis";
import { GAUGE_CHART_STYLE, GAUGE_CHART_STYLE_default } from "../../Enums/GAUGE_CHART_STYLE";
import { getCurrentRange } from "./getCurrentRange";
import { createSubScope, IScope, Type } from "@hexio_io/hae-lib-blueprint";

const animationSettings = {
	duration: 500,
	easing: am5.ease.out(am5.ease.cubic)
};

const HAEComponentGaugeChart_React: THAEComponentReact<typeof HAEComponentGaugeChart_Definition> = ({
	props,
	state,
	setState,
	componentInstance,
	reactComponentClassList
}) => {
	const { value } = props;

	const componentMainContext = React.useContext(HAEComponentMainContext);
	const viewportRootElement = componentMainContext.viewport.getRootElement();

	const resolved = !!viewportRootElement && isValidObject(state);

	const {
		min,
		max,
		startAngle,
		endAngle,
		ranges,
		position: position,
		//styleData,
		backgroundColor,
		foregroundColor,
		size,
		hand,
		ticks,
		label,
		//grid,
		settingsTimestamp
	} = resolved ? state : ({} as Partial<HAEComponentGaugeChart_State>);

	const elementRef = React.useRef<HTMLDivElement>();

	//const chartRef = React.useRef<am5xy.XYChart>();

	const { classList } = ClassList.getElementClassListAndIdClassName(
		"cmp-gauge-chart",
		componentInstance.safePath,
		{ componentClassList: reactComponentClassList }
	);

	// Chart init

	const { rootRef, colorRef, colorValuesString, styleRef, styleValuesString } = useChart(
		resolved,
		elementRef,
		viewportRootElement
	);

	// Doesn't have anything to do with "styleRef" or "styleValuesString"
	const styleValue = getStringEnumValue(GAUGE_CHART_STYLE, props.styleData.type, GAUGE_CHART_STYLE_default);
	const styleDataValue = props.styleData.value[props.styleData.type];

	const axisDataItemRef = React.useRef<am5.DataItem<am5xy.IValueAxisDataItem>>();
	const axisValueRangeRef = React.useRef<am5.DataItem<am5xy.IValueAxisDataItem>>();
	const axisBackgroundRangeRef = React.useRef<am5.DataItem<am5xy.IValueAxisDataItem>>();
	const handRef = React.useRef<am5radar.ClockHand>();
	const labelRef = React.useRef<am5.Label>();

	// Functions and callbacks

	const fixHand = React.useCallback((value: number) => {
		if (!handRef.current) {
			return;
		}

		handRef.current.set("visible", !Number.isNaN(value) && value !== null);
	}, []);

	const fixLabel = React.useCallback(
		(value: number, label: HAEComponentGaugeChart_State["label"], range: TGaugeChartRange) => {
			if (!label || !labelRef.current) {
				return;
			}

			let labelText = "";

			const rangeFormatterDefined = isFunction(range?.formatter);

			if (rangeFormatterDefined || isFunction(label.formatter)) {
				const formatterCallback = (parentScope: IScope) => {
					return createSubScope(parentScope, { value }, { value: Type.Float({}) });
				};

				const formattedValue = rangeFormatterDefined
					? range.formatter(formatterCallback)
					: label.formatter(formatterCallback);

				if (isString(formattedValue)) {
					labelText = formattedValue;
				} else if (isValidValue(formattedValue)) {
					labelText = `${formattedValue}`;
				}
			} else if (Number.isFinite(value)) {
				labelText = `${value}`;
			}

			labelRef.current.set("text", labelText);

			if (range?.foregroundColor) {
				labelRef.current.set("fill", getChartColor(range.foregroundColor, viewportRootElement));
			} else {
				labelRef.current.remove("fill");
			}
		},
		[ viewportRootElement ]
	);

	const fixSolidStyleColor = React.useCallback(
		(range: TGaugeChartRange) => {
			if (styleValue !== GAUGE_CHART_STYLE.SOLID || !axisValueRangeRef.current) {
				return;
			}

			axisValueRangeRef.current
				.get("axisFill")
				.set(
					"fill",
					getChartColor(
						range?.backgroundColor || styleDataValue?.backgroundColor,
						viewportRootElement
					)
				);
		},
		[ styleDataValue?.backgroundColor, viewportRootElement ]
	);

	// Safe value

	const safeValue = React.useMemo(() => {
		if (Number.isFinite(value)) {
			return value;
		}

		if (value === Infinity) {
			return max;
		}

		return min;
	}, [ value, min, max ]);

	// Current range
	// Intentionally not a part of functions above

	const range = React.useMemo(() => getCurrentRange(value, ranges), [ value, settingsTimestamp ]);

	// Setup

	React.useLayoutEffect(() => {
		if (!resolved || !rootRef.current) {
			return;
		}

		const root = rootRef.current;

		const cleanUpQueue = createCleanUpQueue(root);

		const { base /*, background, black, lightGray*/ } = colorRef.current;

		let x: number | am5.Percent;
		let y: number | am5.Percent;

		if (position?.x) {
			const xNumber = toNumber(position.x);

			x = position.x.endsWith("%") ? am5.percent(xNumber) : xNumber;
		}

		if (position?.y) {
			const yNumber = toNumber(position.y);

			y = position.y.endsWith("%") ? am5.percent(yNumber) : yNumber;
		}

		const chart = root.container.children.push(
			am5radar.RadarChart.new(root, {
				startAngle,
				endAngle,
				x,
				y,
				panX: true,
				panY: true
			})
		);

		root.interfaceColors.set(
			"text",
			foregroundColor ? getChartColor(foregroundColor, viewportRootElement) : base
		);

		// Axis

		const { axis, axisBackgroundRange } = createAxis(
			size,
			min,
			max,
			backgroundColor,
			ticks,
			root,
			chart,
			viewportRootElement
		);

		axisBackgroundRangeRef.current = axisBackgroundRange;

		// Value

		axisDataItemRef.current = axis.makeDataItem({});

		const axisDataRange = axis.createAxisRange(axisDataItemRef.current);

		axisDataRange.get("tick").set("forceHidden", true);

		axisDataItemRef.current.set("value", safeValue);
		//axisDataItemRef.current.get("tick").set("forceHidden", true);

		// Ranges

		if (styleValue === GAUGE_CHART_STYLE.ANGULAR) {
			createAngularRanges(ranges, axis, viewportRootElement);

			axisValueRangeRef.current = null;
		} else if (styleValue === GAUGE_CHART_STYLE.SOLID) {
			axisValueRangeRef.current = axis.createAxisRange(axis.makeDataItem({}));
			axisValueRangeRef.current.setAll({
				value: min,
				endValue: safeValue
			});
			axisValueRangeRef.current.get("tick").set("forceHidden", true);

			axisBackgroundRange.set("value", safeValue);

			fixSolidStyleColor(range);
		}

		// Hand

		handRef.current = createHand(hand, root, axisDataItemRef, colorRef.current, viewportRootElement);

		fixHand(value);

		// Label

		labelRef.current = createLabel(label, root, chart, value, styleRef.current);

		fixLabel(value, label, range);

		// Not sure what this was in an example for, keeping it here for a reference
		// chart.bulletsContainer.set("mask", undefined);

		return () => {
			runCleanUp(cleanUpQueue);
		};
	}, [ resolved, colorValuesString, styleValuesString, settingsTimestamp ]);

	// Value update

	React.useLayoutEffect(() => {
		if (!resolved || !axisDataItemRef.current) {
			return;
		}

		fixHand(value);

		axisDataItemRef.current.animate({
			key: "value",
			to: safeValue,
			...animationSettings
		});

		fixLabel(value, label, range);

		if (styleValue === GAUGE_CHART_STYLE.SOLID) {
			axisValueRangeRef.current.animate({
				key: "endValue",
				to: safeValue,
				...animationSettings
			});

			axisBackgroundRangeRef.current.animate({
				key: "value",
				to: safeValue,
				...animationSettings
			});

			fixSolidStyleColor(range);
		}
	}, [ resolved, value, safeValue, settingsTimestamp ]);

	if (!resolved) {
		return null;
	}

	return <div ref={elementRef} className={classList.toClassName()} />;
};

export const HAEComponentGaugeChart: THAEComponentDefinition<typeof HAEComponentGaugeChart_Definition> = {
	...HAEComponentGaugeChart_Definition,
	reactComponent: HAEComponentGaugeChart_React
};

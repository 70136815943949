/**
 * Hexio App Engine core library.
 *
 * @package hae-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	CompileContext,
	DesignContext,
	DOC_ERROR_SEVERITY,
	importSchema,
	IRuntimeError,
	ISchemaConstObject,
	ISchemaValue,
	loadCompiledModel,
	RuntimeContext,
	TGetBlueprintSchemaModel,
	TSchemaConstObjectProps
} from "@hexio_io/hae-lib-blueprint";
import { IAppServer } from "../../app";
import { BlueprintView, DOC_TYPES } from "../../blueprints";
import { ILogger } from "../../logger";
import { GENERIC_RESOURCE_PERMISSIONS, RESOURCE_PERMISSIONS } from "../../registries";
import { RESOURCE_TYPES } from "../IResource";
import { RESOURCE_ON_EVENT_TYPE } from "../IResourceManager";
import { RESOURCE_ERROR_NAMES } from "../ResourceErrorNames";
import { IViewResourceProps, IViewResourceType } from "./IViewResource";

type TViewSchemaModel = TGetBlueprintSchemaModel<typeof BlueprintView>;

export class ViewResourceV1 implements IViewResourceType {
	public get permissions(): RESOURCE_PERMISSIONS[] {
		return GENERIC_RESOURCE_PERMISSIONS;
	}

	public get name(): string {
		return DOC_TYPES.VIEW_V1;
	}

	public get category(): string {
		return RESOURCE_TYPES.VIEW;
	}

	public get statsName(): string {
		return "views";
	}

	protected logger: ILogger;

	public constructor(protected app: IAppServer) {
		this.logger = this.app.get("logger").facility("resource-view-v1");
	}

	public setup(resource: IViewResourceProps): IViewResourceProps {
		resource.resourceType = RESOURCE_TYPES.VIEW;
		resource.parsingDetails.isRegistered = true;

		return resource;
	}

	public async scan(resource: IViewResourceProps): Promise<IViewResourceProps> {
		resource.parsingDetails.paramsSchemaImportOk = false;
		resource.parsingDetails.paramsSchemaOk = false;

		const resourceManager = this.app.get("resourceManager");
		const { uri } = resource;

		this.logger.debug(`Scan view '${uri}'.`);

		let dCtx: DesignContext;
		let model: TViewSchemaModel;

		try {
			dCtx = resourceManager.createDCtx(true);

			resource = await resourceManager.parseModel<IViewResourceProps>(resource, dCtx, BlueprintView);
			model = resource.parsedData.model as TViewSchemaModel;

			if (!model) {
				this.logger.warn("Can't parse view model.");
				this.logger.debug({ uri });
				this.logger.debug("Parse errors: ", dCtx.getParseErrors());
				return resource;
			}

			resource.parsedData.name = model.props.id.value;
			resource.parsedData.label = model.props.label.value;
			resource.parsedData.description = model.props.description.value;
			resource.parsedData.requireAuthenticatedUser =
				model.props.spec.value.props.requireAuthenticatedUser.value;

			resource = await resourceManager.renderSpec(resource);
			const spec = resource.parsedData.spec;

			if (!spec) {
				this.logger.warn("Can't render view model spec.");
				this.logger.debug({ uri });
				return resource;
			}

			resource.parsedData.paramsSchema = spec.spec.params;
			resource.parsingDetails.paramsSchemaOk = true;

			resource.parsedData.paramsSchemaImport = importSchema<
				ISchemaValue<ISchemaConstObject<TSchemaConstObjectProps>>
			>(spec.spec.params);
			resource.parsingDetails.paramsSchemaImportOk = true;

			return resource;
		} catch (error) {
			this.logger.debug({ error: error?.message });
			// TODO: log non-parsing errors into resource
			resourceManager.reportError(`Can't scan view '${uri}'.`, error);
			return resource;
		} finally {
			if (model) {
				model.schema.destroy(model);
			}
			if (dCtx) {
				dCtx.destroy();
			}
		}
	}

	public async parse(resource: IViewResourceProps): Promise<IViewResourceProps> {
		resource.parsingDetails.renderCodeOk = false;
		resource.parsingDetails.renderFnOk = false;

		const resourceManager = this.app.get("resourceManager");
		const { uri } = resource;

		this.logger.debug(`Parse view '${uri}'.`);

		let dCtx: DesignContext;
		let model: TViewSchemaModel;
		let cCtx: CompileContext;

		try {
			dCtx = resourceManager.createDCtx(false);

			resource = await resourceManager.parseModel<IViewResourceProps>(resource, dCtx, BlueprintView);
			model = resource.parsedData.model as TViewSchemaModel;

			if (!model) {
				this.logger.warn("Can't parse view model.");
				this.logger.debug({ uri });
				return resource;
			}

			cCtx = resourceManager.createCCtx();
			const compiledModel = cCtx.compileModel(model.props.spec, true, true);

			resource.reportCompileErrors(cCtx);

			if (cCtx.hasFatalErrors()) {
				this.logger.info(`View '${uri}' has fatal compile errors.`);
				this.logger.debug(cCtx.getCompileErrors());
				return resource;
			}

			resource.parsedData.renderCode = compiledModel.code;
			resource.parsingDetails.renderCodeOk = true;

			let renderFn;
			try {
				renderFn = loadCompiledModel(compiledModel.code);
			} catch (error) {
				this.logger.warn("Failed to load compiled model.");
				this.logger.debug(error);

				resource.reportRuntimeErrors({
					getRuntimeErrors: () => {
						return [
							{
								severity: DOC_ERROR_SEVERITY.ERROR,
								name: RESOURCE_ERROR_NAMES.RESOURCE_COMPILATION_ERROR,
								message: "Failed to load compiled model.",
								details: [ `View Id: '${resource.id}'` ],
								modelPath: [ "$" ],
								modelNodeId: model.nodeId
							} as IRuntimeError
						];
					}
				} as RuntimeContext);

				return resource;
			}

			resource.parsedData.renderFn = renderFn;
			resource.parsingDetails.renderFnOk = true;

			return resource;
		} catch (error) {
			this.logger.debug({ error: error?.message });
			resourceManager.reportError(`Can't parse view '${uri}'.`, error);
			return resource;
		} finally {
			try {
				if (model) {
					model.schema.destroy(model);
				}
				if (dCtx) {
					dCtx.destroy();
				}
			} catch (error) {
				this.logger.debug(error);
			}
		}
	}

	public getDependenciesToReload(
		resource: IViewResourceProps,
		eventType?: RESOURCE_ON_EVENT_TYPE
	): string[] {
		return [];
	}
}

/**
 * Composed Chart HAE component Create Axes
 *
 * @package hae-ext-components-base
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { Axis } from "@amcharts/amcharts5/.internal/charts/xy/axes/Axis";
import { AxisRenderer } from "@amcharts/amcharts5/.internal/charts/xy/axes/AxisRenderer";

import { getStringEnumValue } from "@hexio_io/hae-lib-components";

import { COMPOSED_CHART_AXIS_TYPE } from "../../Enums/COMPOSED_CHART_AXIS_TYPE";
import {
	COMPOSED_CHART_AXIS_DATETIME_UNIT,
	COMPOSED_CHART_AXIS_DATETIME_UNIT_default
} from "../../Enums/COMPOSED_CHART_AXIS_DATETIME_UNIT";

import { TChartAxes } from "./types";
import { noStrokeSettings } from "./settings";
import { IDateAxisSettings } from "@amcharts/amcharts5/.internal/charts/xy/axes/DateAxis";
import { TResolvedAxes } from "./state";
import { getTimestamp } from "@hexio_io/hae-lib-shared";
import { TDatetimeAxisProps, THAEComponentComposedChart_Props, TValueAxisProps } from "./props";
import { IColors } from "../../types/charts";

/**
 * Creates axes
 */
export function createAxes(
	axes: TResolvedAxes,
	root: am5.Root,
	chart: am5xy.XYChart,
	datetimeFormat: THAEComponentComposedChart_Props["datetimeFormat"],
	colors: IColors,
	scrollbarChart?: am5xy.XYChart
): {
	chartAxes: TChartAxes;
	scrollbarChartAxes: TChartAxes;
} {
	const { base } = colors;

	const chartAxes: TChartAxes = {};
	const scrollbarChartAxes: TChartAxes = scrollbarChart ? {} : null;

	axes.forEach((item) => {
		const renderer = (item.horizontal ? am5xy.AxisRendererX : am5xy.AxisRendererY).new(root, {
			opposite: item.opposite,
			stroke: base,
			strokeOpacity: 1,
			strokeWidth: 1
		});

		renderer.grid.template.setAll(
			item.originalData.grid
				? {
						stroke: base,
						strokeOpacity: 0.2,
						strokeWidth: 1
				  }
				: noStrokeSettings
		);

		const scrollbarChartRenderer = scrollbarChart
			? (item.horizontal ? am5xy.AxisRendererX : am5xy.AxisRendererY).new(root, {
					opposite: item.opposite,
					...noStrokeSettings
			  })
			: null;

		if (scrollbarChartRenderer) {
			scrollbarChartRenderer.grid.template.setAll(noStrokeSettings);
		}

		// Axis types

		let chartAxis: Axis<AxisRenderer>;
		let scrollbarChartAxis: Axis<AxisRenderer>;

		switch (item.type) {
			case COMPOSED_CHART_AXIS_TYPE.VALUE: {
				const typeDataValue = item.originalData.typeData.value[
					item.originalData.typeData.type
				] as TValueAxisProps;

				chartAxis = am5xy.ValueAxis.new(root, {
					renderer,
					min: typeDataValue.min,
					max: typeDataValue.max
					//calculateTotals: !horizontal,
					//strictMinMax: true
				});

				if (scrollbarChartRenderer) {
					scrollbarChartAxis = am5xy.ValueAxis.new(root, {
						renderer: scrollbarChartRenderer,
						min: typeDataValue.min,
						max: typeDataValue.max
					});
				}

				break;
			}

			case COMPOSED_CHART_AXIS_TYPE.CATEGORY: {
				chartAxis = am5xy.CategoryAxis.new(root, {
					renderer,
					categoryField: item.horizontal ? "x" : "y"
				});

				if (scrollbarChartRenderer) {
					scrollbarChartAxis = am5xy.CategoryAxis.new(root, {
						renderer: scrollbarChartRenderer,
						categoryField: item.horizontal ? "x" : "y"
					});
				}

				break;
			}

			case COMPOSED_CHART_AXIS_TYPE.DATETIME: {
				const typeDataValue = item.originalData.typeData.value[
					item.originalData.typeData.type
				] as TDatetimeAxisProps;

				const { interval } = typeDataValue;
				const baseInterval: am5.time.ITimeInterval =
					interval && interval.unit && interval.count
						? {
								timeUnit: getStringEnumValue(
									COMPOSED_CHART_AXIS_DATETIME_UNIT,
									typeDataValue.interval.unit,
									COMPOSED_CHART_AXIS_DATETIME_UNIT_default
								),
								count: typeDataValue.interval.count
						  }
						: { timeUnit: "millisecond", count: item.datetimeInterval || 1000 };

				const settings: IDateAxisSettings<AxisRenderer> = { renderer, baseInterval };

				if (typeDataValue.min) {
					settings.min = getTimestamp(typeDataValue.min);
				}

				if (typeDataValue.max) {
					settings.max = getTimestamp(typeDataValue.max);
				}

				if (datetimeFormat) {
					settings.dateFormats = datetimeFormat;
				}

				chartAxis = am5xy.DateAxis.new(root, { ...settings });

				if (scrollbarChartRenderer) {
					scrollbarChartAxis = am5xy.DateAxis.new(root, {
						...settings,
						renderer: scrollbarChartRenderer
					});
				}

				break;
			}
		}

		if (item.horizontal) {
			if (item.originalData.title) {
				chartAxis.children.push(
					am5.Label.new(root, {
						text: item.originalData.title,
						x: am5.p50,
						centerX: am5.p50
					})
				);
			}

			chart.xAxes.push(chartAxis);

			if (scrollbarChartAxis) {
				scrollbarChart.xAxes.push(scrollbarChartAxis);
			}
		} else {
			if (item.originalData.title) {
				chartAxis.children.unshift(
					am5.Label.new(root, {
						text: item.originalData.title,
						y: am5.p50,
						centerX: am5.p50,
						rotation: -90
					})
				);
			}

			chart.yAxes.push(chartAxis);

			if (scrollbarChartAxis) {
				scrollbarChart.yAxes.push(scrollbarChartAxis);
			}
		}

		chartAxes[item.id] = chartAxis;

		if (scrollbarChartAxis) {
			scrollbarChartAxes[item.id] = scrollbarChartAxis;
		}
	});

	return {
		chartAxes,
		scrollbarChartAxes
	};
}

/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { exportSchema } from "../ExportImportSchema/ExportSchema";
import {
	BP_IDT_SCALAR_SUBTYPE,
	BP_IDT_TYPE,
	IBlueprintIDTMap,
	IBlueprintIDTMapElement,
	IBlueprintIDTScalar
} from "../IDT/ISchemaIDT";
import {
	IBlueprintSchema,
	IBlueprintSchemaOpts,
	TBlueprintSchemaParentNode,
	TGetBlueprintSchemaDefault,
	TGetBlueprintSchemaModel,
	TGetBlueprintSchemaSpec
} from "../Schema/IBlueprintSchema";
import { IModelNode, MODEL_CHANGE_TYPE } from "../Schema/IModelNode";
import {
	assignParentToModelProps,
	compileValidateAsNotSupported,
	createEmptySchema,
	createModelNode,
	destroyModelNode,
	handleModelNodeChange,
	validateAsNotSupported
} from "../Schema/SchemaHelpers";
import { applyCodeArg, escapeString } from "../Context/CompileUtil";
import { DesignContext } from "../Context/DesignContext";
import { ISchemaImportExport } from "../ExportImportSchema/ExportTypes";
import {
	extractAndValidateIDTMapProperties,
	provideIDTMapPropertyCompletions,
	provideIDTMapRootCompletions,
	validateIDTNode
} from "../Context/ParseUtil";
import { DOC_ERROR_NAME, DOC_ERROR_SEVERITY } from "../Shared/IDocumentError";
import { TypeDescObject, TypeDescString } from "../Shared/ITypeDescriptor";
import {
	IBlueprintSchemaValidationError,
	SCHEMA_VALIDATION_ERROR_TYPE
} from "../Validator/IBlueprintSchemaValidator";
import { ISchemaValue } from "./value/SchemaValue";
import { ISchemaConstObject, TSchemaConstObjectProps } from "./const/SchemaConstObject";
import { SchemaValueObject } from "./value/SchemaValueObject";
import { IIntegrationRefInstanceItem, IIntegrationRefResolver } from "../Resolvers/IIntegrationRefResolver";
import { SchemaDeclarationError } from "../Schema/SchemaDeclarationError";
import { offEvent, onEvent } from "@hexio_io/hae-lib-shared";
import { IDocumentLocation } from "../Shared/IDocumentLocation";
import { CMPL_ITEM_KIND, ICompletionItem } from "../Shared/ICompletionItem";

type TSchemaIntegrationRefParamsSchema = ISchemaValue<ISchemaConstObject<TSchemaConstObjectProps>>;

/**
 * Schema model
 */
export interface ISchemaIntegrationRefModel extends IModelNode<ISchemaIntegrationRef> {
	/** Integratin type */
	integrationType: string;
	/** Function name */
	functionName: string;
	/** Integration ID */
	integrationId: string;
	/** Function params - can be null! */
	params: TGetBlueprintSchemaModel<TSchemaIntegrationRefParamsSchema>;
	/** Resolver invalidate event bound to model */
	__invalidateHandler: () => void;
}

/**
 * Schema options
 */
export interface ISchemaIntegrationRefOpts extends IBlueprintSchemaOpts {
	/** Validation constraints */
	constraints?: {
		/** If required */
		required?: boolean;
	};
}

/**
 * Default value
 */
export interface ISchemaIntegrationRefDefault {
	/** Integratin type */
	integrationType: string;
	/** Function name */
	functionName: string;
	/** Integration ID */
	integrationId: string;
	/** Function params - can be null! */
	params: TGetBlueprintSchemaDefault<TSchemaIntegrationRefParamsSchema>;
}

/**
 * Schema spec
 */
export interface ISchemaIntegrationRefSpec {
	/** Integratin type */
	integrationType: string;
	/** Function name */
	functionName: string;
	/** Integration ID */
	integrationId: string;
	/** Function params - can be null! */
	params: TGetBlueprintSchemaSpec<TSchemaIntegrationRefParamsSchema>;
}

/**
 * Schema type
 */
export interface ISchemaIntegrationRef
	extends IBlueprintSchema<
		ISchemaIntegrationRefOpts,
		ISchemaIntegrationRefModel,
		ISchemaIntegrationRefSpec,
		ISchemaIntegrationRefDefault
	> {
	/**
	 * Returns a list of avilable integration instances based on a current integration type
	 *
	 * @param modelNode Model node
	 */
	getIntegrationInstanceList: (modelNode: ISchemaIntegrationRefModel) => IIntegrationRefInstanceItem[];

	/**
	 * Sets integration ID
	 *
	 * @param modelNode Model node
	 * @param integrationId Integration ID
	 * @param notify If to notify about model change
	 */
	setIntegrationId: (
		modelNode: ISchemaIntegrationRefModel,
		integrationId: string,
		notify?: boolean
	) => void;
}

/**
 * Schema: String scalar value
 *
 * @param opts Schema options
 */
export function SchemaIntegrationRef(opts: ISchemaIntegrationRefOpts): ISchemaIntegrationRef {
	type TParamsModel = TGetBlueprintSchemaModel<TSchemaIntegrationRefParamsSchema>;

	const getResolver = (dCtx: DesignContext): IIntegrationRefResolver => {
		return dCtx.getResolver<IIntegrationRefResolver>("integrationRef");
	};

	const defineParamsSchema = (props: TSchemaConstObjectProps) => {
		return SchemaValueObject({
			label: "Parameters",
			constraints: {
				required: true
			},
			props: props
		});
	};

	const provideIntegrationTypeCompletions = (
		dCtx: DesignContext,
		parentLoc: IDocumentLocation,
		minColumn: number
	) => {
		const typeList = getResolver(dCtx).getTypeList();

		dCtx.__addCompletition(parentLoc.uri, parentLoc.range, minColumn, () => {
			const items: ICompletionItem[] = typeList.map((item) => ({
				kind: CMPL_ITEM_KIND.Class,
				label: item.integrationType,
				insertText: item.integrationType
			}));

			return items;
		});
	};

	const provideFunctionNameCompletions = (integrationType: string) => {
		return (dCtx: DesignContext, parentLoc: IDocumentLocation, minColumn: number) => {
			const fnList = getResolver(dCtx).getFunctionList(integrationType);

			dCtx.__addCompletition(parentLoc.uri, parentLoc.range, minColumn, () => {
				const items: ICompletionItem[] = fnList.map((item) => ({
					kind: CMPL_ITEM_KIND.Class,
					label: item.functionName,
					insertText: item.functionName,
					detail: item.description
				}));

				return items;
			});
		};
	};

	const provideIntegrationIdCompletions = (integrationType: string) => {
		return (dCtx: DesignContext, parentLoc: IDocumentLocation, minColumn: number) => {
			const fnList = getResolver(dCtx).getInstanceListByType(integrationType);

			dCtx.__addCompletition(parentLoc.uri, parentLoc.range, minColumn, () => {
				const items: ICompletionItem[] = fnList.map((item) => ({
					kind: CMPL_ITEM_KIND.Class,
					label: item.integrationId,
					insertText: item.integrationId,
					detail: item.description
				}));

				return items;
			});
		};
	};

	const keyPropRules = {
		integrationType: {
			required: true,
			idtType: BP_IDT_TYPE.SCALAR,
			idtScalarSubType: BP_IDT_SCALAR_SUBTYPE.STRING,
			provideCompletion: provideIntegrationTypeCompletions
		},
		functionName: {
			required: true,
			idtType: BP_IDT_TYPE.SCALAR,
			idtScalarSubType: BP_IDT_SCALAR_SUBTYPE.STRING
		},
		integrationId: {
			required: true,
			idtType: BP_IDT_TYPE.SCALAR,
			idtScalarSubType: BP_IDT_SCALAR_SUBTYPE.STRING
		},
		params: {
			required: true
		}
	};

	const updateRef = (dCtx: DesignContext, prevId: string, newId: string) => {
		if (prevId !== null) {
			dCtx.__removeRef("integration", prevId);
		}

		if (newId !== null) {
			dCtx.__addRef("integration", newId);
		}
	};

	const schema = createEmptySchema<ISchemaIntegrationRef>("integrationRef", opts);

	const assignParentToChildrenOf = (srcModel) => {
		return assignParentToModelProps(srcModel, "params");
	};

	const createModel = (
		dCtx: DesignContext,
		integrationType: string,
		functionName: string,
		integrationId: string,
		paramsModel: TParamsModel,
		validationErrors: IBlueprintSchemaValidationError[],
		parent: TBlueprintSchemaParentNode
	) => {
		const modelNode = createModelNode(schema, dCtx, parent, validationErrors, {
			integrationType: integrationType,
			functionName: functionName,
			integrationId: integrationId,
			params: paramsModel,
			__invalidateHandler: null
		});

		const model = assignParentToChildrenOf(modelNode);

		model.__invalidateHandler = () => schema.setIntegrationId(model, model.integrationId, true);
		onEvent(getResolver(dCtx).onInvalidate, model.__invalidateHandler);

		updateRef(dCtx, null, integrationId);

		return model;
	};

	schema.createDefault = (dCtx, parent, defaultValue) => {
		if (!opts?.constraints?.required && !defaultValue) {
			return null;
		}

		if (!(defaultValue instanceof Object)) {
			throw new SchemaDeclarationError(
				schema.name,
				schema.opts,
				"Expecting default value to be an object."
			);
		}

		if (!defaultValue.integrationType) {
			throw new SchemaDeclarationError(
				schema.name,
				schema.opts,
				"Expecting default value to have an 'integrationType' property."
			);
		}

		if (!defaultValue.functionName) {
			throw new SchemaDeclarationError(
				schema.name,
				schema.opts,
				"Expecting default value to have a 'functionName' property."
			);
		}

		const integrationType = defaultValue.integrationType;
		const functionName = defaultValue.functionName;
		const integrationId = defaultValue.integrationId;
		const typeExists = getResolver(dCtx).typeExists(integrationType);

		const validationErrors: IBlueprintSchemaValidationError[] = [];

		// Check integration type
		if (!typeExists) {
			// eslint-disable-next-line max-len
			throw new SchemaDeclarationError(
				schema.name,
				schema.opts,
				`Unknown integration type '${integrationType}'.`
			);
		}

		const paramsProps = getResolver(dCtx).getFunctionParamsSchema(integrationType, functionName);

		// Check params schema
		if (!paramsProps) {
			// eslint-disable-next-line max-len
			throw new SchemaDeclarationError(
				schema.name,
				schema.opts,
				`Unknown integration function '${integrationType}:${functionName}'.`
			);
		}

		// Check instance
		if (integrationId && !getResolver(dCtx).instanceExists(integrationType, integrationId)) {
			validationErrors.push({
				type: SCHEMA_VALIDATION_ERROR_TYPE.RANGE,
				message: `Integration of type '${integrationType}' with ID '${integrationId}' does not exist.`,
				metaData: {
					// @todo add to translation table
					translationTerm: "schema:integrationRef#errors.instanceNotFound",
					args: {
						integrationType: integrationType,
						integrationId: integrationId
					}
				}
			});
		} else if (!integrationId) {
			validationErrors.push({
				type: SCHEMA_VALIDATION_ERROR_TYPE.REQUIRED,
				message: `Integration ID must be specified.`,
				metaData: {
					// @todo add to translation table
					translationTerm: "schema:integrationRef#errors.instanceNotDefined"
				}
			});
		}

		const paramsSchema = defineParamsSchema(paramsProps);
		const paramsModel = paramsSchema.createDefault(dCtx, null, defaultValue?.params);

		return createModel(
			dCtx,
			integrationType,
			functionName,
			integrationId,
			paramsModel,
			validationErrors,
			parent
		);
	};

	schema.clone = (dCtx, modelNode, parent) => {
		const clonedParams = modelNode.params.schema.clone(dCtx, modelNode.params, null);

		// Cannot use cloneModelNode because of resolver's invalidate event handler
		const clone = createModel(
			dCtx,
			modelNode.integrationType,
			modelNode.functionName,
			modelNode.integrationId,
			clonedParams,
			modelNode.validationErrors.slice(),
			parent
		);

		return assignParentToChildrenOf(clone);
	};

	schema.destroy = (modelNode) => {
		offEvent(getResolver(modelNode.ctx).onInvalidate, modelNode.__invalidateHandler);

		updateRef(modelNode.ctx, modelNode.integrationId, null);

		modelNode.params.schema.destroy(modelNode.params);
		destroyModelNode(modelNode);
	};

	schema.parse = (dCtx, idtNode, parent) => {
		// Check root node type
		const { node: rootNode, isValid: isRootNodeValid } = validateIDTNode(dCtx, idtNode, {
			required: opts.constraints?.required || false,
			idtType: BP_IDT_TYPE.MAP
		});

		if (!isRootNodeValid || !rootNode) {
			return null;
		}

		// Extract keys
		const { keys, keysValid } = extractAndValidateIDTMapProperties(dCtx, rootNode, keyPropRules);

		if (!keysValid.integrationType) {
			return null;
		}

		const integrationType = (keys["integrationType"].value as IBlueprintIDTScalar).value as string;
		const functionName = (keys["functionName"]?.value as IBlueprintIDTScalar)?.value as string;
		const integrationId =
			((keys["integrationId"]?.value as IBlueprintIDTScalar)?.value as string) || null;
		const typeExists = getResolver(dCtx).typeExists(integrationType);

		const validationErrors: IBlueprintSchemaValidationError[] = [];

		// Check integration type
		if (!typeExists) {
			if (keys["integrationType"].value.parseInfo) {
				dCtx.logParseError(keys["integrationType"].value.parseInfo.loc.uri, {
					range: keys["integrationType"].value.parseInfo.loc.range,
					severity: DOC_ERROR_SEVERITY.ERROR,
					name: DOC_ERROR_NAME.INVALID_REF,
					message: `Unknown integration type '${integrationType}'.`,
					metaData: {
						// @todo add to translation table
						translationTerm: "schema:integrationRef#errors.typeNotFound",
						args: {
							integrationType: integrationType
						}
					},
					parsePath: keys["integrationType"].value.path
				});
			}
			return null;
		}

		provideIDTMapPropertyCompletions(
			dCtx,
			rootNode,
			{
				functionName: keys.functionName,
				integrationId: keys.integrationId
			},
			{
				functionName: provideFunctionNameCompletions(integrationType),
				integrationId: provideIntegrationIdCompletions(integrationType)
			}
		);

		if (!keysValid.functionName || !keysValid.integrationId) {
			return null;
		}

		const paramsProps = getResolver(dCtx).getFunctionParamsSchema(integrationType, functionName);

		// Check params schema
		if (!paramsProps) {
			if (keys["functionName"].value.parseInfo) {
				dCtx.logParseError(keys["functionName"].value.parseInfo.loc.uri, {
					range: keys["functionName"].value.parseInfo.loc.range,
					severity: DOC_ERROR_SEVERITY.ERROR,
					name: DOC_ERROR_NAME.INVALID_REF,
					message: `Unknown integration function '${integrationType}:${functionName}'.`,
					metaData: {
						// @todo add to translation table
						translationTerm: "schema:integrationRef#errors.functionNotFound",
						args: {
							integrationType: integrationType,
							functionName: functionName
						}
					},
					parsePath: keys["functionName"].value.path
				});
			}

			return null;
		}

		// Check instance
		if (integrationId && !getResolver(dCtx).instanceExists(integrationType, integrationId)) {
			const err = {
				type: SCHEMA_VALIDATION_ERROR_TYPE.RANGE,
				message: `Integration of type '${integrationType}' with ID '${integrationId}' does not exist.`,
				metaData: {
					// @todo add to translation table
					translationTerm: "schema:integrationRef#errors.instanceNotFound",
					args: {
						integrationType: integrationType,
						integrationId: integrationId
					}
				}
			};

			validationErrors.push(err);

			// Log manually, because it's only a warning so user will be able to load blueprint event when integration
			// does not exist (anymore).
			if (keys["integrationId"].value.parseInfo) {
				dCtx.logParseError(keys["integrationId"].value.parseInfo.loc.uri, {
					range: keys["integrationId"].value.parseInfo.loc.range,
					severity: DOC_ERROR_SEVERITY.ERROR,
					name: DOC_ERROR_NAME.INVALID_VALUE,
					message: err.message,
					metaData: err.metaData,
					parsePath: keys["integrationId"].value.path
				});
			}
		}

		const paramsSchema = defineParamsSchema(paramsProps);

		provideIDTMapPropertyCompletions(
			dCtx,
			rootNode,
			{
				params: keys.params
			},
			{
				params: paramsSchema.provideCompletion
			}
		);

		if (!keysValid.params) {
			return null;
		}

		const paramsModel = paramsSchema.parse(dCtx, keys["params"].value, null);

		return createModel(
			dCtx,
			integrationType,
			functionName,
			integrationId,
			paramsModel,
			validationErrors,
			parent
		);
	};

	schema.provideCompletion = (dCtx, parentLoc, minColumn, idtNode) => {
		provideIDTMapRootCompletions(dCtx, parentLoc, minColumn, idtNode, keyPropRules);
	};

	schema.serialize = (modelNode, path) => {
		return {
			type: BP_IDT_TYPE.MAP,
			path: path,
			items: [
				// integrationType
				{
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat([ "[integrationType]" ]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat([ "{integrationType}" ]),
						value: "integrationType"
					} as IBlueprintIDTScalar,
					value: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat([ "integrationType" ]),
						value: modelNode.integrationType
					}
				} as IBlueprintIDTMapElement,
				// functionName
				{
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat([ "[functionName]" ]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat([ "{functionName}" ]),
						value: "functionName"
					} as IBlueprintIDTScalar,
					value: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat([ "functionName" ]),
						value: modelNode.functionName
					}
				} as IBlueprintIDTMapElement,
				// integrationId
				{
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat([ "[integrationId]" ]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat([ "{integrationId}" ]),
						value: "integrationId"
					} as IBlueprintIDTScalar,
					value: {
						type: BP_IDT_TYPE.SCALAR,
						subType: modelNode.integrationId
							? BP_IDT_SCALAR_SUBTYPE.STRING
							: BP_IDT_SCALAR_SUBTYPE.NULL,
						path: path.concat([ "integrationId" ]),
						value: modelNode.integrationId
					}
				} as IBlueprintIDTMapElement,
				// params
				{
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat([ "[params]" ]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat([ "{params}" ]),
						value: "params"
					} as IBlueprintIDTScalar,
					value: modelNode.params.schema.serialize(modelNode.params, path.concat([ "params" ]))
				} as IBlueprintIDTMapElement
			]
		} as IBlueprintIDTMap;
	};

	schema.render = (rCtx, modelNode, path, scope, prevSpec) => {
		modelNode.lastScopeFromRender = scope;

		const params = modelNode.params.schema.render(
			rCtx,
			modelNode.params,
			path.concat([ "params" ]),
			scope,
			prevSpec?.params
		);

		return {
			integrationType: modelNode.integrationType,
			functionName: modelNode.functionName,
			integrationId: modelNode.integrationId,
			params: params
		};
	};

	schema.compileRender = (cCtx, modelNode, path) => {
		// Pre-validate params resolve state
		if (!modelNode.isValid) {
			cCtx.logValidationErrors(
				path,
				modelNode.nodeId,
				DOC_ERROR_SEVERITY.ERROR,
				modelNode.validationErrors
			);
		}

		const paramsCmp = modelNode.params.schema.compileRender(
			cCtx,
			modelNode.params,
			path.concat("params")
		);
		const paramsCode = applyCodeArg(
			paramsCmp,
			`typeof pv==="object"&&pv!==null?pv.params:undefined`,
			`pt.concat(["params"])`
		);

		return {
			isScoped: true,
			code: `(s,pv,pt)=>({${[
				`integrationType:"${escapeString(modelNode.integrationType)}"`,
				`functionName:"${escapeString(modelNode.functionName)}"`,
				`integrationId:"${escapeString(modelNode.integrationId)}"`,
				`params:${paramsCode}`
			].join(",")}})`
		};
	};

	// Always returns false because schema cannot be inlined as dynamic value
	schema.validate = (rCtx, path, modelNodeId) => {
		return validateAsNotSupported(rCtx, path, modelNodeId, schema.name);
	};

	// Always returns false because schema cannot be inlined as dynamic value
	schema.compileValidate = (cCtx, path, modelNodeId): string => {
		return compileValidateAsNotSupported(cCtx, path, modelNodeId, schema.name);
	};

	schema.export = (): ISchemaImportExport => {
		return exportSchema("SchemaIntegrationRef", [ opts ]);
	};

	schema.getTypeDescriptor = (modelNode) => {
		return TypeDescObject({
			label: opts.label,
			description: opts.description,
			props: {
				integrationType: TypeDescString({
					label: "Integration type"
				}),
				functionName: TypeDescString({
					label: "Function name"
				}),
				integrationId: TypeDescString({
					label: "Integration ID"
				}),
				params: modelNode.params.schema.getTypeDescriptor(modelNode.params)
			},
			example: opts.example,
			tags: opts.tags
		});
	};

	schema.getIntegrationInstanceList = (modelNode: ISchemaIntegrationRefModel) => {
		return getResolver(modelNode.ctx).getInstanceListByType(modelNode.integrationType);
	};

	schema.setIntegrationId = (modelNode, integrationId, notify?) => {
		const validationErrors: IBlueprintSchemaValidationError[] = [];

		// Check instance
		if (
			integrationId &&
			!getResolver(modelNode.ctx).instanceExists(modelNode.integrationType, integrationId)
		) {
			validationErrors.push({
				type: SCHEMA_VALIDATION_ERROR_TYPE.RANGE,
				message: `Integration of type '${modelNode.integrationType}' with ID '${integrationId}' does not exist.`,
				metaData: {
					// @todo add to translation table
					translationTerm: "schema:integrationRef#errors.instanceNotFound",
					args: {
						integrationType: modelNode.integrationType,
						integrationId: integrationId
					}
				}
			});
		} else if (!integrationId) {
			validationErrors.push({
				type: SCHEMA_VALIDATION_ERROR_TYPE.REQUIRED,
				message: `Integration ID must be specified.`,
				metaData: {
					// @todo add to translation table
					translationTerm: "schema:integrationRef#errors.instanceNotDefined"
				}
			});
		}

		updateRef(modelNode.ctx, modelNode.integrationId, integrationId);

		modelNode.integrationId = integrationId;
		modelNode.validationErrors = validationErrors;
		modelNode.isValid = validationErrors.length === 0 ? true : false;

		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.VALUE);
		}
	};

	schema.getChildNodes = (modelNode) => {
		return modelNode.params
			? [
					{
						key: "params",
						node: modelNode.params
					}
			  ]
			: [];
	};

	return schema;
}

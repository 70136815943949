/**
 * Hexio App Engine Function extensions base library.
 *
 * @package hae-ext-functions-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { ItemRegistry } from "@hexio_io/hae-lib-shared";
import { TGenericFunctionDefinition } from "@hexio_io/hae-lib-blueprint";
import { ITranslationManager } from "@hexio_io/hae-lib-core";

import {
	andFunc,
	concatFunc,
	ifFunc,
	orFunc,
	base64DecodeFunc,
	base64EncodeFunc,
	lowerCaseFunc,
	upperCaseFunc,
	containsFunc,
	includesFunc,
	genRangeFunc,
	genUuidFunc,
	arrPushFunc,
	arrPopFunc,
	arrSpliceFunc,
	arrSliceFunc,
	arrSumFunc,
	arrMapFunc,
	arrReduceFunc,
	arrConcatFunc,
	emptyNullFunc,
	isArray,
	isBool,
	isEmptyFunc,
	isNull,
	isNumber,
	isObject,
	isString,
	startsWithFunc,
	arrFilterFunc,
	splitFunc,
	joinFunc,
	dateFormatFunc,
	dateParseFunc,
	objKeys,
	objSetKey,
	objDeleteKey,
	objFromArray,
	jsonStringify,
	jsonParse,
	jsonIsValid,
	arrFindFunc,
	strlenFunc,
	strInsertFunc,
	dateDiff,
	dateNow,
	switchFuc,
	propsToArray,
	substringFunc,
	replaceFunc,
	minArrFunc,
	maxArrFunc,
	toStringFunc,
	ceilFunc,
	floorFunc,
	formatNumber,
	maxFunc,
	minFunc,
	absFunc,
	parseFloatFunc,
	parseIntFunc,
	qsParse,
	qsSerialize,
	roundFunc,
	toFixedFunc,
	isNaNFunc,
	isFiniteFunc,
	scopedTemplateFunc,
	parseJwt
} from "./functions";

// Export registration functions
export function registerFunctions(
	registry: ItemRegistry<TGenericFunctionDefinition>,
	translationManager: ITranslationManager
): void {

	registry.register(andFunc);
	registry.register(concatFunc);
	registry.register(ifFunc);
	registry.register(orFunc);
	registry.register(base64DecodeFunc);
	registry.register(base64EncodeFunc);
	registry.register(lowerCaseFunc);
	registry.register(upperCaseFunc);
	registry.register(emptyNullFunc);
	registry.register(containsFunc);
	registry.register(startsWithFunc);
	registry.register(strlenFunc);
	registry.register(strInsertFunc);
	registry.register(includesFunc);
	registry.register(splitFunc);
	registry.register(substringFunc);
	registry.register(replaceFunc);
	registry.register(joinFunc);
	registry.register(genRangeFunc);
	registry.register(genUuidFunc);
	registry.register(arrPushFunc);
	registry.register(arrPopFunc);
	registry.register(arrSpliceFunc);
	registry.register(arrSliceFunc);
	registry.register(arrSumFunc);
	registry.register(arrMapFunc);
	registry.register(arrFindFunc);
	registry.register(arrReduceFunc);
	registry.register(arrFilterFunc);
	registry.register(arrConcatFunc);
	registry.register(isArray);
	registry.register(isBool);
	registry.register(isEmptyFunc);
	registry.register(isNull);
	registry.register(isNumber);
	registry.register(isNaNFunc);
	registry.register(isFiniteFunc);
	registry.register(isObject);
	registry.register(isString);
	registry.register(qsParse);
	registry.register(qsSerialize);
	registry.register(roundFunc);
	registry.register(floorFunc);
	registry.register(ceilFunc);
	registry.register(minFunc);
	registry.register(maxFunc);
	registry.register(absFunc);
	registry.register(toFixedFunc);
	registry.register(dateFormatFunc);
	registry.register(dateParseFunc);
	registry.register(objKeys);
	registry.register(objSetKey);
	registry.register(objDeleteKey);
	registry.register(objFromArray);
	registry.register(jsonStringify);
	registry.register(jsonParse);
	registry.register(jsonIsValid);
	registry.register(dateDiff);
	registry.register(dateNow);
	registry.register(switchFuc);
	registry.register(formatNumber);
	registry.register(propsToArray);
	registry.register(minArrFunc);
	registry.register(maxArrFunc);
	registry.register(toStringFunc);
	registry.register(parseIntFunc);
	registry.register(parseFloatFunc);
	registry.register(scopedTemplateFunc);
	registry.register(parseJwt);

	// @todo Register translation terms

}

/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	ISchemaFlowNodeTypeOutputDefinitionMap,
	TGetBlueprintSchemaSpec
} from "@hexio_io/hae-lib-blueprint";
import { BodyBlueprintSchema, NODE_TYPES, HeadersBlueprintSchema } from "./BlueprintNode";
import { termsEditor } from "../../terms";

export const BlueprintResponseNode = {
	name: NODE_TYPES.RESPONSE,
	label: "Response",
	icon: "mdi/tray-arrow-up",
	editorOptions: {
		displayInPalette: true
	},
	opts: BP.Object({
		label: termsEditor.nodes.common.options.label,
		description: termsEditor.nodes.common.options.description,
		props: {
			status: BP.Prop(
				BP.Integer({
					label: termsEditor.nodes.common.status.label,
					description: termsEditor.nodes.common.status.description
				})
			),
			headers: BP.Prop(HeadersBlueprintSchema),
			body: BP.Prop(BodyBlueprintSchema)
		}
	}),
	resolveOutputs: (): ISchemaFlowNodeTypeOutputDefinitionMap => ({})
};

export type TBlueprintResponseNodeOptsSchemaSpec = TGetBlueprintSchemaSpec<
	typeof BlueprintResponseNode["opts"]
>;

/**
 * Table HAE component definition
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	Type,
	defineElementaryComponent,
	IScope,
	createSubScope,
	TGetComponentInstanceFromDefinition,
	COMPONENT_MODE
} from "@hexio_io/hae-lib-blueprint";

import { SORT_ORDER } from "@hexio_io/hae-lib-components";

import {
	isNonEmptyArray,
	isDeepEqual,
	isValidObject,
	isUndefined,
	isNonEmptyObjectWithValidValues
} from "@hexio_io/hae-lib-shared";

import { termsEditor } from "../../terms";

import { sortItems } from "./sortItems";
import { HAEComponentTable_Props } from "./props";
import { TTableItemIds } from "./types";
import { HAEComponentTable_State, IResolvedItem } from "./state";
import { HAEComponentTable_Events } from "./events";

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export const definition = {
	...termsEditor.components.table.component,

	name: "table",

	category: "content",

	icon: "mdi/table-plus",

	docUrl: "...",

	order: 82,

	props: HAEComponentTable_Props,

	events: HAEComponentTable_Events,

	resolve: (
		spec: any,
		state: HAEComponentTable_State,
		_updateStateAsync: unknown,
		componentInstance: TGetComponentInstanceFromDefinition<typeof HAEComponentTable_Definition>
		//_rCtx: RuntimeContext<TGenericBlueprintSchema<any, any>, TGenericRuntimeContextResolvers>
	): HAEComponentTable_State => {
		const normalComponentMode = componentInstance.componentMode === COMPONENT_MODE.NORMAL;
		const editComponentMode = componentInstance.componentMode === COMPONENT_MODE.EDIT;

		// Items

		const items: unknown[] = Array.isArray(spec.items)
			? [ ...spec.items ]
			: Array.isArray(state?.items)
			? [ ...state.items ]
			: [];

		if (editComponentMode && items.length === 0) {
			items.push(null);
		}

		let resolvedItems: IResolvedItem[];

		// Already resolved before
		if (isNonEmptyArray(state?.resolvedItems) && isDeepEqual(state?.items, items)) {
			resolvedItems = state.resolvedItems.map<IResolvedItem>((item, index) => {
				let itemScope: IScope;

				spec.rowOptions((parentScope) => {
					itemScope = createSubScope(
						parentScope,
						{ item: item.data, row: item.data, index },
						{ item: Type.Any({}), row: Type.Any({}), index: Type.Integer({}) },
						item.scope
					);

					return itemScope;
				});

				return {
					...item,
					scope: itemScope
				};
			});
		}
		// Resolve items
		else {
			resolvedItems = items.map<IResolvedItem>((item, index) => {
				let itemScope: IScope;

				const rowOptions = spec.rowOptions((parentScope) => {
					itemScope = createSubScope(
						parentScope,
						{ item, row: item, index },
						{ item: Type.Any({}), row: Type.Any({}), index: Type.Integer({}) }
					);

					return itemScope;
				});

				const id =
					typeof rowOptions.key !== "undefined" &&
					rowOptions.key !== null &&
					rowOptions.key !== "" &&
					typeof item?.[rowOptions.key] !== "undefined"
						? `${item[rowOptions.key]}`
						: `${index}`;

				return {
					id,
					data: item,
					scope: itemScope
				};
			});
		}

		// Sort

		const specSort = spec.sort;
		const sort =
			((isDeepEqual(state?.specSort, specSort) ||
				(specSort !== null && !isNonEmptyObjectWithValidValues(specSort, 1))) &&
				state?.sort) ||
			specSort;

		let order: TTableItemIds;

		if (resolvedItems.length && sort?.client) {
			order = sortItems<IResolvedItem>(
				[ ...resolvedItems ],
				(item) => {
					return isValidObject(item.data) && !isUndefined(item.data[sort.key])
						? item.data[sort.key]
						: item.data;
				},
				SORT_ORDER[sort.order]
			).map((item) => item.id);
		}

		if (!order) {
			order = resolvedItems.map((item) => item.id);
		}

		// Rows

		const rows = {};

		resolvedItems.forEach((item, index) => {
			const { id, scope } = item;

			const rowOptions = spec.rowOptions(item.scope);

			const style: Record<string, unknown> = {};

			if (rowOptions) {
				if (isNonEmptyArray(rowOptions.highlight)) {
					// Apply first highlight
					const highlight = rowOptions.highlight.find((item) => item.condition);

					if (highlight) {
						style.backgroundColor = highlight.backgroundColor;
						style.foregroundColor = highlight.foregroundColor;
					}
				}
			}

			rows[id] = {
				id,
				columns: spec.columns.map((columnItem) => {
					if (!columnItem.render && normalComponentMode) {
						return {};
					}

					const components = columnItem.content(scope, id);

					components.forEach((componentItem) => {
						componentItem.isTemplated = index > 0;
					});

					return {
						components
					};
				}),
				style,
				scope
			};
		});

		return {
			items,
			resolvedItems,
			order,
			rows,
			sort,
			specSort
		};
	},

	getScopeData: (spec: any, state: HAEComponentTable_State): any => {
		return {
			components: state.resolvedItems.map((item) => item.scope.localData),
			items: spec.items,
			sort: state.sort
		};
	},

	getScopeType: (_spec: never, state: HAEComponentTable_State, props: any): any => {
		return Type.Object({
			props: {
				components: Type.Array({
					...termsEditor.schemas.table.components,
					items: state.resolvedItems.map((item) => item.scope.localType)
				}),
				items: props.props.items.schema.getTypeDescriptor(props.props.items),
				sort: props.props.sort.schema.getTypeDescriptor(props.props.sort)
			}
		});
	}
};

export const HAEComponentTable_Definition = defineElementaryComponent<
	typeof HAEComponentTable_Props,
	HAEComponentTable_State,
	typeof HAEComponentTable_Events
>({
	...definition,
	hidden: true
});

export const HAEComponentAdvancedTable_Definition = defineElementaryComponent<
	typeof HAEComponentTable_Props,
	HAEComponentTable_State,
	typeof HAEComponentTable_Events
>({
	...definition,
	name: "advancedTable"
});

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-case-declarations */
/**
 * Hexio App Engine core library.
 *
 * @package hae-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	createEmptyScope,
	createSubScope,
	functionMapToScopeData,
	IFunctionResolver,
	IScope,
	RuntimeContext,
	RUNTIME_CONTEXT_MODE,
	TTypeDesc,
	Type,
	TypeDescAny
} from "@hexio_io/hae-lib-blueprint";
import { IViewParamsSpec, offEvent, onEvent } from "@hexio_io/hae-lib-shared";

import { IAppServer } from "../app";
import { BlueprintNodeTypes, DOC_TYPES, TActionNodesSpec, TAllNodesSpec } from "../blueprints";
import { NODE_OUTPUT_NAMES, NODE_TYPES, TNodeTypes } from "../blueprints/nodes/BlueprintNode";
import { ERROR_CODES, ERROR_NAMES, IntegrationError } from "../errors";
import { ILogger } from "../logger";
import { IExecutionOptions } from "../managers/IExecutionOptions";
import { IActionResourceProps, IResourceOnEventData, RESOURCE_TYPES } from "../resources";
import { Session } from "../sessions";
import { ExecutionContext, IExecutionContext } from "../WebServer";
import { ActionError } from "./ActionError";
import { createActionErrorResult, createActionSuccessResult, setScopeVariable } from "./helpers";
import { IActionManager } from "./IActionManager";
import { IActionParams } from "./IActionParams";
import { ACTION_ERROR_REASON, NODE_RESULT_TYPE, TActionResult } from "./IActionResult";
import { INodeDebugData } from "./INodeDebugData";
import { ITransformNodeExecutor } from "./ITransformNodeExecutor";
import { TNodeHandler } from "./TFlowNodeHandler";

import { CONST } from "../constants";
import { IAppEnvs } from "../envvars";

export type TActionViewRenderer = (
	app: IAppServer,
	viewId: string,
	params: IViewParamsSpec,
	context: IExecutionContext,
	ssrTimeoutMs?: number
) => Promise<string>;

/**
 * Action Manager Options
 */
export interface IActionManagerOptions {
	/** Debug mode */
	debug?: boolean;
	/** Renderer */
	renderer?: TActionViewRenderer;
}

export interface INodeResult {
	status?: NODE_RESULT_TYPE;
	nodeId?: string;
	outputName?: string;
	nCtx: INodeContext;
	data: {
		/** Error data */
		errorData?: {
			name?: string;
			code?: string;
			message?: string;
			detail?: any;
			/** Map/Reduce/Filter nodes error data */
			processedItems?: any[];
			errorList?: any[];
			httpStatus?: number;
			headers?: { [K: string]: string };
			errorData?: INodeResult["data"]["errorData"];
			errorTracking?: INodeResult["data"]["errorTracking"];
		};
		/** Error tracking */
		errorTracking?: {
			actionName?: string;
			nodeName?: string;
			nodeType?: string;
			integrationName?: string;
			functionName?: string;
			nestedActionCalls?: number;
		};
		/** Rest Api service */
		httpStatus?: number;
		headers?: { [K: string]: string };
	};
	typeDescriptor?: TTypeDesc;
	debug?: INodeDebugData;
}

/** Debug info */
export type TDebugInfo = {
	nodes: { [K: string]: INodeDebugData };
	parameters?: any;
	timeout?: number;
	executionTimeInMs?: number;
};

export interface IActionContext<T> {
	app: IAppServer;
	id: string;
	name: string;
	nodes: { [K: string]: T };
	invocationOrder: number;
	context: IExecutionContext;
	config: IExecutionOptions;
	maxRecursion: number;
	renderer: TActionViewRenderer;
	startTimeInMs: number;
	appId: string;
	appEnvId: string;
	appName: string;
	_app: IAppEnvs;
	timeout: number;
	debug: TDebugInfo;
	nodeHandlers: { [keys in TNodeTypes]?: TNodeHandler };
	executor: ITransformNodeExecutor;
	memoryLimit: number;
	lastNodeId?: string;
}

export interface INodeContext {
	localScope: IScope;
	nodeId: string;
	nodeName: string;
	nodeType: NODE_TYPES;
	nodeVarName?: string;
	startTimeInMs: number;
	invocationOrder: number;
	nestedActionCalls: number;
	status: NODE_RESULT_TYPE;
	integrationName?: string;
	functionName?: string;
}

export const invokeNode = async <TSpec extends Partial<TAllNodesSpec>>(
	nodeId: string,
	localScope: IScope,
	aCtx: IActionContext<TSpec>
): Promise<INodeResult> => {
	aCtx.invocationOrder++;
	aCtx.lastNodeId = nodeId;

	const nCtx: INodeContext = {
		invocationOrder: aCtx.invocationOrder,
		nestedActionCalls: aCtx.context?.meta?.actionNodeCalls || 0,
		startTimeInMs: Date.now(),
		localScope,
		nodeId,
		nodeName: null,
		nodeType: null,
		status: null
	};

	const node = aCtx.nodes[nodeId];

	if (!node) {
		const message = `Node '${nodeId}' not found.`;

		aCtx.context.warn(message);

		throw new ActionError(ERROR_NAMES.NODE_NOT_FOUND, ERROR_CODES.NODE_NOT_FOUND, message, {
			nCtx,
			reason: ACTION_ERROR_REASON.NODE_NOT_FOUND
		});
	}

	if (node.type === NODE_TYPES.ACTION) {
		aCtx.context.meta.actionNodeCalls++;
	}

	const nodeType = node.type;

	nCtx.nodeVarName = node.varName;
	nCtx.nodeName = node.varName || nodeType;

	aCtx.context.debug("Invoke node:", { nodeId, nodeType, nodeVarName: nCtx.nodeVarName });

	if (!Object.keys(BlueprintNodeTypes).includes(nodeType)) {
		const message = `Unsupported node type: '${nodeType}'.`;

		throw new ActionError(ERROR_NAMES.NODE_UNSUPPORTED_TYPE, ERROR_CODES.NODE_UNSUPPORTED_TYPE, message, {
			nCtx,
			reason: ACTION_ERROR_REASON.INVALID_BLUEPRINT
		});
	}

	const opts = node.opts(localScope);
	aCtx.context.debug("opts:", opts);

	const nodeHandler = aCtx.nodeHandlers[nodeType];

	if (!nodeHandler) {
		const message = `Unsupported node type: '${nodeType}'.`;

		throw new ActionError(ERROR_NAMES.NODE_UNSUPPORTED_TYPE, ERROR_CODES.NODE_UNSUPPORTED_TYPE, message, {
			nCtx,
			reason: ACTION_ERROR_REASON.INVALID_BLUEPRINT
		});
	}

	nCtx.nodeType = nodeType;
	aCtx.context.debug("nodeType:", nCtx.nodeType);

	const nodeResult = (await nodeHandler(opts, aCtx, nCtx)) as INodeResult;
	if (aCtx.config.debug === true) {
		aCtx.debug.nodes[nodeId] = nodeResult.debug;
	}

	return processNodeResult(nodeResult, aCtx, nCtx);
};

export const processNodeResult = async <TSpec extends Partial<TAllNodesSpec>>(
	nodeResult: INodeResult,
	aCtx: IActionContext<TSpec>,
	nCtx: INodeContext
): Promise<INodeResult> => {
	const node = aCtx.nodes[nCtx.nodeId];

	aCtx.context.debug("Process node result:", nCtx.nodeName);

	if (nodeResult.outputName && node.outputs?.[nodeResult.outputName]?.length > 0) {
		/** Node result has outputs - process them. */
		const targetNodes = node.outputs[nodeResult.outputName];

		aCtx.context.debug("Target:", { targetNodes });

		const nextPromises = [];
		const nextScope = createSubScope(nCtx.localScope);

		if (node.varName) {
			setScopeVariable(nextScope, node.varName, nodeResult.data, nodeResult.typeDescriptor);
		}

		setScopeVariable(nextScope, "prevNodeResult", nodeResult.data, {
			...(nodeResult.typeDescriptor ? nodeResult.typeDescriptor : TypeDescAny({})),
			label: "Previous node result"
		} as TTypeDesc);

		/** Make sure that `_app` wasn't rewritten by any user-defined variable. It's read-only. */
		nextScope.localData["_app"] = nextScope.globalData["_app"];

		for (let i = 0; i < targetNodes.length; i++) {
			nextPromises.push(invokeNode<TSpec>(targetNodes[i], nextScope, aCtx));
		}

		if (aCtx.timeout) {
			/** Make sure that `timeout` is used only once per action/endpoint invocation. */
			// TODO: test this.
			const timeout = aCtx.timeout;
			if (aCtx.debug) {
				aCtx.debug.timeout = timeout;
			}
			aCtx.timeout = undefined;
			return await timeoutPromise(
				timeout,
				() => Promise.race(nextPromises),
				`Invocation timeout ${timeout} ms expired.`
			);
		} else {
			return await Promise.race(nextPromises);
		}
	} else {
		/** Node result has no defined outputs - return result. */
		return nodeResult;
	}
};

/**
 * Action Manager
 */
export class ActionManager implements IActionManager {
	private onResourceEventHandler: (data: IResourceOnEventData) => Promise<void>;

	private scheduledActions: {
		[K: string]: {
			isInterval: boolean;
			timeout: NodeJS.Timeout;
		};
	} = {};

	/** Logger instance */
	protected logger: ILogger;

	public constructor(
		protected app: IAppServer,
		protected executor: ITransformNodeExecutor,
		protected actionNodeHandlers: { [keys in TNodeTypes]?: TNodeHandler },
		protected config?: IActionManagerOptions
	) {
		this.logger = app.get("logger").facility("action-manager");
	}

	/**
	 * Initialize
	 */
	public async init(): Promise<void> {
		this.logger.info("Initializing...");
		await this.executor.init();

		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const that = this;

		this.onResourceEventHandler = async (data: IResourceOnEventData) => {
			return that.onResourceEvent(data);
		};

		onEvent(this.app.get("resourceManager").onResource, this.onResourceEventHandler);
	}

	/**
	 * Disposes provider
	 */
	public async dispose(): Promise<void> {
		this.logger.info("Disposing...");

		if (this.executor) {
			await this.executor.dispose();
		}

		if (this.onResourceEventHandler) {
			offEvent(this.app.get("resourceManager").onResource, this.onResourceEventHandler);
			this.onResourceEventHandler = undefined;
		}

		for (const actionId of Object.keys(this.scheduledActions)) {
			this.logger.debug(`Clear interval/timeout of scheduled action '${actionId}'`);
			this.clearTimeout(actionId);
		}

		this.scheduledActions = {};
	}

	public isInit(): boolean {
		return true;
	}

	protected clearTimeout(actionId: string): void {
		const action = this.scheduledActions[actionId];

		if (action) {
			if (action.isInterval) {
				clearInterval(action.timeout);
			} else {
				clearTimeout(action.timeout);
			}
			delete this.scheduledActions[actionId];
		}
	}

	protected async onResourceEvent(data: IResourceOnEventData): Promise<void> {
		/** Resource changed, if not action skip */
		if (data.resource?.resourceType !== RESOURCE_TYPES.ACTION) {
			return;
		}

		/** Resource changed, clear existing scheduled action to re-initialize it the next steps. */
		if (data.resource?.id) {
			this.clearTimeout(data.resource.id);
		}

		const actions = this.app
			.get("resourceManager")
			.getResourceListByType(DOC_TYPES.ACTION_V1) as IActionResourceProps[];
		const scheduledActionsIds: string[] = [];

		for (const action of actions) {
			/** Skip if it's not scheduled action */
			if (!action.parsedData.scheduled) {
				continue;
			}
			scheduledActionsIds.push(action.id);

			/** Skip if action already scheduled */
			if (this.scheduledActions[action.id]) {
				continue;
			}

			/** Dummy context */
			const context = new ExecutionContext(new Session({}, { userLoggedIn: true }), this.logger);

			const interval = action.parsedData.scheduledInterval || 1000;
			let timeout;
			if (action.parsedData.scheduledOnce) {
				this.logger.debug(`Schedule action '${action.id}' once in ${interval} milliseconds.`);

				timeout = setTimeout(async () => {
					this.logger.debug(`Invoke scheduled action '${action.id}' once.`);
					try {
						await this.invoke(
							action.id,
							{},
							context,
							{},
							this.app.appId,
							this.app.appEnvId,
							this.app.config.appName
						);
					} catch (error) {
						// ignore
					}

					/** Cleaning up after invocation. */
					delete this.scheduledActions[action.id];
				}, interval);
			} else {
				this.logger.debug(`Schedule action '${action.id}' every ${interval} milliseconds.`);

				timeout = setInterval(async () => {
					this.logger.debug(`Invoke scheduled action '${action.id}'.`);
					try {
						await this.invoke(
							action.id,
							{},
							context,
							{},
							this.app.appId,
							this.app.appEnvId,
							this.app.config.appName
						);
					} catch (error) {
						// ignore
					}
				}, interval);
			}

			this.scheduledActions[action.id] = {
				isInterval: action.parsedData.scheduledOnce,
				timeout
			};
		}

		/** Clear all actions that is missing in the ResourceManager resource list. */
		for (const actionId of Object.keys(this.scheduledActions)) {
			if (!scheduledActionsIds.includes(actionId)) {
				this.clearTimeout(actionId);
			}
		}
	}

	public async invoke(
		actionId: string,
		params: IActionParams,
		context: IExecutionContext,
		config: IExecutionOptions,
		appId: string,
		appEnvId: string,
		appName: string,
		viaRestApi?: boolean
	): Promise<TActionResult> {
		const aCtx: IActionContext<TActionNodesSpec> = {
			app: this.app,
			nodeHandlers: this.actionNodeHandlers,
			id: actionId,
			name: null,
			appEnvId,
			appId,
			appName,
			context,
			config,
			invocationOrder: 0,
			maxRecursion: CONST.ACTION.INVOCATION.NODE_MAX_RECURSION,
			_app: { envs: {}, theme: { themeId: null, styleName: null } },
			nodes: {},
			renderer: this.config.renderer,
			startTimeInMs: Date.now(),
			// TODO: add to debug in the end!
			timeout: CONST.ACTION.INVOCATION.DEFAULT_TIMEOUT,
			debug: { nodes: {}, parameters: params },
			executor: this.executor,
			memoryLimit: CONST.ACTION.NODES.TRANSFORM.MEMORY_LIMIT
		};

		const nCtx: INodeContext = {
			invocationOrder: 0,
			nestedActionCalls: aCtx.context?.meta?.actionNodeCalls || 0,
			localScope: null,
			nodeId: NODE_TYPES.START,
			nodeName: NODE_TYPES.START,
			nodeType: NODE_TYPES.START,
			startTimeInMs: Date.now(),
			status: null
		};

		try {
			const _app: IAppEnvs = {
				envs: {},
				theme: this.app.get("resourceManager")?.getManifest()?.defaultTheme || {
					themeId: null,
					styleName: null
				}
			};
			if (this.app.has("envVarManager")) {
				_app.envs = this.app.get("envVarManager").getPublicVars();
			}

			aCtx._app = _app;

			const resource = this.app
				.get("resourceManager")
				.getResourceById(actionId) as IActionResourceProps;

			if (!resource) {
				context.warn(`Action '${actionId}' was not found.`);

				return createActionErrorResult(
					{
						name: ERROR_NAMES.ACTION_NOT_FOUND,
						code: ERROR_CODES.ACTION_NOT_FOUND,
						message: `Action '${actionId}' not found.`,
						reason: ACTION_ERROR_REASON.ACTION_NOT_FOUND
					},
					aCtx,
					nCtx
				);
			}

			if (viaRestApi === true && resource.parsedData.private === true) {
				const message = "Private action can't be invoked via Rest Api.";
				context.warn(message);

				return createActionErrorResult(
					{
						name: ERROR_NAMES.PRIVATE_ACTION,
						code: ERROR_CODES.PRIVATE_ACTION,
						message,
						reason: ACTION_ERROR_REASON.UNAUTHENTICATED
					},
					aCtx,
					nCtx
				);
			}

			aCtx.name = resource.location;

			if (!resource.isValid) {
				context.warn(`Can't invoke action '${actionId}'. Action is invalid.`);
				this.logger.debug(resource.errors);

				return createActionErrorResult(
					{
						name: ERROR_NAMES.INVALID_ACTION,
						code: ERROR_CODES.INVALID_ACTION,
						message: `Action '${actionId}' blueprint is invalid.`,
						reason: ACTION_ERROR_REASON.INVALID_BLUEPRINT
					},
					aCtx,
					nCtx
				);
			}

			if (
				resource.parsedData.requireAuthenticatedUser === true &&
				!context.session?.isAuthenticated()
			) {
				context.warn(`Action '${actionId}' requires authenticated user.`);

				return createActionErrorResult(
					{
						name: ERROR_NAMES.UNAUTHENTICATED,
						code: ERROR_CODES.UNAUTHENTICATED,
						message: "You must be logged in to invoke this action.",
						reason: ACTION_ERROR_REASON.UNAUTHENTICATED
					},
					aCtx,
					nCtx
				);
			}

			aCtx.timeout = resource.parsedData.timeout || aCtx.timeout;
			aCtx.maxRecursion = resource.parsedData.maxRecursion || aCtx.maxRecursion;

			if (context.meta?.actionNodeCalls >= aCtx.maxRecursion) {
				return createActionErrorResult(
					{
						name: "Action Max Recursion Error",
						code: ERROR_CODES.MAX_RECURSION,
						message: `Action is exceeded max (${aCtx.maxRecursion}) allowed recursive call of the action node.`,
						reason: ACTION_ERROR_REASON.MAX_RECURSION
					},
					aCtx,
					nCtx
				);
			}

			const functionScopeData = functionMapToScopeData(
				this.app.get("resolversRegistry").get<IFunctionResolver>("function").getFunctionMap(),
				config.withTypeDescriptor
			);

			let constants = {};
			if (this.app.has("constantsManager")) {
				constants = this.app.get("constantsManager").getAllConstants();
			}

			const scope = createEmptyScope(
				{
					globals: {
						...functionScopeData.data
					},
					params,
					session: context.session.export(),
					currentUser: context.user || null,
					appId,
					appEnvId: context.session?.appEnvId || appEnvId,
					appName,
					constants,
					_app
				},
				{
					globals: Type.Object({
						props: {
							...functionScopeData.type
						}
					})
				}
			);

			// Validate params
			const rCtx = new RuntimeContext(
				{
					resolvers: this.app.get("resolversRegistry").getAll(),
					mode: RUNTIME_CONTEXT_MODE.NORMAL
				},
				resource.parsedData.renderFn,
				scope
			);

			const isValid = resource.parsedData.paramsSchemaImport?.validate(rCtx, ["$"], 0, params, true);
			if (!isValid) {
				return createActionErrorResult(
					{
						name: ERROR_NAMES.INVALID_PARAMS,
						code: ERROR_CODES.INVALID_PARAMS,
						message: "Invalid action parameters.",
						reason: ACTION_ERROR_REASON.INVALID_PARAMS,
						debugDetail: {
							details: rCtx.getRuntimeErrors()
						}
					},
					aCtx,
					nCtx
				);
			}

			const spec = await rCtx.renderAsync();
			const nodesList = spec?.nodes || [];

			const nodesMap: { [K: string]: TActionNodesSpec } = {};
			nodesList.forEach((node) => {
				nodesMap[node.id] = node;
			});

			aCtx.nodes = nodesMap;

			const nodeResult = await invokeNode(NODE_TYPES.START, createSubScope(scope), aCtx);

			if (nodeResult.nCtx?.nodeType === NODE_TYPES.RETURN) {
				return createActionSuccessResult(nodeResult, aCtx);
			} else if (nodeResult.nCtx?.nodeType === NODE_TYPES.THROW) {
				const message = "Invocation error.";

				return createActionErrorResult(
					{
						name: nodeResult.data?.errorData?.name,
						code: nodeResult.data?.errorData?.code,
						message: nodeResult.data?.errorData?.message || message,
						reason: ACTION_ERROR_REASON.USER_ERROR,
						detail: nodeResult.data?.errorData?.detail
					},
					aCtx,
					nodeResult?.nCtx || nCtx,
					nodeResult
				);
			} else if (nodeResult.outputName === NODE_OUTPUT_NAMES.ON_ERROR) {
				if (nodeResult?.data?.errorData?.code === ERROR_CODES.MAX_RECURSION) {
					aCtx.context.debug("Action node max recursion error.");

					return createActionErrorResult(
						{
							name: nodeResult?.data?.errorData?.name,
							code: nodeResult?.data?.errorData?.code,
							message: nodeResult?.data?.errorData?.message,
							detail: nodeResult?.data?.errorData?.detail,
							reason: ACTION_ERROR_REASON.MAX_RECURSION
						},
						aCtx,
						nCtx,
						nodeResult
					);
				}

				const message = `Unhandled error of calling action '${nodeResult?.nCtx?.nodeName}'.`;
				this.logger.debug(message);

				// Default error handler
				return createActionErrorResult(
					{
						name: ERROR_NAMES.UNHANDLED_ERROR,
						code: ERROR_CODES.UNHANDLED_ERROR,
						message: message,
						reason: ACTION_ERROR_REASON.UNHANDLED_ERROR
					},
					aCtx,
					nodeResult?.nCtx || nCtx,
					nodeResult
				);
			} else {
				// Unhandled action end - return last data
				return createActionSuccessResult(nodeResult, aCtx);
			}
		} catch (error) {
			if (error instanceof ActionError) {
				const message = `Unhandled error.`;

				return createActionErrorResult(
					{
						name: ERROR_NAMES.UNHANDLED_ERROR,
						code: ERROR_CODES.UNHANDLED_ERROR,
						message,
						reason: ACTION_ERROR_REASON.UNHANDLED_ERROR,
						httpStatus: error.errorDetails?.safeDetails?.httpStatus,
						debugDetail: {
							error: {
								message: error.message,
								name: error.name,
								code: error.code,
								userMessage: error.userMessage,
								errorDetails: error.errorDetails
							}
						}
					},
					aCtx,
					nCtx
				);
			} else if (error instanceof IntegrationError) {
				const message = "Unhandled error.";

				return createActionErrorResult(
					{
						name: ERROR_NAMES.UNHANDLED_ERROR,
						code: ERROR_CODES.UNHANDLED_ERROR,
						message,
						reason: ACTION_ERROR_REASON.UNHANDLED_ERROR,
						httpStatus: error.errorDetails?.safeDetails?.httpStatus,
						debugDetail: {
							error: {
								message: error.message,
								name: error.name,
								code: error.code,
								userMessage: error.userMessage,
								errorDetails: error.errorDetails
							}
						}
					},
					aCtx,
					nCtx
				);
			} else {
				const message = `Unknown action error.`;

				context.warn(message);
				context.debug(error);

				// Return internal error, because this one should have been handled or should not happened
				return createActionErrorResult(
					{
						name: ERROR_NAMES.UNHANDLED_ERROR,
						code: ERROR_CODES.UNHANDLED_ERROR,
						message,
						reason: ACTION_ERROR_REASON.UNHANDLED_ERROR
					},
					aCtx,
					nCtx
				);
			}
		}
	}
}

/**
 * Promise with timeout
 *
 * @param timeout Timeout in milliseconds
 * @param promise Function that returns promise
 * @param message Error message
 * @returns
 */
export const timeoutPromise = <T>(timeout: number, promise: () => Promise<T>, message: string) => {
	let timeoutHandle: NodeJS.Timeout;

	const timeoutPromise = new Promise<never>((resolve, reject) => {
		timeoutHandle = setTimeout(() => {
			reject(new ActionError("Action Timeout", ACTION_ERROR_REASON.TIMEOUT, message));
		}, timeout);
	});

	return Promise.race([promise(), timeoutPromise]).then((result) => {
		clearTimeout(timeoutHandle);
		return result;
	});
};

/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export enum ERROR_CODES {
	HTTP_BAD_REQUEST = "badRequest",
	HTTP_UNAUTHORIZED = "unauthorized",
	HTTP_FORBIDDEN = "forbidden",
	HTTP_NOT_FOUND = "notFound",
	HTTP_METHOD_NOT_ALLOWED = "methodNotAllowed",
	HTTP_CONFLICT = "conflict",
	HTTP_UNPROCESSABLE_ENTITY = "unprocessableEntity",
	HTTP_INTERNAL_SERVER_ERROR = "internalServerError",
	HTTP_NOT_IMPLEMENTED = "notImplemented",
	HTTP_SERVICE_UNAVAILABLE = "serviceUnavailable",
	HTTP_PAYLOAD_TOO_LARGE = "payloadTooLarge",
	HTTP_UNSUPPORTED_MEDIA_TYPE = "unsupportedMediaType",
	SERVICE_INIT = "serviceInitializationError",
	INTEGRATION = "integrationError",
	INTEGRATION_NODE = "integrationNodeError",
	INTEGRATION_INIT = "integrationInitializationError",
	INTEGRATION_EXEC = "integrationExecutionError",
	INTEGRATION_TEST = "integrationTestFailed",
	SECRETS = "secretsManagerError",
	SQL_CONNECTION = "sqlDatabaseConnectionError",
	INFLUX_SYNTAX = "influxDbSyntaxError",
	INFLUX_SERVER = "influxDbServerError",
	INFLUX_CONFIG = "influxDbConfigError",
	IN_MEM_SYNTAX = "inMemoryDbSyntaxError",
	IN_MEM_SERVICE = "inMemoryDbServiceError",
	NETWORK = "networkError",
	JSON_API_SERVER = "jsonApiServerError",
	JSON_API_REQUEST = "jsonApiRequestError",
	JSON_API_CONFIG = "jsonApiConfigurationError",
	VAULT_SERVER = "vaultServerError",
	VAULT_PROVIDER = "vaultProviderError",
	PG_SERVER = "postgresqlServerError",
	MYSQL_SERVER = "mySqlServerError",
	MSSQL_SERVER = "msSqlServerError",
	ORACLE_SERVER = "oracleDbServerError",
	GRAPHQL_CONFIG = "graphqlConfigError",
	GRAPHQL_REQUEST = "graphqlRequestError",
	GRAPHQL_SERVER = "graphqlServerError",
	GRPC_CONFIG = "grpcConfigError",
	GRPC_REQUEST = "grpcRequestError",
	GRPC_SERVER = "grpcServerError",
	SMTP_CONFIG = "smtpConfigError",
	SMTP_REQUEST = "smtpRequestError",
	SMTP_SERVER = "smtpRequestError",
	SSH_TUNNEL_CONFIG = "sshTunnelConfigError",
	SSH_TUNNEL_INIT = "sshTunnelInitError",
	SSH_TUNNEL_CONNECTION = "sshTunnelConnectionError",
	SSH_CLIENT_CONFIG = "sshClientConfigError",
	STATIC_JSON_CONFIG = "jsonConfigError",
	STATIC_JSON_PARSING = "jsonParsingError",
	STATIC_YAML_CONFIG = "yamlConfigError",
	STATIC_XML_CONFIG = "xmlConfigError",
	STATIC_CSV_CONFIG = "csvConfigError",
	S3_CONFIG = "s3StorageConfigError",
	S3_REQUEST = "s3StorageRequestError",
	CONVERTER_CONFIG = "converterConfigError",
	CONVERTER_REQUEST = "converterRequestError",
	FIRESTORE_CONFIG = "firestoreConfigError",
	FIRESTORE_REQUEST = "firestoreRequestError",
	MONGODB_CONFIG = "mongoDbConfigError",
	MONGODB_SERVER = "mongoDbServerError",
	ASSET_ERROR = "assetError",
	JWT_CONFIG = "jwtInvalidConfig",
	JWT_INVALID = "jwtInvalidToken",
	JSON_FILE_FS = "jsonFileFileSystemError",
	ACTION_NOT_FOUND = "actionNotFound",
	ACTION_NODE = "actionNodeError",
	INVALID_ACTION = "actionInvalid",
	UNAUTHENTICATED = "unauthenticated",
	PRIVATE_ACTION = "privateAction",
	NODE_NOT_FOUND = "nodeNotFound",
	NODE_UNSUPPORTED_TYPE = "UnsupportedNodeType",
	// Invalid function parameters
	INVALID_PARAMS = "invalidParams",
	// Invalid blueprint
	INVALID_BLUEPRINT = "invalidBlueprint",
	// Error can't be handled
	UNHANDLED_ERROR = "unhandledError",
	// Action execution timeout
	TIMEOUT = "actionTimeout",
	MAX_RECURSION = "actionMaxRecursion",
	// Global try catch - this exception should never be thrown (in theory)
	INTERNAL_ERROR = "internalError",
	// Used internally by action delegate when request has failed - eg. because of network error
	REQUEST_ERROR = "requestError",
	ENDPOINT_NOT_FOUND = "endpointNotFound",
	INVALID_ENDPOINT = "invalidEndpoint",
	ENDPOINT_INVALID_BODY = "endpointInvalidBody",
	TRANSFORM_NODE = "transformNodeError",
	VIEW2HTML_NODE_ERROR = "view2htmlNodeError",
	MAP_NODE_ERROR = "mapNodeError",
	UNKNOWN_ERROR = "unknownError",
	USER_ERROR = "userError",
	MQTT_CONFIG = "mqttConfigError",
	MQTT_REQUEST = "mqttRequestError"
}

export enum ERROR_NAMES {
	HTTP_BAD_REQUEST = "Bad Request",
	HTTP_UNAUTHORIZED = "Unauthorized",
	HTTP_FORBIDDEN = "Forbidden",
	HTTP_NOT_FOUND = "Not found",
	HTTP_METHOD_NOT_ALLOWED = "Method Not Allowed",
	HTTP_CONFLICT = "Conflict",
	HTTP_UNPROCESSABLE_ENTITY = "Unprocessable Entity",
	HTTP_INTERNAL_SERVER_ERROR = "Internal Server Error",
	HTTP_NOT_IMPLEMENTED = "Not Implemented",
	HTTP_SERVICE_UNAVAILABLE = "Service Unavailable",
	HTTP_PAYLOAD_TOO_LARGE = "Payload Too Large",
	HTTP_UNSUPPORTED_MEDIA_TYPE = "Unsupported Media Type",
	SERVICE_INIT = "Service Initialization Error",
	INTEGRATION = "Integration Error",
	INTEGRATION_NODE = "IntegrationNode Error",
	INTEGRATION_INIT = "Integration Initialization Error",
	INTEGRATION_EXEC = "Integration Execution Error",
	INTEGRATION_TEST = "Integration Test Failed",
	SECRETS = "SecretsManager Error",
	SQL_CONNECTION = "SqlDbIntegration Connection Error",
	INFLUX_SYNTAX = "InfluxDbIntegration Syntax Error",
	INFLUX_SERVER = "InfluxDbIntegration Server Error",
	INFLUX_CONFIG = "InfluxDbIntegration Config Error",
	IN_MEM_SYNTAX = "InMemoryDbIntegration Syntax Error",
	IN_MEM_SERVICE = "InMemoryDbIntegration Service Error",
	NETWORK = "Network Error",
	JSON_API_SERVER = "JsonApiIntegration Server Error",
	JSON_API_REQUEST = "JsonApiIntegration Request Error",
	JSON_API_CONFIG = "JsonApiIntegration Configuration Error",
	VAULT_SERVER = "Vault Server Error",
	VAULT_PROVIDER = "Vault Provider Error",
	PG_SERVER = "PostgreSqlIntegration Server Error",
	MYSQL_SERVER = "MySqlIntegration Server Error",
	MSSQL_SERVER = "MsSqlIntegration Server Error",
	ORACLE_SERVER = "OracleDbIntegration Server Error",
	GRAPHQL_CONFIG = "GraphqlIntegration Config Error",
	GRAPHQL_REQUEST = "GraphqlIntegration Request Error",
	GRAPHQL_SERVER = "GraphqlIntegration Server Error",
	GRPC_CONFIG = "GrpcIntegration Config Error",
	GRPC_REQUEST = "GrpcIntegration Request Error",
	GRPC_SERVER = "GrpcIntegration Server Error",
	SMTP_CONFIG = "SmtpIntegration Config Error",
	SMTP_REQUEST = "SmtpIntegration Request Error",
	SMTP_SERVER = "SmtpIntegration Request Error",
	SSH_TUNNEL_CONFIG = "SshTunnel Config Error",
	SSH_TUNNEL_INIT = "SshTunnel Initialization Error",
	SSH_TUNNEL_CONNECTION = "SshTunnel Connection Error",
	SSH_CLIENT_CONFIG = "SshClientIntegration Config Error",
	STATIC_JSON_CONFIG = "StaticJsonIntegration Config Error",
	STATIC_JSON_PARSING = "StaticJsonIntegration Parsing Error",
	STATIC_YAML_CONFIG = "StaticYamlIntegration Config Error",
	STATIC_XML_CONFIG = "XmlIntegration Config Error",
	STATIC_CSV_CONFIG = "CsvIntegration Config Error",
	S3_CONFIG = "S3StorageIntegration Config Error",
	S3_REQUEST = "S3StorageIntegration Request Error",
	CONVERTER_CONFIG = "ConverterIntegration Config Error",
	CONVERTER_REQUEST = "ConverterIntegration Request Error",
	FIRESTORE_CONFIG = "FirestoreIntegration Config Error",
	FIRESTORE_REQUEST = "FirestoreIntegration Request Error",
	MONGODB_CONFIG = "MongoDbIntegration Config Error",
	MONGODB_SERVER = "MongoDbIntegration Server Error",
	ASSET_ERROR = "Asset Error",
	JWT_CONFIG = "JwtIntegration Config Error",
	JSON_FILE_FS = "JsonFileIntegration FileSystem Error",
	ACTION_NOT_FOUND = "Action Not Found",
	ACTION_NODE = "ActionNode Error",
	INVALID_ACTION = "Invalid Action Blueprint",
	UNAUTHENTICATED = "Unauthenticated",
	PRIVATE_ACTION = "Private Action",
	NODE_NOT_FOUND = "Node Not Found",
	NODE_UNSUPPORTED_TYPE = "Unsupported Node Type",
	// Invalid function parameters
	INVALID_PARAMS = "Invalid Parameters",
	// Invalid blueprint
	INVALID_BLUEPRINT = "Invalid Blueprint",
	// In case error node was reached - error defined by user
	// Error can't be handled
	UNHANDLED_ERROR = "Unhandled Error",
	// Action execution timeout
	TIMEOUT = "Action Timeout",
	MAX_RECURSION = "Action Max Recursion",
	// Global try catch - this exception should never be thrown (in theory)
	INTERNAL_ERROR = "Internal Error",
	// Used internally by action delegate when request has failed - eg. because of network error
	REQUEST_ERROR = "Request Error",
	ENDPOINT_NOT_FOUND = "Endpoint Not Found",
	INVALID_ENDPOINT = "Invalid Endpoint",
	ENDPOINT_INVALID_BODY = "Endpoint Invalid Body",
	TRANSFORM_NODE = "Transform Node Error",
	VIEW2HTML_NODE_ERROR = "View2HtmlNode Error",
	MAP_NODE_ERROR = "MapNode Error",
	UNKNOWN_ERROR = "Unknown Error"
}

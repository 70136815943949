/**
 * Hexio App Engine Core Library
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	CompileContext,
	createEmptyScope,
	DesignContext,
	DESIGN_CONTEXT_READ_MODE,
	loadCompiledModel,
	RuntimeContext,
	RUNTIME_CONTEXT_MODE,
	TGetBlueprintSchemaModel,
	TModelPath
} from "@hexio_io/hae-lib-blueprint";
import { createEventEmitter, onEvent, IViewParamsSpec } from "@hexio_io/hae-lib-shared";
import { RootComponentListSchema, TRootComponentListSchema } from "@hexio_io/hae-lib-components";
import { IRenderContextResolvers } from "./IRenderContext";

declare global {
	interface Window {
		APP_DEBUG: boolean;
	}
}

/**
 * Creates a model of a main view schema
 *
 * @param dCtx Design Context
 */
export function createRootViewModel(
	dCtx: DesignContext,
	viewName: string,
	viewParams: IViewParamsSpec
): TGetBlueprintSchemaModel<TRootComponentListSchema> {
	return RootComponentListSchema.createDefault(dCtx, null, [
		{
			component: "view",
			id: "main",
			props: {
				view: {
					viewId: viewName,
					params: viewParams
				}
			},
			inheritedProps: {
				containerItemFlex: {
					screenBase: "STRETCH",
					screenLarge: "STRETCH",
					screenTablet: "STRETCH",
					screenPhone: "STRETCH",
					print: "STRETCH"
				}
			}
		}
	]);
}

/**
 * Creates a root view and returns runtime context
 *
 * @param resolvers Resolvers
 * @param viewName View name
 * @param viewParams View params
 * @param parentRuntimeContext Parent Runtime Context
 * @param path Model path
 * @param inEditor In editor
 */
export function createRootViewRuntime(
	resolvers: IRenderContextResolvers,
	viewName: string,
	viewParams: IViewParamsSpec,
	parentRuntimeContext?: RuntimeContext,
	path?: TModelPath,
	inEditor?: boolean
): RuntimeContext<TRootComponentListSchema> {
	// Create design context
	const dCtx = new DesignContext({
		resolvers: {
			...resolvers,
			// Add dummy view ref resolver
			viewRef: {
				getParamsSchemaById: () => false,
				getViewList: () => [],
				onInvalidate: createEventEmitter()
			}
		},
		readMode: DESIGN_CONTEXT_READ_MODE.FULL
	});

	const cCtx = new CompileContext({
		resolvers: {}
	});

	const mainViewModel = createRootViewModel(dCtx, viewName, viewParams);
	const renderCode = cCtx.compileModel(mainViewModel);
	const renderFn = loadCompiledModel(renderCode.code);

	mainViewModel.schema.destroy(mainViewModel);
	dCtx.destroy();

	const rootScope = createEmptyScope();

	const rCtx = new RuntimeContext(
		{
			resolvers,
			mode: RUNTIME_CONTEXT_MODE.NORMAL,
			asyncRenderTimeout: -1,
			path,
			inEditor
		},
		renderFn,
		rootScope
	);

	if (parentRuntimeContext) {
		parentRuntimeContext.connectChildContext(rCtx);

		if (window.APP_DEBUG) {
			console.debug("[RootViewFactory]: View '%s' connected to parent context.", viewName);
		}

		onEvent(rCtx.destroyEvent, () => {
			parentRuntimeContext.disconnectChildContext(rCtx);

			if (window.APP_DEBUG) {
				console.debug(
					"[RootViewFactory]: View '%s' has been destroyed and disconnected from parent context.",
					viewName
				);
			}
		});
	}

	return rCtx;
}

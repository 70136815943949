/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	TGenericBlueprintSchema,
	TGetBlueprintSchemaModel,
	TGetBlueprintSchemaSpec
} from "../Schema/IBlueprintSchema";
import { IBlueprintSchemaValidationError } from "../Validator/IBlueprintSchemaValidator";
import { DOC_ERROR_NAME, DOC_ERROR_SEVERITY } from "../Shared/IDocumentError";
import { IRuntimeError } from "./IRuntimeError";
import { createEmptyScope, createSubScope, IScope, resetScopeIdentifiers } from "../Shared/Scope";
import { TModelPath } from "../Shared/TModelPath";
import {
	createEventEmitter,
	emitEvent,
	offEvent,
	onEvent,
	removeAllEventListeners,
	getPerfTime,
	IProfilerFlameGraphNode,
	Profiler
} from "@hexio_io/hae-lib-shared";

const PENDING_OP_CHECK_LIMIT = 16;
const DEFAULT_ASYNC_RENDER_TIMEOUT = 1000;

/**
 * Runtime context modes
 */
export enum RUNTIME_CONTEXT_MODE {
	NORMAL = "normal",
	READONLY = "readonly",
	EDITOR = "editor"
}

/**
 * Runtime context resolvers
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export type TGenericRuntimeContextResolvers = {};

/**
 * Runtime context options
 */
export interface IRuntimeContextOpts<TResolvers extends TGenericRuntimeContextResolvers> {
	mode: RUNTIME_CONTEXT_MODE;
	resolvers: TResolvers;
	inEditor?: boolean;
	preserveErrorsBetweenRenders?: boolean;
	asyncRenderTimeout?: number;
	path?: TModelPath;
}

/**
 * Runtime context render function
 */
export type TRuntimeContextRenderFunction<TSchema extends TGenericBlueprintSchema = TGenericBlueprintSchema> =
	(
		rCtx: RuntimeContext,
		scope: IScope,
		prevSpec?: TGetBlueprintSchemaSpec<TSchema>
	) => TGetBlueprintSchemaSpec<TSchema>;

/**
 * Runtime context profiler graph node meta-data
 */
export interface IRuntimeContextProfilerMetaData {
	path: TModelPath;
}

/**
 * Object containing count of errors by type
 */
export interface IRuntimeContextErrorCounts {
	error: number;
	warning: number;
	info: number;
	hint: number;
}

/**
 * Object representing local boundary - contains error count and loading status
 */
export interface IRuntimeContextLocalBoundary extends IRuntimeContextErrorCounts {
	isLoading: number;
	isLoadingWithData: number;
}

/**
 * Entry for a connected child context
 */
export interface IRuntimeContextChildContextEntry {
	context: RuntimeContext;
}

/**
 * Runtime context
 */
export class RuntimeContext<
	TSchema extends TGenericBlueprintSchema = TGenericBlueprintSchema,
	TResolvers extends TGenericRuntimeContextResolvers = TGenericRuntimeContextResolvers
> {
	/** Runtime mode */
	private mode: RUNTIME_CONTEXT_MODE;

	/** If runtime context is running in the editor */
	private inEditor: boolean;

	/** Runtime context path */
	private path: TModelPath = null;

	/** Profiler */
	private profiler: Profiler<IRuntimeContextProfilerMetaData>;

	/** Resolvers */
	private resolvers: TResolvers;

	/** Render function */
	private renderFn: TRuntimeContextRenderFunction<TSchema>;

	/** Event emitted when a model is re-rendered */
	public renderEvent = createEventEmitter<TGetBlueprintSchemaSpec<TSchema>>();

	/** Event emitted when an errors are updated */
	public errorEvent = createEventEmitter<void>();

	/** Event emitted when context is destroyed */
	public destroyEvent = createEventEmitter<void>();

	/** Event for 3rd party code to allow communication between individual views / runtimes */
	public customEvent = createEventEmitter<unknown>();

	/** Base scope */
	private baseScope: IScope;

	/** Previous rendered spec */
	private lastSpec: TGetBlueprintSchemaSpec<TSchema> = undefined;

	/** Scope from last render */
	private lastScope: IScope = undefined;

	/** Last generate unique ID */
	private lastUid = 0;

	/** List of pending async operations */
	private asyncOps: Promise<unknown>[] = [];

	/** Async operation revision - used to track changes */
	private asyncOpsRev = 0;

	private asyncRenderTimeout: number;

	/** Temp buffer of runtime errors - is flushed to this.runtimeErrors */
	private runtimeErrorBuffer: IRuntimeError[] = [];

	/* Count of temp error buffer types */
	private errorBufferCounts: IRuntimeContextErrorCounts = {
		error: 0,
		warning: 0,
		info: 0,
		hint: 0
	};

	/** List of local boundaries - used for "catching" errors in renderers and loading state indication */
	private localBoundariesStack: Array<IRuntimeContextLocalBoundary> = [];

	/** List of error scopes - used for "catching" errors in renderers */
	// private errorLocalScopes: Array<{ error: number; warning: number; info: number; hint: number; }> = [];
	private localBoundary: IRuntimeContextLocalBoundary = {
		error: 0,
		warning: 0,
		info: 0,
		hint: 0,
		isLoading: 0,
		isLoadingWithData: 0
	};

	/** List of runtime errors */
	private runtimeErrors: IRuntimeError[] = [];

	/* Count of error types */
	private errorCounts: IRuntimeContextErrorCounts = {
		error: 0,
		warning: 0,
		info: 0,
		hint: 0
	};

	/** If to preserve errors between render, othwerise flush errors before every render */
	private preserveErrorsBetweenRenders: boolean;

	/** If profiling is enabled */
	private profilerEnabled = false;

	/** If the model is being rendered (prevents recursive-call) */
	private isRendering = false;

	/** If the model should be re-rendered (if iterative resolution should continue) */
	private shouldRender = false;

	/** If render was already requested (eg. render for next tick was scheduled) */
	private renderRequested = false;

	/** List of model nodes that caused render invalidation in a current rendering process */
	private invalidationStack: TModelPath[][] = [];

	/** List of functions to call after no more changes were detected */
	private afterReconcileFunctions: Array<() => void>;

	/** List of functions to call when render is finished */
	private afterRenderFunctions: Array<() => void>;

	/** List of entities that are "live", if entity is not registered after render anymore, callback function will be called */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private currentLiveEntityMap: Map<any, () => void> = new Map();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private prevLiveEntityMap: Map<any, () => void> = new Map();

	/** Limit of maximum render passes */
	private renderPassLimit = 16;

	/** Number of last render passes */
	private lastRenderPassCount = 0;

	/** Last render time in ms */
	private lastRenderTime = null;

	/** Connected child contexts */
	private childContexts: IRuntimeContextChildContextEntry[] = [];

	/** Child error event handler */
	private childErrorEventHandler: () => void;

	/** If context was destroyed */
	private destroyed = false;

	/**
	 * Runtime Context
	 *
	 * @param opts Options
	 */
	public constructor(
		opts: IRuntimeContextOpts<TResolvers>,
		renderFn: TRuntimeContextRenderFunction<TSchema>,
		scope: IScope
	) {
		this.mode = opts.mode;
		this.path = opts.path || null;
		this.inEditor = opts.inEditor ?? false;
		this.resolvers = opts.resolvers;
		this.renderFn = renderFn;
		this.baseScope = scope;
		this.preserveErrorsBetweenRenders = opts.preserveErrorsBetweenRenders || false;

		this.asyncRenderTimeout =
			opts.asyncRenderTimeout !== undefined ? opts.asyncRenderTimeout : DEFAULT_ASYNC_RENDER_TIMEOUT;

		this.profiler = new Profiler({
			formatter: (node) => `${node.name} (${node.value.toFixed(4)} ms) ${node.metaData.path.join("->")}`
		});

		this.childErrorEventHandler = () => {
			if (!this.isRendering) {
				emitEvent(this.errorEvent);
			}
		};
	}

	/**
	 * Returns runtime mode
	 */
	public getMode(): RUNTIME_CONTEXT_MODE {
		return this.mode;
	}

	/**
	 * Sets a runtime mode
	 *
	 * @param mode Runtime mode
	 * @param triggerRender If to trigger re-render
	 */
	public setMode(mode: RUNTIME_CONTEXT_MODE, triggerRender = true): void {
		this.mode = mode;

		if (triggerRender) {
			this.render();
		}
	}

	/**
	 * Returns if runtime context is running in the editor
	 */
	public isInEditor(): boolean {
		return this.inEditor;
	}

	/**
	 * Returns context path (if set)
	 */
	public getContextPath(): TModelPath {
		return this.path;
	}

	/**
	 * Sets a runtime context path
	 *
	 * @param path New path
	 */
	public setContextPath(path: TModelPath): void {
		this.path = path;
	}

	/**
	 * Returns resolver by name
	 *
	 * Returns null if resolver is not available.
	 *
	 * @param name Resolver name
	 * @returns Resolver
	 */
	public getResolver<TResolver>(name: string): TResolver {
		const resolver = this.resolvers[name];

		if (resolver) {
			return resolver as unknown as TResolver;
		} else {
			return null;
		}
	}

	/**
	 * Returns context's resolvers
	 */
	public getAllResolvers(): TResolvers {
		return this.resolvers;
	}

	/**
	 * INTERNAL: Sets a new resolvers - used only for testing
	 *
	 * @param resolvers Resolvers
	 */
	public __setResolvers(resolvers: TResolvers): void {
		this.resolvers = resolvers;
	}

	/**
	 * Sets a new render function
	 *
	 * @param renderFn Render function
	 * @param triggerRender If to trigger re-render
	 */
	public setRenderFunction(renderFn: TRuntimeContextRenderFunction<TSchema>, triggerRender = true): void {
		this.renderFn = renderFn;

		if (triggerRender) {
			this.render();
		}
	}

	/**
	 * Sets a new scope
	 *
	 * @param scope New scope
	 * @param trigerRender If to trigger re-render
	 */
	public setScope(scope: IScope, trigerRender = true): void {
		this.baseScope = scope;

		if (trigerRender) {
			this.render();
		}
	}

	/**
	 * Returns a base scope
	 */
	public getScope(): IScope {
		return this.baseScope;
	}

	/**
	 * Sets the last uid
	 *
	 * @param uid UID
	 */
	public setLastUid(uid: number): void {
		this.lastUid = uid;
	}

	/**
	 * Sets last spec to undefined
	 * Basically, it resets view internal state.
	 */
	public clearLastSpec(): void {
		this.lastSpec = undefined;
	}

	/**
	 * Returns configuration value of "preserve errors between renders"
	 */
	public getPreserveErrorsBetweenRenders(): boolean {
		return this.preserveErrorsBetweenRenders;
	}

	/**
	 * Sets if to preserve errors between renders
	 *
	 * @param value Option value
	 */
	public setPreserveErrorsBetweenRenders(value: boolean): void {
		this.preserveErrorsBetweenRenders = value;
	}

	/**
	 * Enables profiler
	 */
	public enableProfiler(): void {
		this.profilerEnabled = true;
	}

	/**
	 * Disables profiler
	 */
	public disableProfiler(): void {
		this.profilerEnabled = false;
	}

	/**
	 * Returns if profiler is enabled
	 */
	public isProfilerEnabled(): boolean {
		return this.profilerEnabled;
	}

	/**
	 * INTERNAL: Returns next unique ID (eg. used to generate unique component IDs)
	 */
	public __getNextUid(): number {
		return this.lastUid++;
	}

	/**
	 * Logs a runtime error
	 *
	 * @param error Error
	 */
	public logRuntimeError(error: IRuntimeError, flushImmediately = false): void {
		if (flushImmediately) {
			this.runtimeErrors.push(error);

			switch (error.severity) {
				case DOC_ERROR_SEVERITY.ERROR:
					this.errorCounts.error++;
					this.localBoundary.error++;
					break;
				case DOC_ERROR_SEVERITY.WARNING:
					this.errorCounts.warning++;
					this.localBoundary.warning++;
					break;
				case DOC_ERROR_SEVERITY.INFO:
					this.errorCounts.info++;
					this.localBoundary.info++;
					break;
				case DOC_ERROR_SEVERITY.HINT:
					this.errorCounts.hint++;
					this.localBoundary.hint++;
					break;
			}
		} else {
			this.runtimeErrorBuffer.push(error);

			switch (error.severity) {
				case DOC_ERROR_SEVERITY.ERROR:
					this.errorBufferCounts.error++;
					this.localBoundary.error++;
					break;
				case DOC_ERROR_SEVERITY.WARNING:
					this.errorBufferCounts.warning++;
					this.localBoundary.warning++;
					break;
				case DOC_ERROR_SEVERITY.INFO:
					this.errorBufferCounts.info++;
					this.localBoundary.info++;
					break;
				case DOC_ERROR_SEVERITY.HINT:
					this.errorBufferCounts.hint++;
					this.localBoundary.hint++;
					break;
			}
		}

		if (!this.isRendering) {
			this.flushErrorsBuffer();
			emitEvent(this.errorEvent);
		}
	}

	/**
	 * Logs a runtime validation error
	 *
	 * @param modelPath Model path
	 * @param modelNodeId Model Node ID
	 * @param severity Error severity
	 * @param errors Validation errors
	 */
	public logValidationErrors(
		modelPath: TModelPath,
		modelNodeId: number,
		severity: DOC_ERROR_SEVERITY,
		errors: IBlueprintSchemaValidationError[],
		// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
		value?: any
	): void {
		const details = (errors || []).map((e) => e.message);

		if (value !== undefined) {
			details.push("Current value type: " + typeof value);
			details.push("Current value: " + JSON.stringify(value));
		}

		this.logRuntimeError({
			severity: severity,
			name: DOC_ERROR_NAME.INVALID_VALUE,
			message: "Value validation failed",
			details: details,
			modelPath: modelPath,
			modelNodeId: modelNodeId
		});
	}

	/**
	 * Returns runtime errors in a current buffer (not flushed)
	 */
	public getRuntimeErrorsBuffer(): IRuntimeError[] {
		return this.runtimeErrorBuffer;
	}

	/**
	 * Returns runtime errors
	 *
	 * @param includeChildErrors If to include errors from child contexts
	 */
	public getRuntimeErrors(includeChildErrors = true): IRuntimeError[] {
		const errors: IRuntimeError[] = this.runtimeErrors.slice();

		if (includeChildErrors) {
			for (let i = 0; i < this.childContexts.length; i++) {
				const childErrors = this.childContexts[i].context.getRuntimeErrors(true);
				const childPath = this.childContexts[i].context.getContextPath() || [];

				for (let j = 0; j < childErrors.length; j++) {
					errors.push({
						...childErrors[j],
						modelPath: childPath.concat(childErrors[j].modelPath)
					});
				}
			}
		}

		return errors;
	}

	/**
	 * Returns true if context has errors
	 *
	 * @param includeChildErrors If to include errors from child contexts
	 */
	public hasRuntimeErrors(includeChildErrors = true): boolean {
		let hasErrors = this.runtimeErrors.length > 0;

		if (includeChildErrors && !hasErrors) {
			for (let i = 0; i < this.childContexts.length; i++) {
				hasErrors &&= this.childContexts[i].context.hasRuntimeErrors();
				if (hasErrors) {
					break;
				}
			}
		}

		return hasErrors;
	}

	/**
	 * Returns count of logged errors by type
	 *
	 * @param includeChildErrors If to include errors from child contexts
	 */
	public getErrorTypeCounts(includeChildErrors = true): IRuntimeContextErrorCounts {
		const counts: IRuntimeContextErrorCounts = {
			error: this.errorCounts.error,
			warning: this.errorCounts.warning,
			info: this.errorCounts.info,
			hint: this.errorCounts.hint
		};

		if (includeChildErrors) {
			for (let i = 0; i < this.childContexts.length; i++) {
				const childCounts = this.childContexts[i].context.getErrorTypeCounts(true);

				counts.error += childCounts.error;
				counts.warning += childCounts.warning;
				counts.info += childCounts.info;
				counts.hint += childCounts.hint;
			}
		}

		return counts;
	}

	/**
	 * Returns if context has errors of type "error"
	 *
	 * @param includeChildErrors If to include errors from child contexts
	 */
	public hasFatalErrors(includeChildErrors = true): boolean {
		const counts = this.getErrorTypeCounts(includeChildErrors);
		return counts.error > 0;
	}

	/**
	 * Removes all runtime errors
	 */
	public clearAllRuntimeErrors(): void {
		this.runtimeErrors = [];

		this.errorCounts.error = 0;
		this.errorCounts.warning = 0;
		this.errorCounts.info = 0;
		this.errorCounts.hint = 0;
	}

	/**
	 * Resets error buffer counts
	 */
	private clearRuntimeErrorsBuffer() {
		this.runtimeErrorBuffer = [];

		this.errorBufferCounts.error = 0;
		this.errorBufferCounts.warning = 0;
		this.errorBufferCounts.info = 0;
		this.errorBufferCounts.hint = 0;
	}

	/**
	 * INTERNAL: Invalidates current render pass and requests a new render pass
	 *
	 * @param triggerRender If to trigger re-render (if not rendering yet, used when model change is triggred by some external event)
	 */
	public __invalidate(sender: TModelPath, triggerRender = false): void {
		this.shouldRender = true;
		this.invalidationStack[this.invalidationStack.length - 1].push(sender);

		/*
		 * DEVELOPER NOTE: Delaying render call to a next tick prevents multiple serial re-render calls caused by
		 * reacting on a single event by multiple components/schemas.
		 *
		 * Example:
		 * 1) A navigateEvent is triggered. Components RouterA and RouterB listen to the event.
		 * 2) Normally, component RouteA will react on event and updates it's own state and calls invalidate. The next render is triggered.
		 * 3) Render is completed.
		 * 4) Flow control is returned back to event emitter which calls RouteB.
		 * 5) RouteB updates it's own state and calls invalidate and render is triggered.
		 * 6) This causes 2 re-renders triggered by a single event.
		 *
		 * What will happen when render is delayed?
		 * 1) A navigateEvent is triggered. Components RouterA and RouterB listen to the event.
		 * 2) Component RouteA will react on event and calls invalidate. Render is delayed and not performed immediately.
		 * 3) Flow control is returned back to event emitter which calls RouteB.
		 * 4) RouteB calls invalidate. Runtime Context knows that render was already requested and does not schedule/run it again.
		 * 5) At next tick the RuntimeContext will perform single render even when invalidate was called multiple times.
		 */
		if (triggerRender && !this.isRendering && !this.renderRequested) {
			this.renderRequested = true;

			this.__addAsyncOperation(
				new Promise((resolve) => {
					setTimeout(() => {
						this.renderRequested = false;
						resolve(null);

						if (!this.destroyed) {
							this.render();
						}
					}, 0);
				})
			);
		}
	}

	/**
	 * INTERNAL: Sets entity as live. If entity is not live after last render pass, onDestryo function will be called.
	 *
	 * @param entity Entity
	 * @param onDestroy Destroy callback
	 */
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
	public __setEntityLive(entity: any, onDestroy: () => void): void {
		this.prevLiveEntityMap.delete(entity);
		this.currentLiveEntityMap.set(entity, onDestroy);
	}

	/**
	 * INTERNAL: Adds a function that will be called after there were no more changes
	 * Could trigger another changes.
	 *
	 * @param fn Function to call
	 */
	public __callAfterReconcile(fn: () => void): void {
		this.afterReconcileFunctions.push(fn);
	}

	/**
	 * INTERNAL: Adds a function that will be called after render is done.
	 *
	 * @param fn Function to call
	 */
	public __callAfterRender(fn: () => void): void {
		this.afterRenderFunctions.push(fn);
	}

	/**
	 * INTERNAL: Begins a new local boundary
	 */
	public __beginLocalBoundary(): void {
		this.localBoundariesStack.push({
			...this.localBoundary
		});

		this.localBoundary = {
			error: 0,
			warning: 0,
			info: 0,
			hint: 0,
			isLoading: 0,
			isLoadingWithData: 0
		};
	}

	/**
	 * INTERNAL: Ends a local boundary
	 */
	public __endLocalBoundary(): IRuntimeContextLocalBoundary {
		if (this.localBoundariesStack.length === 0) {
			throw new Error("Not in a local boundary.");
		}

		const currBoundary = this.localBoundary;
		this.localBoundary = this.localBoundariesStack.pop();

		return currBoundary;
	}

	/**
	 * INTERNAL: Logs a loading state into local boundary
	 *
	 * @param isLoading Is loading state
	 * @param isLoadingWithData Is loading with data (isLoading must be true at first)
	 */
	public __logLoadingState(isLoading: boolean, isLoadingWithData: boolean): void {
		if (isLoading) {
			this.localBoundary.isLoading++;

			if (isLoadingWithData) {
				this.localBoundary.isLoadingWithData++;
			}
		}
	}

	/**
	 * INTERNAL: Returns a local boundary
	 */
	public __getLocalBoundary(): IRuntimeContextLocalBoundary {
		return this.localBoundary;
	}

	/**
	 * INTERNAL: Starts the node profiling
	 *
	 * @param name Node name
	 * @param nodePath Node path
	 */
	public __profilerStart(name: string, path: TModelPath): void {
		if (this.profilerEnabled === false) {
			return;
		}

		this.profiler.start(name, { path: path });
	}

	/**
	 * INTERNAL: Stops the node profiling
	 */
	public __profilerStop(): void {
		if (this.profilerEnabled === false) {
			return;
		}

		this.profiler.stop();
	}

	/**
	 * INTERNAL: Add pending async operation
	 *
	 * @param op Async operation promise
	 */
	public __addAsyncOperation(op: Promise<unknown>): void {
		this.asyncOps.push(op);
		this.asyncOpsRev++;

		const removeOp = () => {
			const i = this.asyncOps.indexOf(op);

			if (i >= 0) {
				this.asyncOps.splice(i, 1);
			}
		};

		op.then(removeOp, removeOp);
	}

	/**
	 * Resets error buffer counts
	 */
	private resetLocalBoundaries() {
		this.localBoundariesStack = [];

		this.localBoundary = {
			error: 0,
			warning: 0,
			info: 0,
			hint: 0,
			isLoading: 0,
			isLoadingWithData: 0
		};
	}

	/**
	 * Adds errors from buffer to normal stack
	 */
	private flushErrorsBuffer() {
		for (let i = 0; i < this.runtimeErrorBuffer.length; i++) {
			this.runtimeErrors.push(this.runtimeErrorBuffer[i]);
		}

		this.errorCounts.error += this.errorBufferCounts.error;
		this.errorCounts.warning += this.errorBufferCounts.warning;
		this.errorCounts.info += this.errorBufferCounts.info;
		this.errorCounts.hint += this.errorBufferCounts.hint;
	}

	/**
	 * Connects a child runtime context
	 *
	 * @param childCtx Child context instance
	 */
	public connectChildContext(childCtx: RuntimeContext): void {
		for (let i = 0; i < this.childContexts.length; i++) {
			if (this.childContexts[i].context === childCtx) {
				throw new Error("Child context is already connected.");
			}
		}

		this.childContexts.push({
			context: childCtx
		});

		onEvent(childCtx.errorEvent, this.childErrorEventHandler);
	}

	/**
	 * Disconnects a child runtime context
	 *
	 * @param childCtx Child context instance
	 */
	public disconnectChildContext(childCtx: RuntimeContext): void {
		let entry: IRuntimeContextChildContextEntry;
		let index: number;

		for (let i = 0; i < this.childContexts.length; i++) {
			if (this.childContexts[i].context === childCtx) {
				entry = this.childContexts[i];
				index = i;
				break;
			}
		}

		if (entry) {
			offEvent(entry.context.errorEvent, this.childErrorEventHandler);
			this.childContexts.splice(index, 1);
		}
	}

	/**
	 * Returns connected child contexts
	 */
	public getChildContexts(): IRuntimeContextChildContextEntry[] {
		return this.childContexts;
	}

	/**
	 * Renders model
	 */
	public render(): TGetBlueprintSchemaSpec<TSchema> {
		if (this.destroyed) {
			throw new Error("Runtime Context was already destroyed, cannot render.");
		}

		// console.trace("rCtx render requested", this.path?.join("."));
		// Already rendering, just invalidate
		if (this.isRendering) {
			this.shouldRender = true;
			// console.log("Runtime render requested, but we are already rendering, added to queue.");
			return;
		}

		// console.trace("Runtime render started.");

		this.isRendering = true;
		this.shouldRender = true;

		let renderPasses = 0;
		const timeStart = getPerfTime();

		this.invalidationStack = [];
		this.afterRenderFunctions = [];
		this.afterReconcileFunctions = [];

		this.profiler.reset();
		this.__profilerStart("Root", []);

		if (!this.preserveErrorsBetweenRenders) {
			this.clearAllRuntimeErrors();
		}

		try {
			const baseScope = this.baseScope || createEmptyScope();
			const scope = createSubScope(baseScope);

			while (this.shouldRender) {
				// Prevent infinite loop
				if (renderPasses >= this.renderPassLimit) {
					const invalidationCount = {};
					const invalidationStackStr = [];

					for (let i = 0; i < this.invalidationStack.length; i++) {
						invalidationStackStr.push("Render Pass #" + i);

						for (let j = 0; j < this.invalidationStack[i].length; j++) {
							const _path = this.invalidationStack[i][j].join(".");
							invalidationCount[_path] = (invalidationCount[_path] || 0) + 1;
							invalidationStackStr.push(_path);
						}
					}

					this.logRuntimeError({
						severity: DOC_ERROR_SEVERITY.ERROR,
						name: DOC_ERROR_NAME.RENDER_LOOP,
						message: "Model resolution is excessively deep and possibly infinite.",
						modelPath: [ "$" ],
						modelNodeId: -1,
						details: Object.keys(invalidationCount)
							.map((k) => `Node "${k}" caused re-render ${invalidationCount[k]} times.`)
							.concat([ "[Invalidation Trace]" ])
							.concat(invalidationStackStr)
					});

					break;
				}

				renderPasses++;
				this.shouldRender = false;

				// --- Render pass start

				this.__profilerStart(`Pass #${renderPasses}`, []);

				this.clearRuntimeErrorsBuffer();
				this.resetLocalBoundaries();

				// Turn-over live entity map
				this.prevLiveEntityMap = this.currentLiveEntityMap;
				this.currentLiveEntityMap = new Map();

				// Reset path and component index
				scope.globalData["__componentPath"] = ([ "$" ] as Array<string | number>).concat(
					this.path ? this.path : []
				);
				resetScopeIdentifiers(scope, baseScope);

				this.invalidationStack.push([]);

				const currSpec = this.renderFn(this, scope, this.lastSpec);
				this.lastSpec = currSpec;
				this.lastScope = scope;

				// Call destroy functions of dead entities
				this.prevLiveEntityMap.forEach((cb) => cb());

				// Call after reconcile functions
				if (!this.shouldRender) {
					const _afterReconcileFunctions = this.afterReconcileFunctions.slice();
					this.afterReconcileFunctions = [];

					for (let i = 0; i < _afterReconcileFunctions.length; i++) {
						_afterReconcileFunctions[i]();
					}
				}

				this.__profilerStop();

				// --- Render pass end
			}

			for (let i = 0; i < this.afterRenderFunctions.length; i++) {
				this.afterRenderFunctions[i]();
			}
		} catch (err) {
			console.error("Unexpected model render error:", err);

			this.logRuntimeError({
				severity: DOC_ERROR_SEVERITY.ERROR,
				name: DOC_ERROR_NAME.RENDER_UNEXPECTED_ERROR,
				message: "Unexpected model render error:" + String(err),
				modelPath: [ "$" ],
				modelNodeId: -1
			});
		}

		this.__profilerStop();

		this.isRendering = false;
		this.shouldRender = false;
		this.lastRenderPassCount = renderPasses;
		this.lastRenderTime = getPerfTime() - timeStart;

		// Flush error buffer
		this.flushErrorsBuffer();

		emitEvent(this.renderEvent, this.lastSpec);
		emitEvent(this.errorEvent);

		return this.lastSpec;
	}

	/**
	 * Renders model and waits for async operations to complete
	 *
	 * @param waitForChildContexts If to wait for connected child contexts
	 */
	public renderAsync(waitForChildContexts = true): Promise<TGetBlueprintSchemaSpec<TSchema>> {
		return new Promise((resolve, reject) => {
			let terminated = false;
			let checkTimeout;

			if (this.asyncRenderTimeout > 0) {
				checkTimeout = setTimeout(() => {
					if (!terminated) {
						terminated = true;
						reject(new Error(`Async render timed-out after ${this.asyncRenderTimeout} ms.`));
					}
				}, this.asyncRenderTimeout);
			}

			const _asyncWrapper = async () => {
				let checks = 0;
				let lastRev = null;

				this.render();

				while (lastRev !== this.asyncOpsRev) {
					// console.log("Runtime (render) Asnyc ops to wait", this.path, lastRev, this.asyncOpsRev, this.asyncOps.length);

					lastRev = this.asyncOpsRev;
					checks++;

					if (checks > PENDING_OP_CHECK_LIMIT) {
						throw new Error("Async operation checks limit reached, terminating.");
					}

					await Promise.all(this.asyncOps);

					if (waitForChildContexts) {
						const childContexts = this.childContexts.slice();

						for (let i = 0; i < childContexts.length; i++) {
							await childContexts[i].context.waitForPendingOperations();
						}
					}
				}
			};

			_asyncWrapper().then(
				() => {
					if (checkTimeout) {
						clearInterval(checkTimeout);
						checkTimeout = null;
					}

					if (!terminated) {
						terminated = true;
						resolve(this.lastSpec);
					}
				},
				(err) => {
					if (checkTimeout) {
						clearInterval(checkTimeout);
						checkTimeout = null;
					}

					if (!terminated) {
						terminated = true;
						reject(err);
					}
				}
			);
		});
	}

	/**
	 * Destroys live entities and unbinds all events
	 */
	public destroy(): void {
		this.destroyed = true;

		// Call destroy functions on all current entities
		this.currentLiveEntityMap.forEach((cb) => cb());

		// Remove child contexts
		for (let i = 0; i < this.childContexts.length; i++) {
			offEvent(this.childContexts[i].context.errorEvent, this.childErrorEventHandler);
		}

		this.childContexts = [];

		removeAllEventListeners(this.renderEvent);
		removeAllEventListeners(this.errorEvent);

		emitEvent(this.destroyEvent);
		removeAllEventListeners(this.destroyEvent);
	}

	/**
	 * Returns if context was destroyed
	 */
	public isDestroyed(): boolean {
		return this.destroyed;
	}

	/**
	 * Returns promise which resolves when all async operations are finished inc. child contexts
	 *
	 * @param waitForChildContexts If to wait for connected child contexts
	 */
	public async waitForPendingOperations(waitForChildContexts = true): Promise<void> {
		let checks = 0;
		let lastRev = null;

		while (lastRev !== this.asyncOpsRev) {
			// console.log("Runtime (wait) Asnyc ops to wait", this.path, lastRev, this.asyncOpsRev, this.asyncOps.length);

			lastRev = this.asyncOpsRev;
			checks++;

			if (checks > PENDING_OP_CHECK_LIMIT) {
				throw new Error("Async operation checks limit reached, terminating.");
			}

			await Promise.all(this.asyncOps);

			if (waitForChildContexts) {
				const childContexts = this.childContexts.slice();

				for (let i = 0; i < childContexts.length; i++) {
					await childContexts[i].context.waitForPendingOperations();
				}
			}
		}
	}

	/**
	 * Returns count of render passes in the last render
	 */
	public getLastRenderPassCount(): number {
		return this.lastRenderPassCount;
	}

	/**
	 * Returns invalidation stack from last render
	 */
	public getLastInvalidationStack(): TModelPath[][] {
		return this.invalidationStack;
	}

	/**
	 * Returns last rendered spec
	 */
	public getLastSpec(): TGetBlueprintSchemaSpec<TSchema> {
		return this.lastSpec;
	}

	/**
	 * Returns last rendered scope
	 */
	public getLastScope(): IScope {
		return this.lastScope;
	}

	/**
	 * Returns the profiler flame graph
	 */
	public getProfilerFlameGraph(): IProfilerFlameGraphNode<IRuntimeContextProfilerMetaData> {
		return this.profiler.getFlameGraph();
	}

	/**
	 * Returns last render time in milliseconds
	 */
	public getLastRenderTime(): number {
		return this.lastRenderTime;
	}
}

/**
 * Loads a compiled model code and returns Runtime Context render function
 *
 * @param code Compiled model code
 */
export function loadCompiledModel<TSchema extends TGenericBlueprintSchema = TGenericBlueprintSchema>(
	code: string
): TRuntimeContextRenderFunction<TSchema> {
	const fn = new Function("rCtx", "s", "pv", "pt", code);
	return (rCtx: RuntimeContext<TSchema>, scope: IScope, prevSpec: TGetBlueprintSchemaSpec<TSchema>) =>
		fn(rCtx, scope, prevSpec, [ "$" ]);
}

/**
 * Creates a RuntimeContext render function from a schema model instance
 *
 * @param model Model instance
 */
export function createRenderFunctionFromModel<
	TSchema extends TGenericBlueprintSchema = TGenericBlueprintSchema
>(model: TGetBlueprintSchemaModel<TSchema>): TRuntimeContextRenderFunction<TSchema> {
	return (rCtx: RuntimeContext<TSchema>, scope: IScope, prevSpec: TGetBlueprintSchemaSpec<TSchema>) =>
		model.schema.render(rCtx, model, [ "$" ], scope, prevSpec);
}

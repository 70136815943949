/**
 * Hexio App Engine Core Library
 *
 * @package hae-lib-core
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { IEventHandlerMetaData, IEventHandlerResult, Type } from "@hexio_io/hae-lib-blueprint";
import {
	IOverlayCloseResult,
	IResolveLinkOpts,
	IToastMessage,
	OVERLAY_CONFIRM_BUTTON_ID,
	OVERLAY_TYPE,
	TLinkLocationSpec,
	TOAST_MESSAGE_TYPE,
	TOverlay
} from "@hexio_io/hae-lib-components";
import { ACTION_DELEGATE_STATE, ACTION_ERROR_REASON, IActionDelegate, IActionParams } from "../actions";
import { TCoreComponentEventNodeOpts, TCoreComponentEventNodeTypes } from "./EventNodeTypes";
import { uiTerms } from "../terms/ui";
import { emitEvent } from "@hexio_io/hae-lib-shared";

/**
 * Event handler log functions
 * Logs event step details
 */
export type IEventHandlerLogFn = (
	eventType: string,
	opts: unknown,
	metaData: IEventHandlerMetaData,
	result: IEventHandlerResult
) => void;

/**
 * Event method error object
 */
export interface IEventMethodError {
	name: string;
	message: string;
}

/**
 * Event handlers interface
 */
export type TCoreComponentEventHandlers = {
	[K in keyof TCoreComponentEventNodeTypes]: (
		opts: TCoreComponentEventNodeOpts<K>,
		metaData: IEventHandlerMetaData
	) => Promise<IEventHandlerResult>;
};

export interface ICoreComponentEventHandlersOpts {
	/** Event logging functions */
	logEvent: IEventHandlerLogFn;

	/** Function to return action delegate */
	getActionDelegate: (actionId: string, params: IActionParams) => IActionDelegate;

	/** Function to navigate client to a link */
	navigate: (linkSpec: TLinkLocationSpec, opts: IResolveLinkOpts, openInNew: boolean) => void;

	/** Function to navigate client to previous link */
	navigateBack: () => void;

	/** Function to show an overlay */
	openOverlay: (overlaySpec: TOverlay) => Promise<IOverlayCloseResult>;

	/** Function to close an overlay */
	closeOverlay: (overlayId: string, buttonId?: string, customData?: unknown) => void;

	/** Function to show a toast message */
	showToastMessage: (toastMessage: IToastMessage) => string;

	/** Function to hide a toast message */
	hideToastMessage: (toastMessageId: string) => void;

	/** Function to reload session */
	reloadSession: (reloadUserAccount?: boolean) => Promise<void>;
}

const methodErrorType = Type.Object({
	label: uiTerms.events.types.methodError.label,
	props: {
		name: Type.String({
			label: uiTerms.events.types.methodError.nameLabel
		}),
		message: Type.String({
			label: uiTerms.events.types.methodError.messageLabel
		})
	}
});

/**
 * Creates core component event handlers
 *
 * @param config Configuration options
 */
export const createCoreComponentEventHandlers = (
	config: ICoreComponentEventHandlersOpts
): TCoreComponentEventHandlers => ({
	/**
	 * Action
	 * Invokes an action
	 */
	action: async (opts, metaData) => {
		let res: IEventHandlerResult;

		const actionDelegate = config.getActionDelegate(opts.actionId, opts.params);
		await actionDelegate.invoke(true);

		const state = actionDelegate.getState();

		if (state === ACTION_DELEGATE_STATE.LOADED) {
			res = {
				outputName: "onSuccess",
				data: actionDelegate.getData(),
				type: actionDelegate.getTypeDescriptor() || Type.Any({})
			};
		} else {
			let outputName;
			const error = actionDelegate.getLastError();

			if (
				error?.reason === ACTION_ERROR_REASON.USER_ERROR ||
				error?.reason === ACTION_ERROR_REASON.UNHANDLED_ERROR
			) {
				outputName = "onError";
			} else {
				outputName = "onRequestFail";
			}

			res = {
				outputName: outputName,
				data: error,
				type: actionDelegate.getTypeDescriptor() || Type.Any({})
			};
		}

		if (
			(res.outputName === "onError" && !metaData.outputsConnected["onError"]) ||
			(res.outputName === "onRequestFail" && !metaData.outputsConnected["onRequestFail"])
		) {
			config.showToastMessage({
				type: TOAST_MESSAGE_TYPE.ERROR,
				message: uiTerms.events.messages.actionHasFailed,
				details: (res.data as IEventMethodError).message
			});
		}

		config.logEvent("action", opts, metaData, res);
		return res;
	},

	/**
	 * Authorization
	 * Activates output based on a condition
	 * If onError output is connected, displayed an error toast
	 */
	auth: async (opts, metaData) => {
		const res = {
			outputName: opts.condition ? "onAuthorize" : "onError",
			data: opts.condition,
			type: Type.Boolean({
				label: uiTerms.events.types.authAllowed
			})
		};

		if (!opts.condition && !metaData.outputsConnected["onError"]) {
			config.showToastMessage({
				type: TOAST_MESSAGE_TYPE.WARNING,
				message: uiTerms.events.messages.actionNotAllowed
			});
		}

		config.logEvent("auth", opts, metaData, res);
		return res;
	},

	/**
	 * Component Method
	 * Calls a component method
	 */
	cmpMethod: async (opts, metaData) => {
		let res: IEventHandlerResult;
		
		try {
			if (opts.method && typeof opts.method === "function") {
				const retValue = opts.method.apply(opts.method, opts.args);

				res = {
					outputName: "onSuccess",
					data: retValue,
					type: Type.Any({})
				};
			} else {
				const errObject: IEventMethodError = {
					name: "VALUE_NOT_METHOD",
					message: uiTerms.events.messages.valueIsNotMethod
				};

				res = {
					outputName: "onError",
					data: errObject,
					type: methodErrorType
				};
			}
		} catch (err) {
			const errObject: IEventMethodError = {
				name: err instanceof Error ? err.name : "ERROR",
				message: String(err)
			};

			res = {
				outputName: "onError",
				data: errObject,
				type: methodErrorType
			};
		}

		if (res.outputName === "onError" && !metaData.outputsConnected["onError"]) {
			config.showToastMessage({
				type: TOAST_MESSAGE_TYPE.WARNING,
				message: uiTerms.events.messages.methodHasFailed,
				details: (res.data as IEventMethodError).message
			});
		}

		config.logEvent("cmpMethod", opts, metaData, res);
		return res;
	},

	/**
	 * Condition
	 * Actives output based on a condition
	 */
	condition: async (opts, metaData) => {
		const res = {
			outputName: opts.condition ? "onTrue" : "onFalse",
			data: opts.condition,
			type: Type.Boolean({
				label: uiTerms.events.types.conditionResult
			})
		};

		config.logEvent("condition", opts, metaData, res);
		return res;
	},

	/**
	 * Delay
	 * Activates output after specified timeout
	 */
	delay: async (opts, metaData) => {
		const res = {
			outputName: "onTimeout",
			data: null,
			type: Type.Null({})
		};

		config.logEvent("delay", opts, metaData, res);

		return new Promise((resolve) => {
			setTimeout(() => resolve(res), opts.timeout);
		});
	},

	/**
	 * Emit custom event
	 * Emits a custom event via runtime context
	 */
	emitCustomEvent: async (opts, metaData) => {
		const res = {
			outputName: null,
			data: null,
			type: Type.Null({})
		};

		config.logEvent("emitCustomEvent", opts, metaData, res);

		emitEvent(metaData.rCtx.customEvent, opts.eventData);

		return res;
	},

	/**
	 * Event start
	 * Activates single output without data
	 */
	eventStart: async (opts, metaData) => {
		const res = {
			outputName: "onEvent",
			data: null,
			type: Type.Null({})
		};

		config.logEvent("eventStart", opts, metaData, res);
		return res;
	},

	/**
	 * Navigate
	 * Navigates to an URL address
	 */
	navigate: async (opts, metaData) => {
		const res = {
			outputName: null,
			data: null,
			type: Type.Null({})
		};

		config.navigate(opts.link, {}, opts.openInNew);

		config.logEvent("navigate", opts, metaData, res);
		return res;
	},

	/**
	 * Navigate Back
	 * Navigates to previous URL address
	 */
	navigateBack: async (opts, metaData) => {
		const res = {
			outputName: null,
			data: null,
			type: Type.Null({})
		};

		config.navigateBack();

		config.logEvent("navigateBack", opts, metaData, res);
		return res;
	},

	/**
	 * Reload Session
	 */
	reloadSession: async (opts, metaData) => {
		const res = {
			outputName: "onReload",
			data: null,
			type: Type.Null({})
		};

		config.logEvent("reloadSession", opts, metaData, res);

		await config.reloadSession(opts.reloadUserAccount);

		return res;
	},

	/**
	 * Show Dialog
	 */
	openDialog: async (opts, metaData) => {
		const result = await config.openOverlay({
			type: OVERLAY_TYPE.DIALOG_INFO,
			id: opts.id,
			header: {
				text: opts.headerText,
				icon: opts.headerIcon
			},
			text: opts.text,
			size: opts.size,
			closable: opts.closable,
			buttons: opts.buttons
		});

		const res = {
			outputName: "onClose",
			data: {
				buttonId: result.buttonId,
				customData: result.customData
			},
			type: Type.Object({
				label: uiTerms.events.types.dialogResult,
				props: {
					buttonId: Type.String({
						label: uiTerms.events.types.dialogButtonId
					}),
					customData: Type.Any({
						label: uiTerms.events.types.dialogCustomData
					})
				}
			})
		};

		config.logEvent("openDialog", opts, metaData, res);
		return res;
	},

	/**
	 * Shows confirmation dialog
	 */
	openConfirmationDialog: async (opts, metaData) => {
		const result = await config.openOverlay({
			type: OVERLAY_TYPE.DIALOG_CONFIRMATION,
			id: opts.id,
			header: {
				text: opts.headerText,
				icon: opts.headerIcon
			},
			text: opts.text,
			size: opts.size,
			confirmValue: opts.confirmValue,
			confirmButton: opts.confirmButton,
			cancelButton: opts.cancelButton
		});

		const res = {
			outputName: result.buttonId === OVERLAY_CONFIRM_BUTTON_ID ? "onConfirm" : "onCancel",
			data: null,
			type: Type.Null({})
		};

		config.logEvent("openConfirmationDialog", opts, metaData, res);
		return res;
	},

	/**
	 * Opens dialog with a custom view
	 */
	openViewDialog: async (opts, metaData) => {
		const result = await config.openOverlay({
			type: OVERLAY_TYPE.DIALOG_VIEW,
			id: opts.id,
			header: {
				text: opts.headerText,
				icon: opts.headerIcon
			},
			view: opts.view,
			size: opts.size,
			closable: opts.closable
		});

		const res = {
			outputName: "onClose",
			data: {
				buttonId: result.buttonId,
				customData: result.customData
			},
			type: Type.Object({
				label: uiTerms.events.types.dialogResult,
				props: {
					buttonId: Type.String({
						label: uiTerms.events.types.dialogButtonId
					}),
					customData: Type.Any({
						label: uiTerms.events.types.dialogCustomData
					})
				}
			})
		};

		config.logEvent("openViewDialog", opts, metaData, res);
		return res;
	},

	/**
	 * Opens sidebar with a custom view
	 */
	openViewSidebar: async (opts, metaData) => {
		const result = await config.openOverlay({
			type: OVERLAY_TYPE.SIDEBAR_VIEW,
			id: opts.id,
			header: {
				text: opts.headerText,
				icon: opts.headerIcon
			},
			view: opts.view,
			size: opts.size,
			closable: opts.closable
		});

		const res = {
			outputName: "onClose",
			data: {
				buttonId: result.buttonId,
				customData: result.customData
			},
			type: Type.Object({
				label: uiTerms.events.types.dialogResult,
				props: {
					buttonId: Type.String({
						label: uiTerms.events.types.dialogButtonId
					}),
					customData: Type.Any({
						label: uiTerms.events.types.dialogCustomData
					})
				}
			})
		};

		config.logEvent("openViewSidebar", opts, metaData, res);
		return res;
	},

	/**
	 * Closes dialog or sidebar
	 */
	closeOverlay: async (opts, metaData) => {
		const res = {
			outputName: null,
			data: null,
			type: null
		};

		config.closeOverlay(opts.id, opts.buttonId, opts.customData);

		config.logEvent("closeOverlay", opts, metaData, res);
		return res;
	},

	/**
	 * Show Message
	 * Shows a toast message
	 */
	showMessage: async (opts, metaData) => {
		const res = {
			outputName: null,
			data: null,
			type: Type.Null({})
		};

		let toastType: TOAST_MESSAGE_TYPE;

		switch (opts.type) {
			case "INFO": {
				toastType = TOAST_MESSAGE_TYPE.INFO;
				break;
			}
			case "SUCCESS": {
				toastType = TOAST_MESSAGE_TYPE.SUCCESS;
				break;
			}
			case "WARNING": {
				toastType = TOAST_MESSAGE_TYPE.WARNING;
				break;
			}
			case "ERROR": {
				toastType = TOAST_MESSAGE_TYPE.ERROR;
				break;
			}
		}

		config.showToastMessage({
			id: opts.id,
			type: toastType,
			message: opts.message,
			details: opts.details,
			duration: opts.duration
		});

		config.logEvent("showMessage", opts, metaData, res);
		return res;
	}
});

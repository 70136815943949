/**
 * Range Field HAE component
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import {
	BP,
	defineElementaryComponent,
	COMPONENT_MODE,
	SCHEMA_CONST_ANY_VALUE_TYPE,
	Type
} from "@hexio_io/hae-lib-blueprint";

import {
	ClassList,
	Label,
	propGroups,
	Range,
	RangeProps,
	THAEComponentDefinition,
	THAEComponentReact
} from "@hexio_io/hae-lib-components";

import { isDefined, isNonEmptyObject } from "@hexio_io/hae-lib-shared";

import { termsEditor } from "../../terms";
import { FieldBaseProps } from "./props";
import { getFieldStateProps, useField } from "./useField";
import { FieldLabelInfo } from "./FieldLabelInfo";
import { FieldInfo } from "./FieldInfo";
import { IFieldState } from "./state";
import { HAEComponentField_Events } from "./events";
import { createFieldClassListModifiers } from "./createFieldClassListModifiers";

interface IHAEComponentRangeFieldValue {
	from: number;
	to: number;
}

interface HAEComponentRangeField_State extends IFieldState {
	initialValue: IHAEComponentRangeFieldValue;
	value: IHAEComponentRangeFieldValue;
}

const { labelText, labelIcon, descriptionText, readOnly, enabled, validate, required } = FieldBaseProps;

const HAEComponentRangeField_Props = {
	labelText,

	labelIcon,

	descriptionText,

	readOnly,

	enabled,

	validate,

	required,

	//...FieldBaseProps, // Custom validation is not supported

	value: BP.Prop(
		BP.Object({
			...termsEditor.schemas.rangeField.value,
			props: {
				from: BP.Prop(
					BP.Float({
						...termsEditor.schemas.rangeField.valueFrom
					}),
					0
				),
				to: BP.Prop(
					BP.Float({
						...termsEditor.schemas.rangeField.valueTo
					}),
					10
				)
			}
		}),
		0,
		propGroups.common
	),

	min: BP.Prop(
		BP.Float({
			...termsEditor.schemas.rangeField.min,
			default: 0,
			fallbackValue: 0,
			constraints: {
				required: true
			}
		}),
		200,
		propGroups.validation
	),

	max: BP.Prop(
		BP.Float({
			...termsEditor.schemas.rangeField.max,
			default: 100,
			fallbackValue: 100,
			constraints: {
				required: true
			}
		}),
		210,
		propGroups.validation
	),

	step: BP.Prop(
		BP.Float({
			...termsEditor.schemas.rangeField.step
		}),
		220,
		propGroups.validation
	)
};

const HAEComponentRangeField_Events = {
	...HAEComponentField_Events
};

const HAEComponentRangeField_Definition = defineElementaryComponent<
	typeof HAEComponentRangeField_Props,
	HAEComponentRangeField_State,
	typeof HAEComponentRangeField_Events
>({
	...termsEditor.components.rangeField.component,

	name: "rangeField",

	category: "form",

	icon: "mdi/electric-switch-closed",

	docUrl: "...",

	order: 100,

	props: HAEComponentRangeField_Props,

	events: HAEComponentRangeField_Events,

	resolve: (spec, state, updateStateAsync) => {
		const initialValue = (isNonEmptyObject(spec.value) ? spec.value : state?.initialValue) || {
			from: null,
			to: null
		};

		const value = {
			from:
				state?.initialValue?.from === initialValue.from
					? isDefined(state?.value?.from)
						? state.value.from
						: spec.value?.from
					: spec.value?.from,
			to:
				state?.initialValue?.to === initialValue.to
					? isDefined(state?.value?.to)
						? state.value.to
						: spec.value?.to
					: spec.value?.to
		};

		function setValue(value: IHAEComponentRangeFieldValue) {
			updateStateAsync((prevState) => ({ ...prevState, value: { ...prevState.value, ...value } }));
		}

		function clearValue(initial = false) {
			updateStateAsync((prevState) => ({
				...prevState,
				value: !initial ? { from: null, to: null } : initialValue
			}));
		}

		return {
			value,
			initialValue,
			...getFieldStateProps(value, initialValue, state, spec.validate, false),
			setValue,
			clearValue
		};
	},

	getScopeData: (spec, state) => {
		return {
			initialValue: spec.value,
			value: state.value,
			valid: state.valid,
			setValue: state.setValue,
			clearValue: state.clearValue
		};
	},

	getScopeType: (spec, state, props) => {
		return Type.Object({
			props: {
				initialValue: Type.Object({
					...termsEditor.schemas.rangeField.initialValue,
					props: {
						from: Type.Float({}),
						to: Type.Float({})
					}
				}),
				value: props.props.value.schema.getTypeDescriptor(props.props.value),
				valid: Type.Boolean({ ...termsEditor.schemas.field.valid }),
				setValue: Type.Method({
					...termsEditor.schemas.field.setValue,
					argRequiredCount: 1,
					argSchemas: [ BP.Any({ defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.MAP }) ],
					argRestSchema: null,
					returnType: Type.Void({})
				}),
				clearValue: Type.Method({
					...termsEditor.schemas.field.clearValue,
					argRequiredCount: 0,
					argSchemas: [ BP.Boolean({ default: false }) ],
					argRestSchema: null,
					returnType: Type.Void({})
				})
			}
		});
	}
});

const HAEComponentRangeField_React: THAEComponentReact<typeof HAEComponentRangeField_Definition> = ({
	props,
	state,
	setState,
	componentInstance,
	reactComponentClassList
}) => {
	const {
		min,
		max,
		step,

		labelText,
		labelIcon,
		descriptionText,
		//hidden,
		readOnly,
		enabled,
		//validate,
		required
		//customValidation
	} = props;

	const { value, touched, changed, valid } = state;

	const { componentMode } = componentInstance;

	const elementReadOnly = readOnly || componentMode !== COMPONENT_MODE.NORMAL;

	const componentPath = componentInstance.safePath;

	// Classlist

	const { classList, idClassName: id } = ClassList.getElementClassListAndIdClassName(
		"cmp-field",
		componentPath,
		{ componentInstance, componentClassList: reactComponentClassList }
	);

	classList.addModifiers({
		range: true,
		validate: props.validate
	});
	classList.addModifiers(
		createFieldClassListModifiers(classList, { enabled, touched, changed, valid }),
		false
	);

	const validate = React.useMemo(() => {
		if (!props.validate) {
			return false;
		}

		return ({ from, to }) => {
			if (readOnly || !enabled) {
				return true;
			}

			if (Number.isFinite(from) && Number.isFinite(to)) {
				return from <= to && [ from, to ].every((value) => value >= min && value <= max);
			}

			if ([ from, to ].some((value) => Number.isFinite(value))) {
				return false;
			}

			return !required;
		};
	}, [ props.validate, readOnly, enabled, required, min, max ]);

	const { setValue, setTouched } = useField<IHAEComponentRangeFieldValue>(
		{
			id,
			state,
			//readOnly, // not needed
			validate,
			//validationDependencies: [ required ],
			isEmpty: false,
			onChange:
				!elementReadOnly && componentInstance.eventEnabled.change
					? componentInstance.eventTriggers.change
					: undefined
		},
		setState
	);

	const rangeProps: RangeProps = {
		min,
		max,
		step,
		disabled: !enabled || elementReadOnly,
		dots: Number.isFinite(step),
		value: [ value.from, value.to ]
	};

	// Event handlers

	const _rangeChangeHandler: RangeProps["onChange"] = React.useCallback(
		([ from, to ]) => {
			setTouched();

			setValue({ from, to });
		},
		[ setValue, setTouched ]
	);

	return (
		<div className={classList.toClassName()}>
			<Label
				text={{ ...labelText, tagName: "span" }}
				icon={{ ...labelIcon, size: "SMALL" }}
				tagName="label"
				htmlFor={id}
				classList={new ClassList("cmp-field__label")}
				componentPath={[ ...componentPath, "label" ]}
				componentMode={componentMode}
			>
				<FieldLabelInfo required={required} />
			</Label>

			<div className="cmp-field__content">
				<Range {...rangeProps} onChange={_rangeChangeHandler} />
			</div>

			<FieldInfo
				descriptionText={descriptionText}
				componentPath={[ ...componentPath, "info" ]}
				componentMode={componentMode}
			/>
		</div>
	);
};

export const HAEComponentRangeField: THAEComponentDefinition<typeof HAEComponentRangeField_Definition> = {
	...HAEComponentRangeField_Definition,
	reactComponent: HAEComponentRangeField_React
};

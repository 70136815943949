/**
 * Table HAE component
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React from "react";

import {
	THAEComponentDefinition,
	THAEComponentReact,
	SORT_ORDER,
	SORT_ORDER_MODE,
	SORT_ORDER_MODE_values,
	Text,
	ClassList,
	StyleSheet,
	getStringEnumKeyByValue,
	ICON_NAME,
	useLoading,
	LoadingInfo,
	EditContext,
	useDnDStateChangeHandler,
	Button,
	getStringEnumCssValue,
	SPACING,
	useStyleSheetRegistry,
	useComponentMainContext
} from "@hexio_io/hae-lib-components";

import { isNonEmptyArray, isNonEmptyString } from "@hexio_io/hae-lib-shared";

import { TableContent } from "./TableContent";
import { HAEComponentAdvancedTable_Definition, HAEComponentTable_Definition } from "./definition";
import { resolveTableBorderStyleSheet, resolveTableColumnsClassListsAndStyleSheet } from "./helpers";
// import { createItemStyle } from "@hexio_io/hae-lib-components/src/SharedSchemas/ItemComponent";
import { COMPONENT_MODE, RUNTIME_CONTEXT_MODE } from "@hexio_io/hae-lib-blueprint";
import { TableCellTitle } from "./TableCellTitle";

const HAEComponentTable_React: THAEComponentReact<typeof HAEComponentAdvancedTable_Definition> = ({
	props,
	state,
	setState,
	componentInstance,
	reactComponentClassList
}) => {
	const { columns, borderData, spacing, emptyText } = props;
	const { order, rows, sort } = state;

	const { safePath: componentPath, componentMode } = componentInstance;

	const componentMainContext = useComponentMainContext();
	const editor = componentMainContext.rCtx.getMode() === RUNTIME_CONTEXT_MODE.EDITOR;

	const editContext = React.useContext(EditContext);

	const elementRef = React.useRef<HTMLDivElement>();

	const [ loading, loadingRef ] = useLoading(componentInstance.isLoading);

	const empty = !order.length && !loading;
	const headerDefined = columns.some((item) => isNonEmptyArray(item.titles));

	const sortEnabled = !!sort;
	const sortOrderValue = (sortEnabled && sort?.order) in SORT_ORDER ? SORT_ORDER[sort.order] : null;

	const { classList, idClassName } = ClassList.getElementClassListAndIdClassName(
		"cmp-table",
		componentPath,
		{ componentInstance, componentClassList: reactComponentClassList }
	);

	classList.addModifiers({
		filled: !empty,
		empty,
		loading,
		"with-header": headerDefined
	});

	const { classList: tableClassList, idClassName: tableIdClassName } =
		ClassList.getElementClassListAndIdClassName("table", componentPath, { componentInstance });

	tableClassList.add("cmp-table__table");

	tableClassList.addModifiers({
		border: borderData.type.toLowerCase()
	});

	//const tableContentSelector = `${tableIdClassName} > .table__content`;
	//const tableItemSelector = `${tableContentSelector} > .table__row > .table__cell > .table__cell-item`;

	//const itemSelector = `${contentSelector} > .container__item`;

	const styleSheetRegistry = useStyleSheetRegistry();

	const styleSheet = React.useMemo(() => {
		const result = resolveTableBorderStyleSheet(tableIdClassName, borderData);

		// Spacing

		if (spacing) {
			const spacingValue = getStringEnumCssValue(SPACING, spacing, "spacing-");

			result.addString(`.${idClassName}`, `--element-spacing: ${spacingValue};`);
		}

		return result;
	}, [ idClassName, tableIdClassName, borderData, spacing ]);

	const { columnsClassLists, columnsStyleSheet } = React.useMemo(() => {
		return resolveTableColumnsClassListsAndStyleSheet(columns, componentPath, componentMode, editor);
	}, [ columns, componentPath, componentMode, editor ]);

	/*const { itemsClassLists, itemsStyleSheet } = React.useMemo(() => {
		const resultClassLists: ClassList[] = [];
		const resultStyleSheet = new StyleSheet();

		columns.forEach((item) => {
			item.content.

			item.content.forEach((contentItem, index) => {
				const {
					classList: itemClassList, idClassName: itemIdClassName
				} = ClassList.getElementClassListAndIdClassName("container__item", contentItem.safePath, { componentMode });
	
				const itemIdSelector = `${tableItemSelector}.${itemIdClassName}`;
	
				const itemStyle = createItemStyle(itemIdSelector, contentItem.inheritedProps);
	
				itemClassList.add(`container__item--name-${contentItem.componentName}`);
	
				resultStyleSheet.add(...itemStyle.styleSheet);
	
				resultClassLists[index] = itemClassList;
			});
		});

		return {
			itemsClassLists: resultClassLists,
			itemsStyleSheet: resultStyleSheet
		};
	}, [ tableItemSelector, columns, componentMode ]);*/

	const allStyleSheets = React.useMemo(() => {
		return new StyleSheet(...styleSheet, ...columnsStyleSheet);
	}, [ styleSheet.toString(), columnsStyleSheet.toString() ]);

	styleSheetRegistry.add(idClassName, allStyleSheets);

	// Drag & drop column width fix

	useDnDStateChangeHandler(editContext, (active) => {
		if (!elementRef.current) {
			return;
		}

		const cells = Array.from<HTMLElement>(elementRef.current.querySelectorAll(".table__cell"));

		if (active) {
			cells.forEach((item) => {
				const rect = item.getBoundingClientRect();

				item.style.width = `${rect.width}px`;
			});
		} else {
			cells.forEach((item) => (item.style.width = ""));
		}
	});

	// Title change handler

	/*const _titleChangeHandler = React.useCallback((event: React.FormEvent<HTMLInputElement>) => {
		const target = event.currentTarget;
		const [ columnIndex, columnTitleIndex ] = target.name.split("-").map((item) => parseInt(item, 10));
		const { value } = target;

		const column = ((componentInstance.modelNode.props.props.columns as Record<string, any>).items as any)[columnIndex];

		if (!column) {
			return;
		}

		const columnTitle = column.props.titles.constant.items[columnTitleIndex];

		if (!columnTitle) {
			return;
		}

		const columnTitleTextValueConst = columnTitle.constant.props.textValue.constant;

		console.log("V", value, columnTitleTextValueConst);

		columnTitleTextValueConst.schema.setValue(columnTitleTextValueConst, value);
	}, []);*/

	// Header

	const tableHeaderContent = headerDefined ? (
		<thead className="table__header">
			<tr className="table__row">
				{columns.map((columnItem, columnIndex) => {
					if (!columnItem.render && componentMode === COMPONENT_MODE.NORMAL) {
						return null;
					}

					const columnKey = `header-column-${columnIndex}`;

					return (
						<th key={columnKey} className={columnsClassLists[columnIndex].toClassName()}>
							{isNonEmptyArray(columnItem.titles)
								? columnItem.titles.map((columnTitleItem, columnTitleIndex) => {
										const cellKey = `${columnKey}-${columnTitleIndex}`;

										const { sortKey: columnSortKey, sortOrderMode } = columnTitleItem;
										const sortOrderModeValue = SORT_ORDER_MODE[sortOrderMode];

										const columnSortEnabled =
											sortEnabled &&
											isNonEmptyString(columnSortKey) &&
											sortOrderModeValue !== SORT_ORDER_MODE.NONE;

										let _sortButtonClickHandler: (
											newSortOrderValue: SORT_ORDER_MODE
										) => void;

										if (columnSortEnabled) {
											_sortButtonClickHandler = (newSortOrderValue) => {
												setState({
													...state,
													sort: {
														...state.sort,
														key: columnSortKey,
														order: getStringEnumKeyByValue(
															SORT_ORDER,
															newSortOrderValue
														)
													}
												});
											};
										}

										return (
											<TableCellTitle
												key={cellKey}
												columnKey={columnSortKey}
												textValue={columnTitleItem.textValue}
												sort={
													columnSortEnabled
														? {
																key: sort.key,
																orderValue: sortOrderValue,
																orderModeValue: SORT_ORDER_MODE[sortOrderMode]
														  }
														: undefined
												}
												componentPath={[ ...componentPath, cellKey, "cell-title" ]}
												componentMode={componentMode}
												onButtonClick={_sortButtonClickHandler}
											/>
										);
								  })
								: null}
						</th>
					);
				})}
			</tr>
		</thead>
	) : null;

	return (
		<div ref={elementRef} className={classList.toClassName()}>
			<div className="cmp-table__container">
				<div className="cmp-table__inner">
					{!empty || tableHeaderContent ? (
						<table className={tableClassList.toClassName()}>
							{tableHeaderContent}

							{!empty ? (
								<TableContent
									rows={rows}
									order={order}
									columnsClassLists={columnsClassLists}
									componentInstance={componentInstance}
									componentPath={componentPath}
								/>
							) : null}
						</table>
					) : null}

					{loading ? (
						<div ref={loadingRef} className="state-info">
							<LoadingInfo />
						</div>
					) : null}

					{empty && emptyText && emptyText.value ? (
						<Text
							{...emptyText}
							componentPath={[ ...componentPath, "empty-text" ]}
							classList={new ClassList("cmp-table__empty-text")}
							componentMode={componentMode}
						/>
					) : null}
				</div>
			</div>
		</div>
	);
};

export const HAEComponentTable: THAEComponentDefinition<typeof HAEComponentTable_Definition> = {
	...HAEComponentTable_Definition,
	reactComponent: HAEComponentTable_React
};

export const HAEComponentAdvancedTable: THAEComponentDefinition<typeof HAEComponentAdvancedTable_Definition> =
	{
		...HAEComponentAdvancedTable_Definition,
		reactComponent: HAEComponentTable_React
	};

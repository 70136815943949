/**
 * File Field HAE component props
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { BP, TSchemaConstObjectPropsSpec } from "@hexio_io/hae-lib-blueprint";

import { termsEditor as HAELibComponentsTerms, propGroups } from "@hexio_io/hae-lib-components";

import { HTTP_METHOD } from "@hexio_io/hae-lib-core";

import { termsEditor } from "../../terms";
import { FILE_FIELD_TYPE_default } from "../../Enums/FILE_FIELD_TYPE";
import { FILE_FIELD_URL_default } from "../../Enums/FILE_FIELD_URL";
import { CustomValidationProps, FieldBaseProps } from "./props";

const StaticUrlProps = {
	method: BP.Prop(
		BP.Enum.String({
			...HAELibComponentsTerms.schemas.httpRequest.method,
			options: [
				{
					value: HTTP_METHOD.POST,
					...HAELibComponentsTerms.schemas.httpRequest.methodValues.post
				},
				{
					value: HTTP_METHOD.PUT,
					...HAELibComponentsTerms.schemas.httpRequest.methodValues.put
				}
			],
			default: "POST",
			fallbackValue: "POST",
			editorOptions: {
				controlType: "buttons"
			},
			constraints: {
				required: true
			}
		}),
		0
	),

	url: BP.Prop(
		BP.String({
			...HAELibComponentsTerms.schemas.httpRequest.url,
			default: "",
			fallbackValue: "",
			constraints: {
				required: true
			}
		}),
		10
	),

	formDataKey: BP.Prop(
		BP.String({
			...termsEditor.schemas.fileField.formDataKey,
			default: ""
		}),
		20
	),

	headers: BP.Prop(
		BP.Map({
			...HAELibComponentsTerms.schemas.httpRequest.headers,
			value: BP.String({})
		}),
		30
	)
};

export type TStaticUrlProps = Partial<TSchemaConstObjectPropsSpec<typeof StaticUrlProps>>;

export const HAEComponentFileField_Props = {
	labelText: FieldBaseProps.labelText,

	labelIcon: FieldBaseProps.labelIcon,

	descriptionText: FieldBaseProps.descriptionText,

	enabled: FieldBaseProps.enabled,

	validate: FieldBaseProps.validate,

	required: FieldBaseProps.required,

	customValidation: BP.Prop(
		BP.OptGroup({
			...termsEditor.schemas.field.customValidation,
			enabledOpts: {
				default: false,
				fallbackValue: false
			},
			value: BP.Object({
				props: {
					condition: CustomValidationProps.condition
				},
				editorOptions: {
					layoutType: "passthrough"
				}
			})
		}),
		FieldBaseProps.customValidation.order,
		FieldBaseProps.customValidation.group
	),

	urlData: BP.Prop(
		BP.OneOf({
			...termsEditor.schemas.fileField.url,
			typeValueOpts: {
				...termsEditor.schemas.fileField.url
			},
			defaultType: FILE_FIELD_URL_default,
			types: {
				STATIC: {
					...termsEditor.schemas.fileField.urlValues.static,
					value: BP.Object({
						props: StaticUrlProps,
						editorOptions: {
							layoutType: "passthrough"
						}
					})
				},
				ACTION: {
					...termsEditor.schemas.fileField.urlValues.action,
					value: BP.ScopedTemplate({
						...termsEditor.schemas.fileField.action,
						template: BP.ActionRef({})
					})
				}
			},
			constraints: {
				required: true
			},
			editorOptions: {
				layoutType: "noHeader"
			}
		}),
		0,
		propGroups.common
	),

	uploadImmediately: BP.Prop(
		BP.Boolean({
			...termsEditor.schemas.fileField.uploadImmediately,
			default: false,
			fallbackValue: false,
			constraints: {
				required: true
			}
		}),
		10,
		propGroups.common
	),

	typeData: BP.Prop(
		BP.OneOf({
			...termsEditor.schemas.fileField.type,
			typeValueOpts: {
				...termsEditor.schemas.fileField.type
			},
			defaultType: FILE_FIELD_TYPE_default,
			types: {
				SIMPLE: {
					...termsEditor.schemas.fileField.typeValues.simple,
					value: BP.Object({
						props: {
							fileNames: BP.Prop(
								BP.Boolean({
									...termsEditor.schemas.fileField.fileNames,
									default: true,
									fallbackValue: true,
									constraints: {
										required: true
									}
								}),
								0
							)
						},
						editorOptions: {
							layoutType: "passthrough"
						}
					})
				},
				LIST: {
					...termsEditor.schemas.fileField.typeValues.list,
					value: BP.Void({})
				}
				/*"GALLERY": {
				...termsEditor.schemas.fileField.typeValues.gallery,
				value: BP.Void({})
			},*/
			},
			constraints: {
				required: true
			},
			editorOptions: {
				layoutType: "noHeader"
			}
		}),
		20,
		propGroups.common
	),

	accept: BP.Prop(
		BP.String({
			...termsEditor.schemas.fileField.accept,
			default: ""
		}),
		30,
		propGroups.common
	),

	multiple: BP.Prop(
		BP.OptGroup({
			...termsEditor.schemas.fileField.multiple,
			enabledOpts: {
				default: false,
				fallbackValue: false
			},
			value: BP.Object({
				props: {
					max: BP.Prop(
						BP.Integer({
							...termsEditor.schemas.fileField.multipleMax,
							constraints: {
								min: 1
							}
						}),
						0
					)
				},
				editorOptions: {
					layoutType: "passthrough"
				}
			})
		}),
		40,
		propGroups.common
	),

	maxFileSize: BP.Prop(
		BP.Integer({
			...termsEditor.schemas.fileField.maxFileSize,
			default: null,
			constraints: {
				min: 1
			}
		}),
		50,
		propGroups.common
	),

	requestTimeout: BP.Prop(
		BP.Integer({
			...termsEditor.schemas.fileField.requestTimeout,
			default: 30000,
			constraints: {
				min: 1
			}
		}),
		60,
		propGroups.common
	),

	customPlaceholder: BP.Prop(
		BP.String({
			...termsEditor.schemas.fileField.customPlaceholder
		}),
		70,
		propGroups.common
	),

	customPlaceholderTouch: BP.Prop(
		BP.String({
			...termsEditor.schemas.fileField.customPlaceholderTouch
		}),
		80,
		propGroups.common
	),

	customMaxFilesExceededMessage: BP.Prop(
		BP.String({
			...termsEditor.schemas.fileField.customMaxFilesExceededMessage
		}),
		90,
		propGroups.common
	)
};

/**
 * Table schema terms
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const table = {
	items: {
		label: "schema:table.items#label",
		description: "schema:table.items#description"
	},
	components: {
		label: "schema:table.components#label",
		description: "schema:table.components#description"
	},
	rows: {
		label: "schema:table.rows#label",
		description: "schema:table.rows#description"
	},
	columns: {
		label: "schema:table.columns#label",
		description: "schema:table.columns#description"
	},
	columnTitles: {
		label: "schema:table.columnTitles#label",
		description: "schema:table.columnTitles#description"
	},
	columnTitle: {
		label: "schema:table.columnTitle#label",
		description: "schema:table.columnTitle#description"
	},
	columnKey: {
		label: "schema:table.columnKey#label",
		description: "schema:table.columnKey#description"
	},
	columnMapper: {
		label: "schema:table.columnMapper#label",
		description: "schema:table.columnMapper#description"
	},
	columnFormatter: {
		label: "schema:table.columnFormatter#label",
		description: "schema:table.columnFormatter#description"
	},
	/*columnMapperExpression: {
		label: "schema:table.columnMapperExpression#label",
		description: "schema:table.columnMapperExpression#description"
	},*/
	columnTypeData: {
		label: "schema:table.columnTypeData#label"
	},
	columnTypeDataValues: {
		string: {
			label: "schema:table.columnTypeData#values.string#label"
		},
		number: {
			label: "schema:table.columnTypeData#values.number#label"
		},
		boolean: {
			label: "schema:table.columnTypeData#values.boolean#label"
		}
	},
	columnSelectAll: {
		label: "schema:table.columnSelectAll#label",
		description: "schema:table.columnSelectAll#description"
	},
	columnSortKey: {
		label: "schema:table.columnSortKey#label"
	},
	columnSortOrderMode: {
		label: "schema:table.columnSortOrderMode#label"
	},
	columnContent: {
		label: "schema:table.columnContent#label"
	},
	columnWidth: {
		label: "schema:table.columnWidth#label"
	},
	columnMinWidth: {
		label: "schema:table.columnMinWidth#label"
	},
	columnEditable: {
		label: "schema:table.columnEditable#label"
	},
	columnVisible: {
		label: "schema:table.columnVisible#label"
	},
	columnRender: {
		label: "schema:table.columnRender#label"
	},
	rowOptions: {
		label: "schema:table.rowOptions#label"
	},
	rowOptionsKey: {
		label: "schema:table.rowOptions#props.key#label"
	},
	rowOptionsHighlight: {
		label: "schema:table.rowOptions#props.highlight#label"
	},
	rowOptionsHighlightCondition: {
		label: "schema:table.rowOptions#props.highlightCondition#label"
	},
	borderData: {
		label: "schema:table.borderData#label",
		description: "schema:table.borderData#description"
	},
	borderDataValues: {
		none: {
			label: "schema:table.borderData#values.none#label"
		},
		rows: {
			label: "schema:table.borderData#values.rows#label"
		},
		cells: {
			label: "schema:table.borderData#values.cells#label"
		}
	},
	spacing: {
		label: "schema:table.spacing#label",
		description: "schema:table.spacing#description"
	},
	pagination: {
		label: "schema:table.pagination#label"
	},
	paginationStyle: {
		label: "schema:table.paginationStyle#label"
	},
	sort: {
		label: "schema:table.sort#label"
	},
	sortKey: {
		label: "schema:table.sortKey#label"
	},
	sortOrder: {
		label: "schema:table.sortOrder#label"
	},
	sortOrderValues: {
		asc: {
			label: "schema:table.sortOrder#values.asc#label"
		},
		desc: {
			label: "schema:table.sortOrder#values.desc#label"
		}
	},
	sortClient: {
		label: "schema:table.sortClient#label",
		description: "schema:table.sortClient#description"
	},
	itemCount: {
		label: "schema:table.itemCount#label"
	},
	csvExport: {
		label: "schema:table.csvExport#label"
	},
	csvExportHeader: {
		label: "schema:table.csvExport#props.header#label"
	},
	emptyText: {
		label: "schema:table.emptyText#label"
	},
	events: {
		rowClick: {
			label: "schema:table.events.rowClick#label",
			description: "schema:table.events.rowClick#description"
		}
	}
};

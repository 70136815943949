/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP_IDT_SCALAR_SUBTYPE,
	BP_IDT_TYPE,
	IBlueprintIDTMap,
	IBlueprintIDTMapElement,
	IBlueprintIDTScalar,
	TBlueprintIDTNode
} from "../IDT/ISchemaIDT";
import {
	IBlueprintSchema,
	IBlueprintSchemaOpts,
	TBlueprintSchemaParentNode,
	TGenericBlueprintSchema,
	TGetBlueprintSchemaDefault,
	TGetBlueprintSchemaModel
} from "../Schema/IBlueprintSchema";
import { IModelNode, MODEL_CHANGE_TYPE } from "../Schema/IModelNode";
import { SchemaDeclarationError } from "../Schema/SchemaDeclarationError";
import {
	assignParentToModelProps,
	cloneModelNode,
	compileValidateAsNotSupported,
	createEmptySchema,
	createModelNode,
	destroyModelNode,
	handleModelNodeChange,
	validateAsNotSupported
} from "../Schema/SchemaHelpers";
import { DOC_ERROR_NAME, DOC_ERROR_SEVERITY } from "../Shared/IDocumentError";
import { DesignContext } from "../Context/DesignContext";
import { exportSchema } from "../ExportImportSchema/ExportSchema";
import { ISchemaValue, ISchemaValueDefault, ISchemaValueModel, SCHEMA_VALUE_TYPE } from "./value/SchemaValue";
import {
	ISchemaConstBoolean,
	ISchemaConstBooleanModel,
	SchemaConstBoolean
} from "./const/SchemaConstBoolean";
import {
	TGenericComponentInstance,
	TGenericComponentInstanceList,
	TSchemaComponentDisplaySpec
} from "../Component/IComponentInstance";
import {
	ISchemaConstObject,
	ISchemaConstObjectModel,
	ISchemaConstObjectOptsProp,
	Prop,
	SchemaConstObject,
	TSchemaConstObjectProps
} from "./const/SchemaConstObject";
import { IComponentStateBase } from "../Component/IComponentStateBase";
import { IComponentDefinition, TGenericComponentDefinition } from "../Component/IComponentDefinition";
import { ISchemaConstArrayModel, SchemaConstArray } from "./const/SchemaConstArray";
import { ISchemaConstString, ISchemaConstStringModel, SchemaConstString } from "./const/SchemaConstString";
import { SchemaValueBoolean } from "./value/SchemaValueBoolean";
import { ISchemaOverride, SchemaOverride } from "./SchemaOverride";
import { RUNTIME_CONTEXT_MODE } from "../Context/RuntimeContext";
import { applyCodeArg, escapeString, inlineValue } from "../Context/CompileUtil";
import { TypeDescVoid } from "../Shared/ITypeDescriptor";
import { ISchemaComments, ISchemaCommentsModel, SchemaComments } from "./SchemaComments";
import { ISchemaConstEnum } from "./const/SchemaConstEnum";
import { MEDIA_RESOLUTIONS, TMapMediaResolutions } from "../constants";
import { SchemaValueEnumString } from "./value/SchemaValueEnum";
import { SchemaValueObject } from "./value/SchemaValueObject";
import { SchemaValueString } from "./value/SchemaValueString";
import { IComponentResolver } from "../Resolvers";
import { ISchemaFlowNodeList, SchemaFlowNodeList } from "./SchemaFlowNodeList";
import { ISchemaFlowNodeTypeDefinitionMap } from "./SchemaFlowNode";
import { ISchemaScopedTemplate, SchemaScopedTemplate } from "./SchemaScopedTemplate";
import { ISchemaComponentListModel } from "./SchemaComponentList";
import { serializeIDTToYAML } from "../IDT/IDTToYAML";
import { parseYAMLToIDT } from "../IDT/YAMLToIDT";
import { emitEvent, offEvent, onEvent } from "@hexio_io/hae-lib-shared";
import {
	extractAndValidateIDTMapProperties,
	provideIDTMapPropertyCompletions,
	provideIDTMapRootCompletions
} from "../Context/ParseUtil";
import { IDocumentLocation } from "../Shared/IDocumentLocation";
import { CMPL_ITEM_KIND, ICompletionItem } from "../Shared/ICompletionItem";
import { TGenericEventDefinitionMap } from "../Events/EventTypes";
import { IEventResolver } from "../Resolvers/IEventResolver";

export type TSchemaComponentIdModel = ISchemaConstStringModel;
export type TSchemaComponentPropsModel = ISchemaConstObjectModel<TSchemaConstObjectProps>;
export type TSchemaComponentInheritedPropsModel = ISchemaConstObjectModel<TSchemaConstObjectProps>;
export type TSchemaComponentVisibleModel = ISchemaValueModel<ISchemaConstBoolean>;
export type TSchemaComponentCommentsModel = ISchemaCommentsModel;
export type TSchemaComponentReadOnlyModel = ISchemaConstBooleanModel;

export type TSchemaComponentModifiersModel = ISchemaConstArrayModel<
	ISchemaConstObject<{
		name: ISchemaConstObjectOptsProp<ISchemaConstString>;
		enabled: ISchemaConstObjectOptsProp<ISchemaValue<ISchemaConstBoolean>>;
		props: ISchemaConstObjectModel<{
			[K: string]: ISchemaConstObjectOptsProp<ISchemaOverride<TGenericBlueprintSchema>>;
		}>;
		inheritedProps: ISchemaConstObjectModel<{
			[K: string]: ISchemaConstObjectOptsProp<ISchemaOverride<TGenericBlueprintSchema>>;
		}>;
		preview: ISchemaConstObjectOptsProp<ISchemaConstBoolean>;
	}>
>;

export type TSchemaComponentEventHandler = ISchemaConstObject<{
	nodes: ISchemaConstObjectOptsProp<
		ISchemaScopedTemplate<ISchemaFlowNodeList<ISchemaFlowNodeTypeDefinitionMap>>
	>;
}>;

export type TSchemaComponentEvents = ISchemaConstObject<{
	[K: string]: ISchemaConstObjectOptsProp<TSchemaComponentEventHandler>;
}>;

export type TSchemaComponentEventsModel = TGetBlueprintSchemaModel<TSchemaComponentEvents>;

export type TSchemaComponentDisplaySchema = ISchemaConstObject<{
	cssClassName: ISchemaConstObjectOptsProp<ISchemaValue<ISchemaConstString>>;
	cssVisibility: ISchemaConstObjectOptsProp<ISchemaValue<ISchemaConstEnum<ISchemaConstString>>>;
	mediaResolution: ISchemaConstObjectOptsProp<
		ISchemaValue<
			ISchemaConstObject<
				TMapMediaResolutions<ISchemaConstObjectOptsProp<ISchemaValue<ISchemaConstBoolean>>>
			>
		>
	>;
	showLoading: ISchemaConstObjectOptsProp<ISchemaConstBoolean>;
}>;

export type TSchemaComponentDisplayModel = TGetBlueprintSchemaModel<TSchemaComponentDisplaySchema>;
export type TSchemaComponentDisplayDefault = TGetBlueprintSchemaDefault<TSchemaComponentDisplaySchema>;

/**
 * Schema model
 */
export interface ISchemaComponentModel extends IModelNode<ISchemaComponent> {
	componentName: string;
	componentHandler: IComponentDefinition<
		TSchemaConstObjectProps,
		IComponentStateBase,
		TGenericEventDefinitionMap
	>;
	id: TSchemaComponentIdModel;
	props: TSchemaComponentPropsModel;
	inheritedProps: TSchemaComponentInheritedPropsModel;
	modifiers: TSchemaComponentModifiersModel;
	events: TSchemaComponentEventsModel;
	visible: TSchemaComponentVisibleModel;
	comments: TSchemaComponentCommentsModel;
	display: TSchemaComponentDisplayModel;
	readOnly: TSchemaComponentReadOnlyModel;
	parentComponentList: ISchemaComponentListModel;
	__inheritedPropsIDT?: TBlueprintIDTNode;
	__modifiersIDT?: TBlueprintIDTNode;
	__updateIdIndex: () => void;
}

/**
 * Component modifier default value
 */
export interface ISchemaComponentModifierDefault {
	name: string;
	enabled: ISchemaValueDefault<ISchemaConstBoolean>;
	props: { [K: string]: unknown };
	inheritedProps: { [K: string]: unknown };
	preview: false;
}

/**
 * Schema spec
 */
export type TSchemaComponentSpec = TGenericComponentInstanceList;

/**
 * Schema defaults
 */
export interface ISchemaComponentDefault {
	component: string;
	id: string;
	props: { [K: string]: unknown };
	inheritedProps: { [K: string]: unknown };
	modifiers?: ISchemaComponentModifierDefault[];
	events?: TGetBlueprintSchemaDefault<TSchemaComponentEvents>;
	visible?: ISchemaValueDefault<ISchemaConstBoolean>;
	comments?: TGetBlueprintSchemaDefault<ISchemaComments>;
	display?: TSchemaComponentDisplayDefault;
	readOnly?: TGetBlueprintSchemaDefault<TSchemaComponentReadOnlyModel>;
}

/**
 * Schema options
 */
export interface ISchemaComponentOpts extends IBlueprintSchemaOpts {
	/** Allowed component categories */
	allowComponentCategories?: Array<string>;
	/** Explicit list of allowed component names */
	allowComponentNames?: Array<string>;
}

/**
 * Schema create opts
 */
export interface ISchemaComponentCreateOpts {
	componentListModel?: ISchemaComponentListModel;
}

/**
 * Schema type
 */
export interface ISchemaComponent
	extends IBlueprintSchema<
		ISchemaComponentOpts,
		ISchemaComponentModel,
		TSchemaComponentSpec,
		ISchemaComponentDefault,
		ISchemaComponentCreateOpts
	> {
	/**
	 * Assigns inherited props (replaces old one)
	 *
	 * @param modelNode Model node
	 * @param listModel Component list model
	 * @param notify If to notify about change
	 */
	assignToComponentList: (
		modelNode: ISchemaComponentModel,
		listModel: ISchemaComponentListModel,
		notify?: boolean
	) => void;

	/**
	 * Un-assigns inherited props
	 *
	 * @param modelNode Model node
	 * @param notify If to notify about change
	 */
	unassignFromComponentList: (modelNode: ISchemaComponentModel, notify?: boolean) => void;

	/**
	 * Removes the component from assigned list
	 *
	 * @param modelNode Model node
	 * @param notify If to notify about change
	 * @param destroy If to destroy self
	 */
	removeSelfFromComponentList: (
		modelNode: ISchemaComponentModel,
		notify?: boolean,
		destroy?: boolean
	) => void;
}

/**
 * Schema: Component
 *
 * @param opts Schema options
 */
export function SchemaComponent(opts: ISchemaComponentOpts): ISchemaComponent {
	const schema = createEmptySchema<ISchemaComponent>("component", opts);

	const schemaId = SchemaConstString({
		label: "Component ID",
		constraints: {
			required: true,
			min: 1
		},
		fallbackValue: "undefinedId"
	});

	const schemaVisible = SchemaValueBoolean({
		label: "Render",
		// eslint-disable-next-line max-len
		description:
			"If component should be rendered. When set to FALSE component will not exists at all and will loose its state. Set to FALSE when you want to remove the component completely - for example based on user's permissions.",
		default: true,
		fallbackValue: true
	});

	const schemaComments = SchemaComments({
		label: "Comments"
	});

	const schemaReadOnly = SchemaConstBoolean({
		label: "Read Only",
		description: "If component properties are editable via editor."
	});

	const schemaDisplay: TSchemaComponentDisplaySchema = SchemaConstObject({
		label: "Display",
		description: "Specifies when and how to display the component.",
		props: {
			cssClassName: Prop(
				SchemaValueString({
					label: "CSS Class",
					description: "Can be used with custom theme css.",
					fallbackValue: null
					// editorOptions: {
					// 	hidden: true
					// }
				}),
				10
			),
			cssVisibility: Prop(
				SchemaValueEnumString({
					label: "CSS Visibility",
					description: "Allows to specify if the component should be visible or hidden.",
					options: [
						{
							label: "Visible",
							value: "visible",
							description: "Component is normally visible."
						},
						{
							label: "Hidden",
							value: "hidden",
							description: "Component is hidden but still taking its space."
						}
					],
					default: "visible",
					fallbackValue: "visible"
				}),
				20
			),
			showLoading: Prop(
				SchemaConstBoolean({
					label: "Show Auto Loading",
					description:
						"If enabled the component will display a loading indicator while waiting for data.",
					default: false,
					fallbackValue: false
				}),
				25
			),
			mediaResolution: Prop(
				SchemaValueObject({
					label: "Media Resolutions",
					description: "Specifies on which screen resolutions the component should be displayed.",
					props: (() => {
						const opts = {};

						for (const k in MEDIA_RESOLUTIONS) {
							opts[k] = Prop(
								SchemaValueBoolean({
									label: MEDIA_RESOLUTIONS[k].label,
									default: true,
									fallbackValue: true
								}),
								MEDIA_RESOLUTIONS[k].order
							);
						}

						return opts;
					})()
				}),
				30
			)
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} as any
	});

	const isComponentAllowed = (name: string) => {
		if (opts.allowComponentNames) {
			return opts.allowComponentNames.includes(name);
		} else {
			return true;
		}
	};

	const createPropsSchema = (cmpHandler: TGenericComponentDefinition) => {
		return SchemaConstObject({
			label: "Component properties",
			constraints: {
				required: true
			},
			props: cmpHandler.props
		});
	};

	const createInheritedPropsSchema = (inheritedProps: TSchemaConstObjectProps) => {
		return SchemaConstObject({
			label: "Inherited properties",
			constraints: {
				required: true
			},
			props: inheritedProps
		});
	};

	const createModifiersSchema = (
		cmpHandler: TGenericComponentDefinition,
		propsSchema: ISchemaConstObject<TSchemaConstObjectProps>,
		inheritedPropsSchema: ISchemaConstObject<TSchemaConstObjectProps>
	) => {
		const overridenProps: {
			[K: string]: ISchemaConstObjectOptsProp<ISchemaOverride<TGenericBlueprintSchema>>;
		} = {};
		const overridenInheritedProps: {
			[K: string]: ISchemaConstObjectOptsProp<ISchemaOverride<TGenericBlueprintSchema>>;
		} = {};

		for (const k in propsSchema.opts.props) {
			const prop = propsSchema.opts.props[k];

			overridenProps[k] = Prop(
				SchemaOverride(prop.schema, {
					label: prop.schema.opts.label,
					description: prop.schema.opts.description,
					icon: prop.schema.opts.icon,
					example: prop.schema.opts.example,
					tags: prop.schema.opts.tags,
					placeholder: prop.schema.opts.placeholder
				}),
				prop.order
			);
		}

		for (const k in inheritedPropsSchema.opts.props) {
			const prop = inheritedPropsSchema.opts.props[k];

			overridenInheritedProps[k] = Prop(
				SchemaOverride(prop.schema, {
					label: prop.schema.opts.label,
					description: prop.schema.opts.description,
					example: prop.schema.opts.example,
					tags: prop.schema.opts.tags,
					icon: prop.schema.opts.icon,
					placeholder: prop.schema.opts.placeholder
				}),
				prop.order
			);
		}

		return SchemaConstArray({
			label: "Modifiers",
			description: "Modifiers allows you to overriden properties based on a condition.",
			items: SchemaConstObject({
				label: "Modifier",
				props: {
					name: Prop(
						SchemaConstString({
							label: "Modifier name",
							constraints: {
								required: true,
								min: 1
							}
						}),
						0
					),
					enabled: Prop(
						SchemaValueBoolean({
							label: "Enabled",
							description:
								"If the modifier is enabled. In many cases it's a function or variable reference.",
							constraints: {
								required: true
							}
						}),
						1
					),
					preview: Prop(
						SchemaConstBoolean({
							label: "Preview",
							// eslint-disable-next-line max-len
							description:
								"If the modifier should be active regardless the enabled property. Used in editor to preview modifier.",
							default: false
						}),
						2
					),
					props: Prop(
						SchemaConstObject({
							label: "Properties",
							description: "Properties overriden by the modifier.",
							props: overridenProps
						}),
						10
					),
					inheritedProps: Prop(
						SchemaConstObject({
							label: "Inherited properties",
							description: "Inherited properties overriden by the modifier.",
							props: overridenInheritedProps
						}),
						10
					)
				}
			})
		});
	};

	const createEventsSchema = (
		dCtx: DesignContext,
		cmpHandler: TGenericComponentDefinition
	): TSchemaComponentEvents => {
		const eventProps: TSchemaConstObjectProps = {};

		const eventNodeTypes = dCtx.getResolver<IEventResolver>("componentEvent").getEventNodeTypes();

		for (const k in cmpHandler.events) {
			const eventDef = cmpHandler.events[k];

			eventProps[k] = Prop(
				SchemaConstObject({
					label: eventDef.label || k,
					description: eventDef.description,
					icon: eventDef.icon,
					alias: "componentEvent",
					props: {
						nodes: Prop(
							SchemaScopedTemplate({
								template: SchemaFlowNodeList({
									label: "Event flow",
									nodeTypes: eventNodeTypes,
									entryNode: {
										id: "eventStart",
										type: "eventStart",
										defaultPosition: {
											x: 0,
											y: 0
										},
										defaultOpts: {}
									}
								})
							})
						)
					}
				}),
				eventDef.order
			);
		}

		return SchemaConstObject({
			label: "Events",
			description: "Event handlers",
			props: eventProps
		}) as TSchemaComponentEvents;
	};

	const bindUpdateIdIndex = (model: ISchemaComponentModel) => {
		let lastId: string = null;

		const updateIdIndex = () => {
			if (model.id.value !== lastId) {
				if (lastId && model.id.value) {
					model.ctx.__renameIdentifier(lastId, model.id.value);
				} else if (lastId) {
					model.ctx.__removeIdentifier(lastId);
				} else if (model.id.value) {
					model.ctx.__addIdentifier(model.id.value);
				}

				lastId = model.id.value;
			}
		};

		onEvent(model.id.changeEvent, updateIdIndex);
		updateIdIndex();

		model.__updateIdIndex = updateIdIndex;
	};

	const keyPropRules = {
		component: {
			idtType: BP_IDT_TYPE.SCALAR,
			idtScalarSubType: BP_IDT_SCALAR_SUBTYPE.STRING,
			required: true,
			provideCompletion: (dCtx: DesignContext, parentLoc: IDocumentLocation, minColumn: number) => {
				const componentList = dCtx
					.getResolver<IComponentResolver>("component")
					.getFilteredList(opts.allowComponentCategories);

				dCtx.__addCompletition(parentLoc.uri, parentLoc.range, minColumn, () => {
					const items: ICompletionItem[] = Object.keys(componentList).map((cmpName) => ({
						kind: CMPL_ITEM_KIND.Function,
						label: cmpName,
						insertText: cmpName
					}));

					return items;
				});
			}
		},
		id: {
			required: true,
			provideCompletion: schemaId.provideCompletion
		},
		props: {
			required: false
		},
		inheritedProps: {
			required: false
		},
		modifiers: {
			required: false
		},
		events: {
			required: false
		},
		visible: {
			required: false,
			provideCompletion: schemaVisible.provideCompletion
		},
		comments: {
			required: false,
			provideCompletion: schemaComments.provideCompletion
		},
		display: {
			required: false,
			provideCompletion: schemaDisplay.provideCompletion
		},
		readOnly: {
			required: false
		}
	};

	const assignParentToChildrenOf = (srcModel) => {
		return assignParentToModelProps(srcModel, [
			"id",
			"props",
			"inheritedProps",
			"modifiers",
			"events",
			"visible",
			"comments",
			"display",
			"readOnly"
		]);
	};

	const createModel = (
		dCtx: DesignContext,
		componentName: string,
		componentHandler: TGenericComponentDefinition,
		models: {
			id: TSchemaComponentIdModel;
			props: TSchemaComponentPropsModel;
			inheritedProps: TSchemaComponentInheritedPropsModel;
			modifiers: TSchemaComponentModifiersModel;
			events: TSchemaComponentEventsModel;
			visible: TSchemaComponentVisibleModel;
			comments: TSchemaComponentCommentsModel;
			display: TSchemaComponentDisplayModel;
			readOnly: TSchemaComponentReadOnlyModel;
		},
		parentComponentList: ISchemaComponentListModel,
		parent: TBlueprintSchemaParentNode
	) => {
		const modelNode = createModelNode(schema, dCtx, parent, [], {
			componentName: componentName,
			componentHandler: componentHandler,
			id: models.id,
			props: models.props,
			inheritedProps: models.inheritedProps,
			modifiers: models.modifiers,
			events: models.events,
			visible: models.visible,
			comments: models.comments,
			display: models.display,
			readOnly: models.readOnly,
			parentComponentList: parentComponentList,
			__updateIdIndex: null
		});

		const model = assignParentToChildrenOf(modelNode);

		// Assign original props to overrides
		for (const k in model.props.props) {
			for (let i = 0; i < model.modifiers.items.length; i++) {
				const overridenProp = model.modifiers.items[i].props.props.props[k];
				overridenProp.schema.__assignOriginalValue(overridenProp, model.props.props[k]);
			}
		}

		bindUpdateIdIndex(model);

		return model;
	};

	schema.createDefault = (dCtx, parent, defaultValue, createOpts) => {
		if (!defaultValue) {
			throw new SchemaDeclarationError(schema.name, schema.opts, "Default value must be defined.");
		}

		if (!(defaultValue instanceof Object)) {
			throw new SchemaDeclarationError(
				schema.name,
				schema.opts,
				"Expecting default value to be an object."
			);
		}

		if (!defaultValue.component) {
			throw new SchemaDeclarationError(
				schema.name,
				schema.opts,
				"Default value property 'component' must be defined."
			);
		}

		if (!defaultValue.id) {
			throw new SchemaDeclarationError(
				schema.name,
				schema.opts,
				"Defalt value property 'id' must be defined."
			);
		}

		const componentList = dCtx
			.getResolver<IComponentResolver>("component")
			.getFilteredList(opts.allowComponentCategories);
		const cmpName = defaultValue.component;

		// Check and get component handler
		const cmpHandler = componentList[cmpName];

		if (!cmpHandler) {
			throw new SchemaDeclarationError(
				schema.name,
				schema.opts,
				`Component '${cmpName}' is not defined.`
			);
		}

		if (!isComponentAllowed(cmpName)) {
			throw new SchemaDeclarationError(
				schema.name,
				schema.opts,
				`Component '${cmpName}' is not allowed to be used here.`
			);
		}

		const propsSchema = createPropsSchema(cmpHandler);

		if (cmpHandler.alterPropsSchemaHook) {
			cmpHandler.alterPropsSchemaHook(propsSchema, createOpts);
		}

		const eventSchema = createEventsSchema(dCtx, cmpHandler);
		const inhertedPropsSchema = createInheritedPropsSchema(
			createOpts?.componentListModel
				? createOpts.componentListModel.schema.getInheritedProps(createOpts.componentListModel)
				: {}
		);
		const modifiersSchema = createModifiersSchema(cmpHandler, propsSchema, inhertedPropsSchema);

		const idModel = schemaId.createDefault(dCtx, null, defaultValue.id);
		const propsModel = propsSchema.createDefault(dCtx, null, defaultValue.props);
		const inheritedPropsModel = inhertedPropsSchema.createDefault(
			dCtx,
			null,
			defaultValue.inheritedProps
		);
		const modifiersModel = modifiersSchema.createDefault(
			dCtx,
			null,
			defaultValue.modifiers
		) as TSchemaComponentModifiersModel;
		const eventsModel = eventSchema.createDefault(dCtx, null, defaultValue.events);
		const visibleModel = schemaVisible.createDefault(dCtx, null, defaultValue.visible);
		const commentsModel = schemaComments.createDefault(dCtx, null, defaultValue.comments);
		const displayModel = schemaDisplay.createDefault(dCtx, null, defaultValue.display);
		const readOnlyModel = schemaReadOnly.createDefault(dCtx, null, defaultValue.readOnly);

		if (cmpHandler.restorePropsSchemaHook) {
			cmpHandler.restorePropsSchemaHook(propsSchema);
		}

		// const inheritedProps = createOpts && createOpts.inheritedProps
		// 	? cmpHandler.assignInheritedPropsHook
		// 		? cmpHandler.assignInheritedPropsHook(propsModel, createOpts.inheritedProps, dCtx, false)
		// 		: createOpts.inheritedProps
		// 	: {}

		// Create model
		return createModel(
			dCtx,
			cmpName,
			cmpHandler,
			{
				id: idModel,
				props: propsModel,
				inheritedProps: inheritedPropsModel,
				modifiers: modifiersModel,
				events: eventsModel,
				visible: visibleModel,
				comments: commentsModel,
				display: displayModel,
				readOnly: readOnlyModel
			},
			createOpts?.componentListModel || null,
			parent
		);
	};

	schema.clone = (dCtx, modelNode, parent) => {
		const clonedModel = cloneModelNode(dCtx, modelNode, parent, {
			componentName: modelNode.componentName,
			componentHandler: modelNode.componentHandler,
			id: modelNode.id.schema.clone(dCtx, modelNode.id, null),
			props: modelNode.props.schema.clone(dCtx, modelNode.props, null),
			inheritedProps: modelNode.inheritedProps
				? modelNode.inheritedProps.schema.clone(dCtx, modelNode.inheritedProps, null)
				: null,
			modifiers: modelNode.modifiers.schema.clone(dCtx, modelNode.modifiers, null),
			events: modelNode.events.schema.clone(dCtx, modelNode.events, null),
			visible: modelNode.visible.schema.clone(dCtx, modelNode.visible, null),
			comments: modelNode.comments.schema.clone(dCtx, modelNode.comments, null),
			display: modelNode.display.schema.clone(dCtx, modelNode.display, null),
			readOnly: modelNode.readOnly.schema.clone(dCtx, modelNode.readOnly, null),
			parentComponentList: null,
			__updateIdIndex: null
		});

		const clone = assignParentToChildrenOf(clonedModel);

		bindUpdateIdIndex(clone);

		return clone;
	};

	schema.destroy = (modelNode) => {
		if (modelNode.__updateIdIndex) {
			offEvent(modelNode.id.changeEvent, modelNode.__updateIdIndex);

			if (modelNode.id.value) {
				modelNode.ctx.__removeIdentifier(modelNode.id.value);
			}
		}

		modelNode.componentName = undefined;
		modelNode.componentHandler = undefined;

		modelNode.parent = undefined;
		modelNode.parentComponentList = undefined;

		modelNode.id.schema.destroy(modelNode.id);
		modelNode.props.schema.destroy(modelNode.props);
		modelNode.inheritedProps.schema.destroy(modelNode.inheritedProps);
		modelNode.modifiers.schema.destroy(modelNode.modifiers);
		modelNode.events.schema.destroy(modelNode.events);
		modelNode.visible.schema.destroy(modelNode.visible);
		modelNode.comments.schema.destroy(modelNode.comments);
		modelNode.display.schema.destroy(modelNode.display);
		modelNode.readOnly.schema.destroy(modelNode.readOnly);
		modelNode.__updateIdIndex = undefined;

		destroyModelNode(modelNode);
	};

	schema.parse = (dCtx, idtNode, parent, createOpts) => {
		// Check node
		if (idtNode.type !== BP_IDT_TYPE.MAP) {
			if (idtNode.parseInfo) {
				dCtx.logParseError(idtNode.parseInfo.loc.uri, {
					range: idtNode.parseInfo.loc.range,
					severity: DOC_ERROR_SEVERITY.ERROR,
					name: DOC_ERROR_NAME.OBJ_NOT_MAP,
					message: "Expecting a map.",
					parsePath: idtNode.path
				});
			}

			return null;
		}

		const { keys, isValid: isKeysValid } = extractAndValidateIDTMapProperties(
			dCtx,
			idtNode,
			keyPropRules
		);

		if (!isKeysValid) {
			return null;
		}

		const componentList = dCtx
			.getResolver<IComponentResolver>("component")
			.getFilteredList(opts.allowComponentCategories);
		const cmpName = (keys["component"].value as IBlueprintIDTScalar).value as string;

		// Check and get component handler
		const cmpHandler = componentList[cmpName];

		if (!cmpHandler) {
			if (keys["component"].value.parseInfo) {
				dCtx.logParseError(keys["component"].value.parseInfo.loc.uri, {
					range: keys["component"].value.parseInfo.loc.range,
					severity: DOC_ERROR_SEVERITY.ERROR,
					name: DOC_ERROR_NAME.CMP_UNKNOWN,
					message: `Unknown component name '${cmpName}'.`,
					parsePath: keys["component"].value.path
				});
			}

			return null;
		}

		if (!isComponentAllowed(cmpName)) {
			if (keys["component"].value.parseInfo) {
				dCtx.logParseError(keys["component"].value.parseInfo.loc.uri, {
					range: keys["component"].value.parseInfo.loc.range,
					severity: DOC_ERROR_SEVERITY.ERROR,
					name: DOC_ERROR_NAME.CMP_UNKNOWN,
					message: `Component '${cmpName}' is not allowed to be used here.`,
					parsePath: keys["component"].value.path
				});
			}

			return null;
		}

		// Define schemas and create models
		const propsSchema = createPropsSchema(cmpHandler);

		if (cmpHandler.alterPropsSchemaHook) {
			cmpHandler.alterPropsSchemaHook(propsSchema, createOpts);
		}

		const inheritedPropsSchema = createInheritedPropsSchema(
			createOpts?.componentListModel
				? createOpts.componentListModel.schema.getInheritedProps(createOpts.componentListModel)
				: {}
		);

		const modifiersSchema = createModifiersSchema(cmpHandler, propsSchema, inheritedPropsSchema);
		const eventSchema = createEventsSchema(dCtx, cmpHandler);

		const idModel = schemaId.parse(dCtx, keys["id"].value, null);

		const propsModel =
			keys["props"] && keys["props"].value
				? propsSchema.parse(dCtx, keys["props"].value, null)
				: propsSchema.createDefault(dCtx, null);

		const inheritedPropsModel =
			keys["inheritedProps"] && keys["inheritedProps"].value
				? inheritedPropsSchema.parse(dCtx, keys["inheritedProps"].value, null)
				: inheritedPropsSchema.createDefault(dCtx, null);

		const modifiersModel = (
			keys["modifiers"] && keys["modifiers"].value
				? modifiersSchema.parse(dCtx, keys["modifiers"].value, null)
				: modifiersSchema.createDefault(dCtx, null)
		) as TSchemaComponentModifiersModel;

		const eventsModel =
			keys["events"] && keys["events"].value
				? eventSchema.parse(dCtx, keys["events"].value, null)
				: eventSchema.createDefault(dCtx, null);

		const visibleModel =
			keys["visible"] && keys["visible"].value
				? schemaVisible.parse(dCtx, keys["visible"].value, null)
				: schemaVisible.createDefault(dCtx, null);

		const commentsModel =
			keys["comments"] && keys["comments"].value
				? schemaComments.parse(dCtx, keys["comments"].value, null)
				: schemaComments.createDefault(dCtx, null);

		const displayModel =
			keys["display"] && keys["display"].value
				? schemaDisplay.parse(dCtx, keys["display"].value, null)
				: schemaDisplay.createDefault(dCtx, null);

		const readOnlyModel =
			keys["readOnly"] && keys["readOnly"].value
				? schemaReadOnly.parse(dCtx, keys["readOnly"].value, null)
				: schemaReadOnly.createDefault(dCtx, null);

		if (cmpHandler.restorePropsSchemaHook) {
			cmpHandler.restorePropsSchemaHook(propsSchema);
		}

		// Provide completions
		provideIDTMapPropertyCompletions(
			dCtx,
			idtNode,
			{
				props: keys.props,
				inheritedProps: keys.inheritedProps,
				modifiers: keys.modifiers,
				events: keys.events
			},
			{
				props: propsSchema.provideCompletion,
				inheritedProps: inheritedPropsSchema.provideCompletion,
				modifiers: modifiersSchema.provideCompletion,
				events: eventSchema.provideCompletion
			}
		);

		// Create model
		return createModel(
			dCtx,
			cmpName,
			cmpHandler,
			{
				id: idModel,
				props: propsModel,
				inheritedProps: inheritedPropsModel,
				modifiers: modifiersModel,
				events: eventsModel,
				visible: visibleModel,
				comments: commentsModel,
				display: displayModel,
				readOnly: readOnlyModel
			},
			createOpts?.componentListModel,
			parent
		);
	};

	schema.provideCompletion = (dCtx, parentLoc, minColumn, idtNode) => {
		provideIDTMapRootCompletions(dCtx, parentLoc, minColumn, idtNode, keyPropRules);
	};

	schema.serialize = (modelNode, path) => {
		return {
			type: BP_IDT_TYPE.MAP,
			path: path,
			items: [
				// component
				{
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat([ "[component]" ]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat([ "{component}" ]),
						value: "component"
					} as IBlueprintIDTScalar,
					value: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat([ "component" ]),
						value: modelNode.componentName
					} as IBlueprintIDTScalar
				} as IBlueprintIDTMapElement,
				// id
				{
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat([ "[id]" ]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat([ "{id}" ]),
						value: "id"
					} as IBlueprintIDTScalar,
					value: modelNode.id.schema.serialize(modelNode.id, path.concat([ "id" ]))
				} as IBlueprintIDTMapElement,
				// props
				{
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat([ "[props]" ]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat([ "{props}" ]),
						value: "props"
					} as IBlueprintIDTScalar,
					value: modelNode.props.schema.serialize(modelNode.props, path.concat([ "props" ]))
				} as IBlueprintIDTMapElement,
				// inherited props
				{
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat([ "[inheritedProps]" ]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat([ "{inheritedProps}" ]),
						value: "inheritedProps"
					} as IBlueprintIDTScalar,
					value: modelNode.inheritedProps.schema.serialize(
						modelNode.inheritedProps,
						path.concat([ "inheritedProps" ])
					)
				} as IBlueprintIDTMapElement,
				// modifiers
				{
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat([ "[modifiers]" ]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat([ "{modifiers}" ]),
						value: "modifiers"
					} as IBlueprintIDTScalar,
					value: modelNode.modifiers.schema.serialize(
						modelNode.modifiers,
						path.concat([ "modifiers" ])
					)
				} as IBlueprintIDTMapElement,
				// events
				{
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat([ "[events]" ]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat([ "{events}" ]),
						value: "events"
					} as IBlueprintIDTScalar,
					value: modelNode.events.schema.serialize(modelNode.events, path.concat([ "events" ]))
				} as IBlueprintIDTMapElement,
				// visible
				{
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat([ "[visible]" ]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat([ "{visible}" ]),
						value: "visible"
					} as IBlueprintIDTScalar,
					value: modelNode.visible.schema.serialize(modelNode.visible, path.concat([ "visible" ]))
				} as IBlueprintIDTMapElement,
				// comments
				{
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat([ "[comments]" ]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat([ "{comments}" ]),
						value: "comments"
					} as IBlueprintIDTScalar,
					value: modelNode.comments.schema.serialize(
						modelNode.comments,
						path.concat([ "comments" ])
					)
				} as IBlueprintIDTMapElement,
				// display
				{
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat([ "[display]" ]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat([ "{display}" ]),
						value: "display"
					} as IBlueprintIDTScalar,
					value: modelNode.display.schema.serialize(modelNode.display, path.concat([ "display" ]))
				} as IBlueprintIDTMapElement,
				// readOnly
				{
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat([ "[readOnly]" ]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat([ "{readOnly}" ]),
						value: "readOnly"
					} as IBlueprintIDTScalar,
					value: modelNode.readOnly.schema.serialize(
						modelNode.readOnly,
						path.concat([ "readOnly" ])
					)
				} as IBlueprintIDTMapElement
			]
		} as IBlueprintIDTMap;
	};

	schema.render = (rCtx, modelNode, _path, scope, prevSpec) => {
		modelNode.lastScopeFromRender = scope;

		const path = _path.slice();

		if (modelNode.id.isValid) {
			const last = path.pop();
			path.push(modelNode.id.value + ` (${last})`);
		}

		rCtx.__profilerStart(modelNode.componentName, path);
		rCtx.__beginLocalBoundary();

		const prevRootSpec =
			prevSpec instanceof Array && prevSpec.rootSpec
				? prevSpec.rootSpec
				: ({} as unknown as TGenericComponentInstance);

		// Check visibility
		const visible = modelNode.visible.schema.render(
			rCtx,
			modelNode.visible,
			path.concat([ "visible" ]),
			scope,
			prevSpec instanceof Array && prevSpec.rootSpec ? true : undefined
		);

		// Resolve ID
		const id = modelNode.id.schema.render(
			rCtx,
			modelNode.id,
			path.concat([ "id" ]),
			scope,
			prevRootSpec.id
		);

		// Resolve spec
		const baseProps = modelNode.props.schema.render(
			rCtx,
			modelNode.props,
			path.concat([ "props" ]),
			scope,
			prevRootSpec.__basePropsSpec
		);
		const baseInheritedProps = modelNode.inheritedProps.schema.render(
			rCtx,
			modelNode.inheritedProps,
			path.concat([ "inheritedProps" ]),
			scope,
			prevRootSpec.__baseInheritedPropsSpec
		);

		const modifiers = modelNode.modifiers.schema.render(
			rCtx,
			modelNode.modifiers,
			path.concat([ "modifiers" ]),
			scope,
			prevRootSpec.__modifiersSpec
		);

		let resolvedProps = baseProps;
		let resolvedInheritedProps = baseInheritedProps;

		for (let i = 0; i < modifiers.length; i++) {
			if (
				modifiers[i].enabled === true ||
				(rCtx.getMode() === RUNTIME_CONTEXT_MODE.EDITOR && modifiers[i].preview === true)
			) {
				resolvedProps = {
					...resolvedProps,
					...modifiers[i].props
				};

				resolvedInheritedProps = {
					...resolvedInheritedProps,
					...modifiers[i].inheritedProps
				};
			}
		}

		// Call hook to update inherited props
		if (modelNode.componentHandler.renderInheritedPropsHook) {
			resolvedInheritedProps = modelNode.componentHandler.renderInheritedPropsHook(
				resolvedInheritedProps,
				resolvedProps,
				rCtx
			);
		}

		// Resolve events
		const events = modelNode.events.schema.render(
			rCtx,
			modelNode.events,
			path.concat([ "events" ]),
			scope,
			prevRootSpec.__eventsSpec
		);
		const eventsEnabled: { [K: string]: boolean } = {};

		for (const k in modelNode.events.props) {
			// eslint-disable-next-line max-len
			const entryNode = modelNode.events.props[k].props.nodes.template.schema.getEntryNode(
				modelNode.events.props[k].props.nodes.template
			);
			eventsEnabled[k] = entryNode.outputs.props["onEvent"]?.items.length > 0;
		}

		// Resolve comments
		let comments;

		if (rCtx.getMode() === RUNTIME_CONTEXT_MODE.EDITOR) {
			comments = modelNode.comments.schema.render(
				rCtx,
				modelNode.comments,
				path.concat([ "comments" ]),
				scope,
				prevRootSpec.comments
			);
		}

		// Resolve display
		const display = modelNode.display.schema.render(
			rCtx,
			modelNode.display,
			path.concat([ "display" ]),
			scope,
			prevRootSpec.display
		);

		// Resolve read-only
		const readOnly = modelNode.readOnly.schema.render(
			rCtx,
			modelNode.readOnly,
			path.concat([ "readOnly" ]),
			scope
		);

		// Get errors
		const localBoundary = rCtx.__endLocalBoundary();

		try {
			const ret = modelNode.componentHandler.render(
				rCtx,
				{
					componentName: modelNode.componentName,
					modelNodeId: modelNode.nodeId,
					id: id,
					props: resolvedProps,
					inheritedProps: resolvedInheritedProps,
					events: events,
					eventsEnabled: eventsEnabled,
					modifiers: modifiers,
					baseProps: baseProps,
					baseInheritedProps: baseInheritedProps,
					display: display as TSchemaComponentDisplaySpec,
					comments: comments,
					modelNode: readOnly !== true ? modelNode : null,
					hasErrors: localBoundary.error > 0,
					hasWarnings: localBoundary.warning > 0,
					isLoading: localBoundary.isLoading > 0,
					isLoadingWithData: localBoundary.isLoadingWithData === localBoundary.isLoading,
					shouldBeRendered: visible
				},
				path,
				scope,
				prevSpec
			);

			rCtx.__profilerStop();

			return ret;
		} catch (err) {
			rCtx.__profilerStop();

			console.log(
				"Failed to process component because of internal exception",
				{
					componentName: modelNode?.componentName,
					modelNodeId: modelNode?.nodeId,
					componentId: modelNode?.id?.value
				},
				{
					componentName: modelNode.componentName,
					modelNodeId: modelNode.nodeId,
					id: id,
					props: resolvedProps,
					inheritedProps: resolvedInheritedProps,
					events: events,
					eventsEnabled: eventsEnabled,
					modifiers: modifiers,
					baseProps: baseProps,
					baseInheritedProps: baseInheritedProps,
					display: display as TSchemaComponentDisplaySpec,
					comments: comments,
					modelNode: readOnly !== true ? modelNode : null,
					hasErrors: localBoundary.error > 0,
					hasWarnings: localBoundary.warning > 0,
					isLoading: localBoundary.isLoading > 0,
					isLoadingWithData: localBoundary.isLoadingWithData === localBoundary.isLoading,
					shouldBeRendered: visible
				},
				err
			);

			rCtx.logRuntimeError({
				severity: DOC_ERROR_SEVERITY.ERROR,
				name: DOC_ERROR_NAME.CMP_RUNTIME_ERROR,
				message: "Failed to process component: " + String(err),
				modelPath: path,
				modelNodeId: modelNode.nodeId
			});

			return Object.assign([], { rootSpec: undefined });
		}
	};

	schema.compileRender = (cCtx, modelNode, path) => {
		// If visible is constant and false, return false
		if (
			modelNode.visible.type === SCHEMA_VALUE_TYPE.CONST &&
			modelNode.visible.constant.value === false
		) {
			return {
				isScoped: false,
				code: `Object.assign([],{rootSpec:undefined})`
			};
		}

		// Compile child props
		const id = modelNode.id.schema.compileRender(cCtx, modelNode.id, path.concat([ "id" ]));
		const props = modelNode.props.schema.compileRender(cCtx, modelNode.props, path.concat([ "props" ]));
		const inheritedProps = modelNode.inheritedProps.schema.compileRender(
			cCtx,
			modelNode.inheritedProps,
			path.concat([ "inheritedProps" ])
		);
		const modifiers = modelNode.modifiers.schema.compileRender(
			cCtx,
			modelNode.modifiers,
			path.concat([ "modifiers" ])
		);
		const visible = modelNode.visible.schema.compileRender(
			cCtx,
			modelNode.visible,
			path.concat([ "visible" ])
		);
		const display = modelNode.display.schema.compileRender(
			cCtx,
			modelNode.display,
			path.concat([ "display" ])
		);

		const events = modelNode.events.schema.compileRender(
			cCtx,
			modelNode.events,
			path.concat([ "events" ])
		);
		const eventsEnabled: { [K: string]: boolean } = {};

		for (const k in modelNode.events.props) {
			// eslint-disable-next-line max-len
			const entryNode = modelNode.events.props[k].props.nodes.template.schema.getEntryNode(
				modelNode.events.props[k].props.nodes.template
			);
			eventsEnabled[k] = entryNode.outputs.props["onEvent"]?.items.length > 0;
		}

		const statements = [
			// Resolve component handler
			`const _ch=rCtx.getResolver("component").getByName("${escapeString(modelNode.componentName)}");`,
			// eslint-disable-next-line max-len
			`if(!_ch){rCtx.logRuntimeError({severity:${inlineValue(
				DOC_ERROR_SEVERITY.ERROR
			)},name:${inlineValue(DOC_ERROR_NAME.CMP_UNKNOWN)},message:"Unknown component '"+"${escapeString(
				modelNode.componentName
			)}"+"'.",modelPath:pt,modelNodeId:${inlineValue(
				modelNode.nodeId
			)}});return Object.assign([],{rootSpec:undefined})}`,
			// Define prev spec
			`const _ps=(pv&&Array.isArray(pv)&&pv.rootSpec?pv.rootSpec:{});`,
			// Reset error counts
			`rCtx.__beginLocalBoundary();`,
			// Check visibility
			`const _vs=${applyCodeArg(
				visible,
				`pv&&Array.isArray(pv)&&pv.rootSpec?true:false`,
				`pt.concat(["visible"])`
			)};`,
			// `if(_vs===false){return Object.assign([],{rootSpec:undefined})}`,
			// Resolve props
			`const _bp=${applyCodeArg(props, `_ps.__basePropsSpec`, `pt.concat(["props"])`)};`,
			`const _ibp=${applyCodeArg(
				inheritedProps,
				`_ps.__baseInheritedPropsSpec`,
				`pt.concat(["inheritedProps"])`
			)};`,
			`let _pr=_bp;`,
			`let _ipr=_ibp;`,
			`const _m=${applyCodeArg(modifiers, `_ps.__modifiersSpec`, `pt.concat(["modifiers"])`)};`,
			`for(let i=0;i<_m.length;i++){if(_m[i].enabled===true){_pr={..._pr,..._m[i].props};_ipr={..._ipr,..._m[i].inheritedProps}}}`,
			// Call inherited props hook
			`if(_ch.renderInheritedPropsHook){_ipr=_ch.renderInheritedPropsHook(_pt,_ipr,rCtx)}`,
			// Resolve events
			`const _ev=${applyCodeArg(events, `_ps.__eventsSpec`, `pt.concat(["events"])`)};`,
			`const _eve=${inlineValue(eventsEnabled)};`,
			// Resolve display
			`const _ds=${applyCodeArg(display, `_ps.display`, `pt.concat(["display"])`)};`,
			// Get local boundary
			`const _lb=rCtx.__endLocalBoundary();`,
			// Call handler
			`try{`,
			// eslint-disable-next-line max-len
			`return _ch.render(rCtx,{componentName:"${escapeString(
				modelNode.componentName
			)}",modelNodeId:${inlineValue(modelNode.nodeId)},id:${applyCodeArg(
				id,
				`_ps.id`,
				`pt.concat(["id])`
			)},props:_pr,inheritedProps:_ipr,events:_ev,eventsEnabled:_eve,modifiers:_m,display:_ds,baseProps:_bp,baseInheritedProps:_ibp,hasErrors:_lb.errors>0,hasWarnings:_lb.warnings>0,isLoading:_lb.isLoading>0,isLoadingWithData:_lb.isLoadingWithData===_lb.isLoading,shouldBeRendered:_vs},pt,s,pv)`,
			// eslint-disable-next-line max-len
			`}catch(e){rCtx.logRuntimeError({severity:${inlineValue(
				DOC_ERROR_SEVERITY.ERROR
			)},name:${inlineValue(
				DOC_ERROR_NAME.CMP_RUNTIME_ERROR
			)},message:"Failed to process component: "+String(e),modelPath:pt,modelNodeId:${inlineValue(
				modelNode.nodeId
			)}});return Object.assign([],{rootSpec:undefined})}`
		];

		return {
			isScoped: true,
			code: `(s,pv,pt)=>{${statements.join("")}}`
		};
	};

	// Always returns false because component cannot be inlined as dynamic value
	schema.validate = (rCtx, path, modelNodeId) => {
		return validateAsNotSupported(rCtx, path, modelNodeId, "Component");
	};

	// Always returns false because component cannot be inlined as dynamic value
	schema.compileValidate = (cCtx, path, modelNodeId): string => {
		return compileValidateAsNotSupported(cCtx, path, modelNodeId, "Component");
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	schema.export = (): any => {
		return exportSchema("SchemaComponent", [ opts ]);
	};

	// Type descriptor here is irrelevant, because component exports to scope on it's own
	schema.getTypeDescriptor = () => {
		return TypeDescVoid({
			label: opts.label,
			description: opts.description,
			example: opts.example,
			tags: opts.tags
		});
	};

	schema.assignToComponentList = (
		modelNode: ISchemaComponentModel,
		listNode: ISchemaComponentListModel,
		notify?: boolean
	) => {
		// Assign component list
		modelNode.parentComponentList = listNode;

		const inheritedProps = listNode.schema.getInheritedProps(listNode);

		// Prevent re-assign of the same props
		if (modelNode.inheritedProps.schema.opts.props === inheritedProps) {
			return;
		}

		// Destroy old nodes
		modelNode.inheritedProps.schema.destroy(modelNode.inheritedProps);
		modelNode.modifiers.schema.destroy(modelNode.modifiers);

		// Determine inherited props
		const _inheritedProps = modelNode.componentHandler?.assignInheritedPropsHook
			? modelNode.componentHandler.assignInheritedPropsHook(
					modelNode.props,
					inheritedProps,
					modelNode.ctx,
					notify
			  )
			: inheritedProps;

		// Create a new one
		const inhertedPropsSchema = createInheritedPropsSchema(_inheritedProps);
		const modifiersSchema = createModifiersSchema(
			modelNode.componentHandler,
			modelNode.props.schema,
			inhertedPropsSchema
		);

		modelNode.inheritedProps = inhertedPropsSchema.parse(
			modelNode.ctx,
			modelNode.__inheritedPropsIDT,
			modelNode
		);
		modelNode.modifiers = modifiersSchema.parse(
			modelNode.ctx,
			modelNode.__modifiersIDT,
			modelNode
		) as TSchemaComponentModifiersModel;

		// Notify
		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.STRUCTURE);
		}
	};

	schema.unassignFromComponentList = (modelNode: ISchemaComponentModel, notify?: boolean) => {
		// Unassing parent component list
		modelNode.parentComponentList = null;

		// Backup current inherited props
		const inheritedPropsYAML = serializeIDTToYAML(
			modelNode.inheritedProps.schema.serialize(modelNode.inheritedProps, [ "$" ])
		);
		const modifiersYAML = serializeIDTToYAML(
			modelNode.modifiers.schema.serialize(modelNode.modifiers, [ "modifiers" ])
		);

		modelNode.__inheritedPropsIDT = parseYAMLToIDT(inheritedPropsYAML, "local://export").idt;
		modelNode.__modifiersIDT = parseYAMLToIDT(modifiersYAML, "local://export").idt;

		// Destroy old nodes
		modelNode.inheritedProps.schema.destroy(modelNode.inheritedProps);
		modelNode.modifiers.schema.destroy(modelNode.modifiers);

		// Call hook
		if (modelNode.componentHandler?.unassignInheritedPropsHook) {
			modelNode.componentHandler.unassignInheritedPropsHook(modelNode.props, modelNode.ctx, notify);
		}

		// Create a new one
		const inhertedPropsSchema = createInheritedPropsSchema({});
		const modifiersSchema = createModifiersSchema(
			modelNode.componentHandler,
			modelNode.props.schema,
			inhertedPropsSchema
		);

		modelNode.inheritedProps = inhertedPropsSchema.createDefault(modelNode.ctx, modelNode);
		modelNode.modifiers = modifiersSchema.createDefault(
			modelNode.ctx,
			modelNode
		) as TSchemaComponentModifiersModel;

		// Notify
		if (notify) {
			handleModelNodeChange(modelNode, MODEL_CHANGE_TYPE.STRUCTURE);
		}
	};

	schema.removeSelfFromComponentList = (
		modelNode: ISchemaComponentModel,
		notify?: boolean,
		destroy?: boolean
	) => {
		if (modelNode.parentComponentList) {
			modelNode.parentComponentList.schema.removeItemByModel(
				modelNode.parentComponentList,
				modelNode,
				notify
			);
		}

		if (destroy) {
			modelNode.schema.destroy(modelNode);

			if (notify) {
				emitEvent(modelNode.ctx.modelChangeEvent);
			}
		}
	};

	schema.getChildNodes = (modelNode) => {
		return [
			{
				key: "id",
				node: modelNode.id
			},
			{
				key: "props",
				node: modelNode.props
			},
			{
				key: "inheritedProps",
				node: modelNode.inheritedProps
			},
			{
				key: "modifiers",
				node: modelNode.modifiers
			},
			{
				key: "events",
				node: modelNode.events
			},
			{
				key: "visible",
				node: modelNode.visible
			},
			{
				key: "comments",
				node: modelNode.comments
			},
			{
				key: "display",
				node: modelNode.display
			},
			{
				key: "readOnly",
				node: modelNode.readOnly
			}
		];
	};

	return schema;
}

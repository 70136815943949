/**
 * Hexio App Engine Function extensions base library.
 *
 * @package hae-ext-functions-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP,
	createEmptyScope,
	declareFunction,
	IScope,
	SCHEMA_CONST_ANY_VALUE_TYPE,
	Type
} from "@hexio_io/hae-lib-blueprint";
import { stringify as stringifyQs, parse as parseQs } from "qs";

export const qsParse = declareFunction({
	name: "QUERYSTRING_PARSE",
	category: "url",
	label: "Parses Query String",
	description: "Parses first argument as query string and returns parsed parameters as an object.",
	argRequiredCount: 1,
	argSchemas: [
		BP.String({
			label: "Query String",
			constraints: {
				required: false
			},
			fallbackValue: ""
		})
	],
	argRestSchema: null,
	returnType: Type.Any({}),
	render: (_rCtx, args) => {
		const qs = args[0]();
		return qs ? parseQs(qs) : {};
	}
});

export const qsSerialize = declareFunction({
	name: "QUERYSTRING_SERIALIZE",
	category: "url",
	label: "Serialize Query String",
	description: "Returns first argument as a serialized query string.",
	argRequiredCount: 1,
	argSchemas: [
		BP.Map({
			label: "Query",
			constraints: {
				required: true
			},
			value: BP.Any({
				label: "Value",
				defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING
			}),
			fallbackValue: {}
		}),
		BP.Boolean({
			label: "Skip nulls",
			constraints: {
				required: false
			},
			fallbackValue: false
		})
	],
	argRestSchema: null,
	returnType: Type.Any({}),
	render: (_rCtx, args) => {
		const data = args[0]();
		const skipNulls = args[1]();

		return stringifyQs(data, {
			skipNulls: !!skipNulls
		});
	}
});

export const parseIntFunc = declareFunction({
	name: "PARSE_INT",
	category: "types",
	label: "Parse Integer",
	description: "Tries to parse integer from string. Returns null if value cannot be parsed.",
	argRequiredCount: 1,
	argSchemas: [
		BP.String({
			label: "Value",
			constraints: {
				required: true
			},
			fallbackValue: ""
		}),
		BP.Integer({
			label: "Radix",
			constraints: {
				required: false
			},
			default: 10,
			fallbackValue: 10
		})
	],
	argRestSchema: null,
	returnType: Type.Integer({}),
	render: (_rCtx, args) => {
		const value = args[0]() as string;
		const radix = args[1]() as number;
		const res = parseInt(value, radix);

		if (!isNaN(res)) {
			return res;
		} else {
			return null;
		}
	}
});

export const parseFloatFunc = declareFunction({
	name: "PARSE_FLOAT",
	category: "types",
	label: "Parse Float",
	description: "Tries to parse float from string. Returns null if value cannot be parsed.",
	argRequiredCount: 1,
	argSchemas: [
		BP.String({
			label: "Value",
			constraints: {
				required: true
			},
			fallbackValue: ""
		})
	],
	argRestSchema: null,
	returnType: Type.Integer({}),
	render: (_rCtx, args) => {
		const value = args[0]() as string;
		const res = parseFloat(value);

		if (!isNaN(res)) {
			return res;
		} else {
			return null;
		}
	}
});

export const scopedTemplateFunc = declareFunction({
	name: "SCOPED_TEMPLATE",
	category: "util",
	label: "Scoped Template",
	description:
		"Returns a scoped template functions that can be used in formatters and other dynamic fields.\
 Scoped template works like an anonymous function that is called later for some items that are provided in a scope.",
	argRequiredCount: 1,
	argSchemas: [
		BP.Any({
			label: "Expression",
			defaultType: SCHEMA_CONST_ANY_VALUE_TYPE.STRING,
			constraints: {
				required: true
			},
			fallbackValue: null
		})
	],
	argRestSchema: null,
	returnType: Type.Integer({}),
	render: (_rCtx, args, _restArgs, _scope) => {
		return (scope: IScope | ((parentScope: IScope) => IScope), _key?: string) => {
			let childScope = typeof scope === "function" ? scope(_scope) : scope;

			// Check for valid scope - if not, create a new one
			// This is mainly because of test framework which calls the every fucking function when comparing data
			if (!(childScope?.globalData instanceof Object)) {
				childScope = createEmptyScope();
			}

			return args[0](childScope.globalData, childScope.globalType.props);
		};
	}
});

/**
 * Field schema terms
 *
 * @package hae-ext-components-base
 * @copyright 2021 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

export const field = {
	labelText: {
		label: "schema:field.labelText#label"
	},
	labelIcon: {
		label: "schema:field.labelIcon#label"
	},
	descriptionText: {
		label: "schema:field.descriptionText#label"
	},
	hidden: {
		label: "schema:field.hidden#label"
	},
	readOnly: {
		label: "schema:field.readOnly#label"
	},
	enabled: {
		label: "schema:field.enabled#label"
	},
	required: {
		label: "schema:field.required#label"
	},
	validate: {
		label: "schema:field.validate#label"
	},
	validReadOnlyOrDisabled: {
		label: "schema:field.validReadOnlyOrDisabled#label",
		description: "schema:field.validReadOnlyOrDisabled#description"
	},
	minLength: {
		label: "schema:field.minLength#label"
	},
	maxLength: {
		label: "schema:field.maxLength#label"
	},
	customValidation: {
		label: "schema:field.customValidation#label"
	},
	customValidationCondition: {
		label: "schema:field.customValidation#props.condition#label"
	},
	customValidationMessage: {
		label: "schema:field.customValidation#props.message#label"
	},
	htmlAutocomplete: {
		label: "schema:field.htmlAutocomplete#label",
		description: "schema:field.htmlAutocomplete#description"
	},
	pattern: {
		label: "schema:field.pattern#label",
		description: "schema:field.pattern#description"
	},
	placeholder: {
		label: "schema:field.placeholder#label",
		description: "schema:field.placeholder#description"
	},
	prefix: {
		label: "schema:field.prefix#label"
	},
	prefixValue: {
		label: "schema:field.prefix#props.value#label"
	},
	prefixWidth: {
		label: "schema:field.prefix#props.width#label"
	},
	/*prefixIncludeInValue: {
		label: "schema:field.prefix#props.includeInValue#label",
	},*/
	suffix: {
		label: "schema:field.suffix#label"
	},
	suffixValue: {
		label: "schema:field.suffix#props.value#label"
	},
	suffixWidth: {
		label: "schema:field.suffix#props.width#label"
	},
	/*suffixIncludeInValue: {
		label: "schema:field.suffix#props.includeInValue#label",
	}*/
	valid: {
		label: "schema:field.valid#label"
	},
	selection: {
		label: "schema:field.selection#label"
	},
	selectionCaret: {
		label: "schema:field.selection#props.caret#label"
	},
	selectionStart: {
		label: "schema:field.selection#props.start#label"
	},
	selectionEnd: {
		label: "schema:field.selection#props.end#label"
	},
	selectionDirection: {
		label: "schema:field.selection#props.direction#label"
	},
	setValue: {
		label: "schema:field.setValue#label"
	},
	clearValue: {
		label: "schema:field.clearValue#label"
	},
	events: {
		change: {
			label: "schema:field.events.change#label"
		},
		customValueChange: {
			label: "schema:field.events.customValueChange#label"
		}
	}
};

export const checkboxField = {
	value: {
		label: "schema:checkboxField.value#label",
		description: "schema:checkboxField.value#description"
	},
	styleData: {
		label: "schema:checkboxField.styleData#label"
	},
	styleDataValues: {
		default: {
			label: "schema:checkboxField.styleData#values.default#label"
		},
		switch: {
			label: "schema:checkboxField.styleData#values.switch#label"
		}
	},
	truthyState: {
		label: "schema:checkboxField.truthyState#label"
	},
	falsyState: {
		label: "schema:checkboxField.falsyState#label"
	},
	width: {
		label: "schema:checkboxField.width#label",
		description: "schema:checkboxField.width#description"
	},
	height: {
		label: "schema:checkboxField.height#label",
		description: "schema:checkboxField.height#description"
	},
	initialValue: {
		label: "schema:checkboxField.initialValue#label",
		description: "schema:checkboxField.initialValue#description"
	}
};

export const colorField = {
	value: {
		label: "schema:colorField.value#label",
		description: "schema:colorField.value#description"
	},
	presets: {
		label: "schema:colorField.presets#label",
		description: "schema:colorField.presets#description"
	},
	presetValue: {
		label: "schema:colorField.presetValue#label",
		description: "schema:colorField.presetValue#description"
	},
	presetDescription: {
		label: "schema:colorField.presetDescription#label",
		description: "schema:colorField.presetDescription#description"
	},
	allowCustomValue: {
		label: "schema:colorField.allowCustomValue#label",
		description: "schema:colorField.allowCustomValue#description"
	},
	initialValue: {
		label: "schema:colorField.initialValue#label",
		description: "schema:colorField.initialValue#description"
	}
};

export const datetimeField = {
	value: {
		label: "schema:datetimeField.value#label",
		description: "schema:datetimeField.value#description"
	},
	type: {
		label: "schema:datetimeField.type#label",
		description: "schema:datetimeField.type#description"
	},
	typeValues: {
		datetime: {
			label: "schema:datetimeField.type#values.datetime#label"
		},
		datetime_full: {
			label: "schema:datetimeField.type#values.datetimeFull#label"
		},
		date: {
			label: "schema:datetimeField.type#values.date#label"
		},
		time: {
			label: "schema:datetimeField.type#values.time#label"
		},
		time_full: {
			label: "schema:datetimeField.type#values.timeFull#label"
		}
	},
	min: {
		label: "schema:datetimeField.min#label",
		description: "schema:datetimeField.min#description"
	},
	max: {
		label: "schema:datetimeField.max#label",
		description: "schema:datetimeField.max#description"
	},
	initialValue: {
		label: "schema:datetimeField.initialValue#label",
		description: "schema:datetimeField.initialValue#description"
	}
};

export const fileField = {
	url: {
		label: "schema:fileField.url#label",
		description: "schema:fileField.url#description"
	},
	urlValues: {
		static: {
			label: "schema:fileField.url#values.static#label"
		},
		action: {
			label: "schema:fileField.url#values.action#label"
		}
	},
	formDataKey: {
		label: "schema:fileField.formDataKey#label",
		description: "schema:fileField.formDataKey#description"
	},
	action: {
		label: "schema:fileField.action#label",
		description: "schema:fileField.action#description"
	},
	uploadImmediately: {
		label: "schema:fileField.uploadImmediately#label",
		description: "schema:fileField.uploadImmediately#description"
	},
	type: {
		label: "schema:fileField.type#label",
		description: "schema:fileField.type#description"
	},
	typeValues: {
		simple: {
			label: "schema:fileField.type#values.simple#label"
		},
		list: {
			label: "schema:fileField.type#values.list#label"
		},
		gallery: {
			label: "schema:fileField.type#values.gallery#label"
		}
	},
	fileNames: {
		label: "schema:fileField.fileNames#label",
		description: "schema:fileField.fileNames#description"
	},
	accept: {
		label: "schema:fileField.accept#label",
		description: "schema:fileField.accept#description"
	},
	multiple: {
		label: "schema:fileField.multiple#label",
		description: "schema:fileField.multiple#description"
	},
	multipleMax: {
		label: "schema:fileField.multiple#props.max#label",
		description: "schema:fileField.multiple#props.max#description"
	},
	maxFileSize: {
		label: "schema:fileField.maxFileSize#label",
		description: "schema:fileField.maxFileSize#description"
	},
	customPlaceholder: {
		label: "schema:fileField.customPlaceholder#label",
		description: "schema:fileField.customPlaceholder#description"
	},
	customPlaceholderTouch: {
		label: "schema:fileField.customPlaceholderTouch#label",
		description: "schema:fileField.customPlaceholderTouch#description"
	},
	customMaxFilesExceededMessage: {
		label: "schema:fileField.customMaxFilesExceededMessage#label",
		description: "schema:fileField.customMaxFilesExceededMessage#description"
	},
	requestTimeout: {
		label: "schema:fileField.requestTimeout#label",
		description: "schema:fileField.requestTimeout#description"
	},
	files: {
		label: "schema:fileField.files#label",
		description: "schema:fileField.files#description"
	},
	uploadAll: {
		label: "schema:fileField.uploadAll#label",
		description: "schema:fileField.uploadAll#description"
	},
	upload: {
		label: "schema:fileField.upload#label",
		description: "schema:fileField.upload#description"
	},
	removeAll: {
		label: "schema:fileField.removeAll#label",
		description: "schema:fileField.removeAll#description"
	},
	remove: {
		label: "schema:fileField.remove#label",
		description: "schema:fileField.remove#description"
	},
	cancel: {
		label: "schema:fileField.cancel#label",
		description: "schema:fileField.cancel#description"
	},
	events: {
		add: {
			label: "schema:fileField.events.add#label"
		},
		remove: {
			label: "schema:fileField.events.remove#label"
		},
		delete: {
			label: "schema:fileField.events.delete#label"
		},
		upload: {
			label: "schema:fileField.events.upload#label"
		},
		uploadError: {
			label: "schema:fileField.events.uploadError#label"
		}
	}
};

export const numberField = {
	value: {
		label: "schema:numberField.value#label",
		description: "schema:numberField.value#description"
	},
	typeData: {
		label: "schema:numberField.typeData#label",
		description: "schema:numberField.typeData#description"
	},
	typeDataValues: {
		default: {
			label: "schema:numberField.typeData#values.default#label"
		},
		slider: {
			label: "schema:numberField.typeData#values.slider#label"
		}
	},
	mode: {
		label: "schema:numberField.mode#label",
		description: "schema:numberField.mode#description"
	},
	modeValues: {
		default: {
			label: "schema:numberField.mode#values.default#label"
		},
		from_start: {
			label: "schema:numberField.mode#values.fromStart#label"
		},
		from_end: {
			label: "schema:numberField.mode#values.fromEnd#label"
		}
	},
	min: {
		label: "schema:numberField.min#label",
		description: "schema:numberField.min#description"
	},
	max: {
		label: "schema:numberField.max#label",
		description: "schema:numberField.max#description"
	},
	step: {
		label: "schema:numberField.step#label",
		description: "schema:numberField.step#description"
	},
	initialValue: {
		label: "schema:numberField.initialValue#label",
		description: "schema:numberField.initialValue#description"
	}
};

export const rangeField = {
	value: {
		label: "schema:rangeField.value#label",
		description: "schema:rangeField.value#description"
	},
	valueFrom: {
		label: "schema:rangeField.valueFrom#label"
	},
	valueTo: {
		label: "schema:rangeField.valueTo#label"
	},
	type: {
		label: "schema:rangeField.type#label",
		description: "schema:rangeField.type#description"
	},
	min: {
		label: "schema:rangeField.min#label",
		description: "schema:rangeField.min#description"
	},
	max: {
		label: "schema:rangeField.max#label",
		description: "schema:rangeField.max#description"
	},
	step: {
		label: "schema:rangeField.step#label",
		description: "schema:rangeField.step#description"
	},
	initialValue: {
		label: "schema:rangeField.initialValue#label",
		description: "schema:rangeField.initialValue#description"
	}
};

export const textField = {
	value: {
		label: "schema:textField.value#label",
		description: "schema:textField.value#description"
	},
	type: {
		label: "schema:textField.type#label"
	},
	typeValues: {
		default: {
			label: "schema:textField.type#values.default#label"
		},
		email: {
			label: "schema:textField.type#values.email#label"
		},
		password: {
			label: "schema:textField.type#values.password#label"
		}
	},
	passwordPreview: {
		label: "schema:textField.passwordPreview#label"
	},
	autocomplete: {
		label: "schema:textField.autocomplete#label"
	},
	autocompleteItems: {
		label: "schema:textField.autocomplete#props.items#label"
	},
	autocompleteMinValueLength: {
		label: "schema:textField.autocomplete#props.minValueLength#label",
		description: "schema:textField.autocomplete#props.minValueLength#description"
	},
	initialValue: {
		label: "schema:textField.initialValue#label",
		description: "schema:textField.initialValue#description"
	}
};

export const textAreaField = {
	value: {
		label: "schema:textAreaField.value#label",
		description: "schema:textAreaField.value#description"
	},
	initialValue: {
		label: "schema:textAreaField.initialValue#label",
		description: "schema:textAreaField.initialValue#description"
	}
};

export const optionsField = {
	value: {
		label: "schema:optionsField.value#label",
		description: "schema:optionsField.value#description"
	},
	items: {
		label: "schema:optionsField.items#label",
		description: "schema:optionsField.items#description"
	},
	type: {
		label: "schema:optionsField.type#label"
	},
	typeValues: {
		default: {
			label: "schema:optionsField.type#values.default#label"
		},
		radio_group: {
			label: "schema:optionsField.type#values.radioGroup#label"
		}
	},
	placeholder: {
		label: "schema:optionsField.placeholder#label",
		description: "schema:optionsField.placeholder#description"
	},
	allowCustomValue: {
		label: "schema:optionsField.allowCustomValue#label",
		description: "schema:optionsField.allowCustomValue#description"
	},
	allowCustomValueType: {
		label: "schema:optionsField.allowCustomValueType#label"
	},
	allowCustomValueTypeValues: {
		custom_value: {
			label: "schema:optionsField.allowCustomValueType#values.customValue#label"
		},
		search: {
			label: "schema:optionsField.allowCustomValueType#values.search#label"
		},
		both: {
			label: "schema:optionsField.allowCustomValueType#values.both#label"
		}
	},
	allowCustomValuePlaceholder: {
		label: "schema:optionsField.allowCustomValuePlaceholder#label",
		description: "schema:optionsField.allowCustomValuePlaceholder#description"
	},
	allowCustomValueSearchClient: {
		label: "schema:optionsField.allowCustomValueSearchClient#label",
		description: "schema:optionsField.allowCustomValueSearchClient#description"
	},
	initialValue: {
		label: "schema:optionsField.initialValue#label",
		description: "schema:optionsField.initialValue#description"
	},
	customValue: {
		label: "schema:optionsField.customValue#label",
		description: "schema:optionsField.customValue#description"
	}
};

export const optionsFieldItem = {
	value: {
		label: "schema:optionsFieldItem.value#label",
		description: "schema:optionsFieldItem.value#description"
	},
	labelText: {
		label: "schema:optionsFieldItem.labelText#label",
		description: "schema:optionsFieldItem.labelText#description"
	},
	labelIcon: {
		label: "schema:optionsFieldItem.labelIcon#label",
		description: "schema:optionsFieldItem.labelIcon#description"
	},
	descriptionText: {
		label: "schema:optionsFieldItem.descriptionText#label",
		description: "schema:optionsFieldItem.descriptionText#description"
	}
};

/**
 * hae-lib-blueprint
 *
 * Hexio App Engine library for processing blueprints.
 *
 * @package hae-lib-blueprint
 * @copyright 2020 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import {
	BP_IDT_SCALAR_SUBTYPE,
	BP_IDT_TYPE,
	IBlueprintIDTMap,
	IBlueprintIDTMapElement,
	IBlueprintIDTScalar,
	TBlueprintIDTNode,
	TBlueprintIDTNodePath
} from "../IDT/ISchemaIDT";
import {
	IBlueprintSchema,
	IBlueprintSchemaOpts,
	TBlueprintSchemaParentNode,
	TGenericBlueprintSchema,
	TGetBlueprintSchemaDefault,
	TGetBlueprintSchemaModel,
	TGetBlueprintSchemaSpec
} from "../Schema/IBlueprintSchema";
import { IModelNode } from "../Schema/IModelNode";
import {
	applyRuntimeValidators,
	assignParentToObjAttributes,
	cloneModelNode,
	compileRuntimeValidators,
	createEmptySchema,
	createModelNode,
	destroyModelNode,
	validateDefaultValue
} from "../Schema/SchemaHelpers";
import { applyCodeArg, escapeString } from "../Context/CompileUtil";
import { DOC_ERROR_NAME, DOC_ERROR_SEVERITY } from "../Shared/IDocumentError";
import { DesignContext } from "../Context/DesignContext";
import { ValidatorObject } from "../validators/ValidatorObject";
import { SchemaDeclarationError } from "../Schema/SchemaDeclarationError";
import { exportSchema } from "../ExportImportSchema/ExportSchema";
import { TypeDescObject, TypeDescString } from "../Shared/ITypeDescriptor";
import {
	extractAndValidateIDTMapProperties,
	IExtractPropRules,
	provideIDTMapRootCompletions,
	validateIDTNode
} from "../Context/ParseUtil";
import { ISchemaConstEnum } from "./const/SchemaConstEnum";
import { ISchemaConstString } from "./const/SchemaConstString";
import { ISchemaValueEnumStringOpts, SchemaValueEnumString } from "./value/SchemaValueEnum";
import { ISchemaValueDefault, ISchemaValueModel, SCHEMA_VALUE_TYPE } from "./value/SchemaValue";
import { IBlueprintSchemaValidationError } from "../Validator/IBlueprintSchemaValidator";
import { IDocumentLocation } from "../Shared/IDocumentLocation";

/**
 * OneOf type options
 */
export interface ISchemaOneOfTypeOpts<
	TValueSchema extends TGenericBlueprintSchema = TGenericBlueprintSchema
> {
	/** Type label */
	label: string;

	/** Type description */
	description?: string;

	/** Type icon */
	icon?: string;

	/** Property schema */
	value: TValueSchema;

	/** Order in editor UI */
	order?: number;
}

/**
 * List of OneOf types
 */
export type TSchemaOneOfTypes = { [K: string]: ISchemaOneOfTypeOpts };

/**
 * Helper type which resolves to map of SchemaOneOf types and their corresponding schemas
 */
export type TSchemaOneOfTypesSchema<T extends TSchemaOneOfTypes> = { [K in keyof T]: T[K]["value"] };

/**
 * Helper type which resolves to map of SchemaOneOf types and their corresponding TModelNode
 */
export type TSchemaOneOfTypesModel<T extends TSchemaOneOfTypes> = {
	[K in keyof T]: TGetBlueprintSchemaModel<T[K]["value"]>;
};

/**
 * Helper type which resolves to map of SchemaOneOf types and their corresponding TSpec
 */
export type TSchemaOneOfTypesSpec<T extends TSchemaOneOfTypes> = {
	[K in keyof T]: TGetBlueprintSchemaSpec<T[K]["value"]>;
};

/**
 * Helper type which resolves to map of SchemaOneOf types and their corresponding TDefault
 */
export type TSchemaOneOfTypesDefault<T extends TSchemaOneOfTypes> = {
	[K in keyof T]: TGetBlueprintSchemaDefault<T[K]["value"]>;
};

/**
 * Schema model
 */
export interface ISchemaOneOfModel<TTypes extends TSchemaOneOfTypes>
	extends IModelNode<ISchemaOneOf<TTypes>> {
	type: ISchemaValueModel<ISchemaConstEnum<ISchemaConstString>>;
	value: Partial<TSchemaOneOfTypesModel<TTypes>>;
}

/**
 * Schema options
 */
export interface ISchemaOneOfOpts<TTypes extends TSchemaOneOfTypes> extends IBlueprintSchemaOpts {
	/** Object properties */
	types: TTypes;
	/** Default type for a new node */
	defaultType: keyof TTypes;
	/** Base validation constraints */
	constraints?: {
		/** If the value and type is required */
		required: boolean;
	};
	/** Custom options for type selector */
	typeValueOpts?: Partial<ISchemaValueEnumStringOpts>;
}

/**
 * Schema spec
 */
export interface ISchemaOneOfSpec<TTypes extends TSchemaOneOfTypes> {
	type: keyof TTypes;
	value: Partial<TSchemaOneOfTypesSpec<TTypes>>;
}

/**
 * Schema default
 */
export interface ISchemaOneOfDefault<TTypes extends TSchemaOneOfTypes> {
	type: keyof TTypes | ISchemaValueDefault<ISchemaConstEnum<ISchemaConstString>>;
	value: Partial<TSchemaOneOfTypesDefault<TTypes>>;
}

/**
 * Schema type
 */
export interface ISchemaOneOf<TTypes extends TSchemaOneOfTypes>
	extends IBlueprintSchema<
		ISchemaOneOfOpts<TTypes>,
		ISchemaOneOfModel<TTypes>,
		ISchemaOneOfSpec<TTypes>,
		ISchemaOneOfDefault<TTypes>
	> {}

/**
 * Schema: String scalar value
 *
 * @param opts Schema options
 */
export function SchemaOneOf<TTypes extends TSchemaOneOfTypes>(
	opts: ISchemaOneOfOpts<TTypes>
): ISchemaOneOf<TTypes> {
	type TTypeModel = ISchemaValueModel<ISchemaConstEnum<ISchemaConstString>>;
	type TValueModel = TSchemaOneOfTypesModel<TTypes>;
	type TValueItemModel = TGetBlueprintSchemaModel<TTypes[Extract<keyof TTypes, string>]["value"]>;

	// Pre-process types
	let _tmpOrder = 0;

	for (const k in opts.types) {
		if (opts.types[k].order === undefined) {
			opts.types[k].order = _tmpOrder;
		}

		_tmpOrder++;
	}

	const sortedTypes = Object.keys(opts.types)
		.map((typeName) => ({
			label: opts.types[typeName].label,
			description: opts.types[typeName].description,
			icon: opts.types[typeName].icon,
			order: opts.types[typeName].order,
			value: typeName
		}))
		.sort((a, b) => a.order - b.order);

	const typeSchema = SchemaValueEnumString({
		...opts.typeValueOpts,
		options: sortedTypes,
		default: opts.defaultType as string,
		fallbackValue: opts.defaultType as string,
		constraints: {
			required: true
		}
	});

	const rootValidator = ValidatorObject({ required: opts.constraints?.required });
	const valueValidator = ValidatorObject({ required: true });

	const valuePropRules = {} as IExtractPropRules;

	for (const k in opts.types) {
		valuePropRules[k] = {
			required: false,
			provideCompletion: opts.types[k].value.provideCompletion
		};
	}

	const rootPropRules = {
		type: {
			required: true,
			provideCompletion: typeSchema.provideCompletion
		},
		value: {
			required: true,
			idtType: BP_IDT_TYPE.MAP,
			provideCompletion: (
				dCtx: DesignContext,
				parentLoc: IDocumentLocation,
				minColumn: number,
				idtNode: TBlueprintIDTNode
			) => {
				provideIDTMapRootCompletions(dCtx, parentLoc, minColumn, idtNode, valuePropRules);
			}
		}
	};

	const schema = createEmptySchema<ISchemaOneOf<TTypes>>("oneOf", opts);

	const assignParentToChildrenOf = (srcModel) => {
		const { type, value } = srcModel;
		type.schema.assignParent(type, srcModel, false);
		return assignParentToObjAttributes(srcModel, value);
	};

	const createModel = (
		dCtx: DesignContext,
		typeModel: TTypeModel,
		valueModel: TValueModel,
		validationErrors: IBlueprintSchemaValidationError[],
		parent: TBlueprintSchemaParentNode
	) => {
		const model = createModelNode(schema, dCtx, parent, validationErrors, {
			type: typeModel,
			value: valueModel
		});

		return assignParentToChildrenOf(model);
	};

	schema.createDefault = (dCtx, parent, defaultValue) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const errors = validateDefaultValue(schema, [ rootValidator as any ], defaultValue);

		if (defaultValue) {
			if (!(defaultValue instanceof Object)) {
				throw new SchemaDeclarationError(
					schema.name,
					schema.opts,
					"Expecting default value to be an object."
				);
			}

			if (!defaultValue.type) {
				throw new SchemaDeclarationError(
					schema.name,
					schema.opts,
					"Expecting default value to have a `type` property."
				);
			}

			if (!defaultValue.value || !(defaultValue.value instanceof Object)) {
				// eslint-disable-next-line max-len
				throw new SchemaDeclarationError(
					schema.name,
					schema.opts,
					"Expecting default value to have a `value` property of type object."
				);
			}

			const typeModel = typeSchema.createDefault(dCtx, null, defaultValue.type as string);
			const valueModel = {} as TValueModel;

			for (const k in opts.types) {
				valueModel[k] = opts.types[k].value.createDefault(
					dCtx,
					null,
					defaultValue.value[k]
				) as TValueItemModel;
			}

			return createModel(dCtx, typeModel, valueModel, errors, parent);
		} else {
			const typeName = opts.defaultType;

			const typeModel = typeSchema.createDefault(dCtx, null, typeName as string);
			const valueModel = {} as TValueModel;

			for (const k in opts.types) {
				valueModel[k] = opts.types[k].value.createDefault(dCtx, null) as TValueItemModel;
			}

			return createModel(dCtx, typeModel, valueModel, errors, parent);
		}
	};

	schema.clone = (dCtx, modelNode, parent) => {
		const clonedType = modelNode.type.schema.clone(dCtx, modelNode.type, null);

		const clonedValue: TValueModel = {} as TValueModel;

		for (const k in modelNode.value) {
			clonedValue[k] = modelNode.value[k].schema.clone(
				dCtx,
				modelNode.value[k],
				null
			) as TValueItemModel;
		}

		const clone = cloneModelNode(dCtx, modelNode, parent, {
			type: clonedType,
			value: clonedValue
		});

		return assignParentToChildrenOf(clone);
	};

	schema.destroy = (modelNode) => {
		for (const k in modelNode.value) {
			modelNode.value[k].schema.destroy(modelNode.value[k]);
		}

		modelNode.type.schema.destroy(modelNode.type);

		destroyModelNode(modelNode);
	};

	schema.parse = (dCtx, idtNode, parent) => {
		// Check root node type
		const { node: rootNode, isValid: isRootNodeValid } = validateIDTNode(dCtx, idtNode, {
			required: opts.constraints?.required || false,
			idtType: BP_IDT_TYPE.MAP
		});

		if (!isRootNodeValid || !rootNode) {
			return schema.createDefault(dCtx, parent);
		}

		// Extract keys
		const { keys, isValid: isRootKeysValid } = extractAndValidateIDTMapProperties(
			dCtx,
			rootNode,
			rootPropRules
		);

		if (!isRootKeysValid) {
			return schema.createDefault(dCtx, parent);
		}

		// Extract value keys
		const { keys: values } = extractAndValidateIDTMapProperties(
			dCtx,
			keys.value.value as IBlueprintIDTMap,
			valuePropRules
		);

		// Create models
		const typeModel = typeSchema.parse(dCtx, keys.type.value, null);
		const valueModel = {} as TValueModel;

		for (const k in opts.types) {
			valueModel[k] = values[k]
				? (opts.types[k].value.parse(dCtx, values[k].value, null) as TValueItemModel)
				: (opts.types[k].value.createDefault(dCtx, null) as TValueItemModel);
		}

		// Construct model
		return createModel(dCtx, typeModel, valueModel, [], parent);
	};

	schema.provideCompletion = (dCtx, parentLoc, minColumn, idtNode) => {
		provideIDTMapRootCompletions(dCtx, parentLoc, minColumn, idtNode, rootPropRules);
	};

	schema.serialize = (modelNode, path: TBlueprintIDTNodePath) => {
		const serializedItems: IBlueprintIDTMapElement[] = [];

		if (modelNode.type.type === SCHEMA_VALUE_TYPE.CONST) {
			const _key = modelNode.type.constant.value;

			serializedItems.push({
				type: BP_IDT_TYPE.MAP_ELEMENT,
				path: path.concat([ "[value]", `[${_key}]` ]),
				key: {
					type: BP_IDT_TYPE.SCALAR,
					subType: BP_IDT_SCALAR_SUBTYPE.STRING,
					path: path.concat([ "{value}", `{${_key}}` ]),
					value: _key
				},
				value: modelNode.value[_key].schema.serialize(
					modelNode.value[_key],
					path.concat([ "value", _key ])
				)
			});
		} else {
			for (const k in modelNode.value) {
				serializedItems.push({
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat([ "[value]", `[${k}]` ]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat([ "{value}", `{${k}}` ]),
						value: k
					},
					value: modelNode.value[k].schema.serialize(
						modelNode.value[k],
						path.concat([ "value", k ])
					)
				});
			}
		}

		return {
			type: BP_IDT_TYPE.MAP,
			path: path,
			items: [
				// type
				{
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat([ "[type]" ]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat([ "{type}" ]),
						value: "type"
					} as IBlueprintIDTScalar,
					value: modelNode.type.schema.serialize(modelNode.type, path.concat([ "type" ]))
				} as IBlueprintIDTMapElement,
				// value
				{
					type: BP_IDT_TYPE.MAP_ELEMENT,
					path: path.concat([ "[value]" ]),
					key: {
						type: BP_IDT_TYPE.SCALAR,
						subType: BP_IDT_SCALAR_SUBTYPE.STRING,
						path: path.concat([ "{value}" ]),
						value: "value"
					} as IBlueprintIDTScalar,
					value: {
						type: BP_IDT_TYPE.MAP,
						path: path.concat([ "value" ]),
						items: serializedItems
					}
				} as IBlueprintIDTMapElement
			]
		} as IBlueprintIDTMap;
	};

	schema.render = (rCtx, modelNode, path, scope, prevSpec) => {
		modelNode.lastScopeFromRender = scope;

		const typeName = modelNode.type.schema.render(
			rCtx,
			modelNode.type,
			path.concat([ "type" ]),
			scope,
			prevSpec?.type as string
		);
		const valueModel = modelNode.value[typeName];

		return {
			type: typeName,
			value: {
				[typeName]: valueModel.schema.render(
					rCtx,
					valueModel,
					path.concat([ "value", typeName as string ]),
					scope,
					prevSpec?.value?.[typeName]
				)
			}
		} as ISchemaOneOfSpec<TTypes>;
	};

	schema.compileRender = (cCtx, modelNode, path) => {
		// Constant optimization - we can return selected value directly
		if (modelNode.type.type === SCHEMA_VALUE_TYPE.CONST) {
			const typeName = modelNode.type.constant.value;

			if (!modelNode.value[typeName]) {
				cCtx.logCompileError({
					name: DOC_ERROR_NAME.INVALID_VALUE,
					severity: DOC_ERROR_SEVERITY.ERROR,
					message: `Invalid oneOf type '${typeName}'.`,
					modelNodeId: modelNode.nodeId,
					modelPath: path
				});

				return {
					isScoped: false,
					code: `null`
				};
			}

			const valueCode = modelNode.value[typeName].schema.compileRender(
				cCtx,
				modelNode.value[typeName],
				path.concat([ "value", typeName ])
			);

			return {
				isScoped: true,
				// eslint-disable-next-line max-len
				code: `(s,pv,pt)=>({type:"${escapeString(typeName)}",value:{"${escapeString(
					typeName
				)}":${applyCodeArg(
					valueCode,
					`typeof pv==="object"&&pv!==null&&pv.value&&typeof pv.value==="object"&&pv.value!==null?pv.value["${escapeString(
						typeName
					)}"]:undefined`,
					`pt.concat(["value","${escapeString(typeName)}"])`
				)}}})`
			};
		} else {
			const typeCmp = modelNode.type.schema.compileRender(
				cCtx,
				modelNode.type,
				path.concat([ "type" ])
			);

			const statements = [
				`const _t=${applyCodeArg(
					typeCmp,
					`typeof pv==="object"&&pv!==null?pv.type:undefined`,
					`pt.concat(["type"])`
				)};`,
				`let _v;`
			];

			Object.keys(modelNode.value).forEach((k) => {
				const valueCmp = modelNode.value[k].schema.compileRender(
					cCtx,
					modelNode.value[k],
					path.concat([ "value", k ])
				);

				// eslint-disable-next-line max-len
				statements.push(
					`if(_t==="${escapeString(k)}"){_v=${applyCodeArg(
						valueCmp,
						`typeof pv==="object"&&pv!==null&&pv.value&&typeof pv.value==="object"&&pv.value!==null?pv.value["${escapeString(
							k
						)}"]:undefined`,
						`pt.concat(["value","${escapeString(k)}"])`
					)}}`
				);
			});

			statements.push(`return {type:_t,value:{[_t]:_v}}`);

			return {
				isScoped: true,
				// eslint-disable-next-line max-len
				code: `(s,pv,pt)=>{${statements.join("")}}`
			};
		}
	};

	schema.validate = (rCtx, path, modelNodeId, value, validateChildren) => {
		let isValid = true;

		// Validate self as object
		isValid &&= applyRuntimeValidators<{ [K: string]: unknown }>(
			rCtx,
			path,
			modelNodeId,
			[ rootValidator ],
			DOC_ERROR_SEVERITY.ERROR,
			value as unknown as { [K: string]: unknown }
		);

		if (validateChildren && value instanceof Object) {
			isValid &&= typeSchema.validate(
				rCtx,
				path.concat([ "type" ]),
				modelNodeId,
				value.type as string,
				true
			);

			isValid &&= applyRuntimeValidators<{ [K: string]: unknown }>(
				rCtx,
				path,
				modelNodeId,
				[ valueValidator ],
				DOC_ERROR_SEVERITY.ERROR,
				value.value as unknown as { [K: string]: unknown }
			);

			if (value.value instanceof Object) {
				for (const k in opts.types) {
					if (value.type === k) {
						isValid &&= opts.types[k].value.validate(
							rCtx,
							path.concat([ "value", k ]),
							modelNodeId,
							value.value[k],
							true
						);
					}
				}
			}
		}

		return isValid;
	};

	schema.compileValidate = (cCtx, path, modelNodeId, validateChildren): string => {
		const expressions = [];

		const rootValidatorCmp = compileRuntimeValidators(
			cCtx,
			path,
			modelNodeId,
			[ rootValidator ],
			DOC_ERROR_SEVERITY.ERROR
		);

		if (rootValidatorCmp !== null) {
			expressions.push(`_vd=(${rootValidatorCmp})(v,pt)&&_vd;`);
		}

		if (validateChildren) {
			expressions.push(`if(typeof v==="object"&&v!==null){`);

			const typeValidatorCmp = typeSchema.compileValidate(
				cCtx,
				path.concat([ "type" ]),
				modelNodeId,
				true
			);

			const valueValidatorCmp = compileRuntimeValidators(
				cCtx,
				path.concat([ "value" ]),
				modelNodeId,
				[ valueValidator ],
				DOC_ERROR_SEVERITY.ERROR
			);

			if (typeValidatorCmp !== null) {
				expressions.push(`_vd=(${typeValidatorCmp})(v.type,pt.concat(["type"]))&&_vd;`);
			}

			if (valueValidatorCmp !== null) {
				expressions.push(`_vd=(${valueValidatorCmp})(v.value,pt.concat(["value"]))&&_vd;`);
			}

			expressions.push(`if(typeof v.value==="object"&&v.value!==null){`);

			for (const k in opts.types) {
				const valueValidator = opts.types[k].value.compileValidate(
					cCtx,
					path.concat([ "value", k ]),
					modelNodeId,
					validateChildren
				);
				const _k = escapeString(k);

				if (valueValidator !== null) {
					// eslint-disable-next-line max-len
					expressions.push(
						`if(v.type==="${_k}"){_vd=(${valueValidator})(v.value["${_k}"],pt.concat(["value","${_k}"]))&&_vd}`
					);
				}
			}

			expressions.push(`}}`);
		}

		if (expressions.length === 0) {
			return null;
		} else {
			return cCtx.addGlobalValue(`(v,pt)=>{let _vd=true;${expressions.join("")}return _vd}`);
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	schema.export = (): any => {
		return exportSchema("SchemaOneOf", [ opts ]);
	};

	schema.getTypeDescriptor = (modelNode) => {
		const valueTypeDesc = {};

		for (const k in opts.types) {
			if (modelNode?.value) {
				valueTypeDesc[k as string] = modelNode.value[k].schema.getTypeDescriptor(modelNode.value[k]);
			} else {
				valueTypeDesc[k as string] = opts.types[k].value.getTypeDescriptor();
			}
		}

		return TypeDescObject({
			props: {
				type: TypeDescString({
					label: "Type"
				}),
				value: TypeDescObject({
					label: "Value",
					props: valueTypeDesc
				})
			},
			label: opts.label,
			description: opts.description,
			example: opts.example,
			tags: opts.tags
		});
	};

	schema.getChildNodes = (modelNode) => {
		const children = [];

		if (modelNode.type.type === SCHEMA_VALUE_TYPE.CONST) {
			const typeKey = modelNode.type.constant.value;

			if (modelNode.value[typeKey] !== undefined) {
				children.push({
					key: "value." + typeKey,
					node: modelNode.value[typeKey]
				});
			}
		} else {
			for (const k in modelNode.value) {
				children.push({
					key: "value." + k,
					node: modelNode.value[k]
				});
			}
		}

		return children;
	};

	// Validate schema declaration
	if (!(opts.types instanceof Object) || Object.keys(opts.types).length === 0) {
		throw new SchemaDeclarationError(
			schema.name,
			schema.opts,
			`Schema must have at least one type defined.`
		);
	}

	if (!Object.keys(opts.types).includes(opts.defaultType as string)) {
		throw new SchemaDeclarationError(
			schema.name,
			schema.opts,
			`Invalid default type '${opts.defaultType}'. Must be one of [ ${Object.keys(opts.types).join(
				", "
			)} ].`
		);
	}

	return schema;
}

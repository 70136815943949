/**
 * QR Code HAE component
 *
 * @package hae-ext-components-base
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import React, { useEffect } from "react";

import {
	Type,
	defineElementaryComponent,
	BP,
	createSubScope,
	TGetBlueprintSchemaSpec,
	ISchemaConstObject
} from "@hexio_io/hae-lib-blueprint";

import { termsEditor } from "../../terms";
import {
	ClassList,
	propGroups,
	THAEComponentDefinition,
	THAEComponentReact
} from "@hexio_io/hae-lib-components";

import { BrowserMultiFormatReader, IScannerControls } from '@zxing/browser'
import { DecodeHintType, Result, BarcodeFormat } from "@zxing/library";

// const idsBarCodesTypes = [1,2,3,4,6,7,8,14,15]

enum TReaderStatus {
	INITIALIZING = "INITIALIZING",
	READY = "READY",
	ERROR = "ERROR",
	DISABLED = "DISABLED",
	NO_CAMERA = "NO_CAMERA"
}

interface HAEComponentQrCodeReader_State {
	status: TReaderStatus;
	value: string|null;
	setStatus: (state: TReaderStatus) => void;
	setValue: (value: string|null) => void;
	clearValue: () => void;
}

const HAEComponentQrCodeReader_Props = {
	cameraPreference: BP.Prop(
		BP.OneOf({
			...termsEditor.schemas.qrBarReader.cameraPreference,
			typeValueOpts: {
				...termsEditor.schemas.qrBarReader.cameraPreference
			},	
			types: {
				FRONT: {
					...termsEditor.schemas.qrBarReader.cameraPreferenceFront,
					value: BP.Void({})
				},
				REAR: {
					...termsEditor.schemas.qrBarReader.cameraPreferenceRear,
					value: BP.Void({})
				},
				FIRST: {
					...termsEditor.schemas.qrBarReader.cameraPreferenceFirst,
					value: BP.Void({})
				},
				LAST: {
					...termsEditor.schemas.qrBarReader.cameraPreferenceLast,
					value: BP.Void({})
				},
				CUSTOM: {
					...termsEditor.schemas.qrBarReader.cameraPreferenceCustom,
					value: BP.Object({
						props: {
							cameraIndex: BP.Prop(
								BP.Integer({
									...termsEditor.schemas.qrBarReader.cameraIndex,
									constraints: {
										min: 0,
										required: true
									},
									default: 0
								})
							)
						}
					})
				}
			},
			constraints: {
				required: true
			},
			defaultType: "REAR"
		}),
		5,
		propGroups.common
	),

	allowedFormats: BP.Prop(
		BP.Object({
			...termsEditor.schemas.qrBarReader.allowedFormats,
			props: {
				/** QR Code 2D barcode format. */
				QR_CODE: BP.Prop(
					BP.Boolean({
						label: "QR Code 2D",
						default: true,
					})
				),
				/** Aztec 2D barcode format. */
				AZTEC: BP.Prop(
					BP.Boolean({
						label: "Aztec 2D",
						default: true,
					})
				),
				/** CODABAR 1D format. */
				CODABAR: BP.Prop(
					BP.Boolean({
						label: "Codabar 1D",
						default: true,
					})
				),
				/** Code 39 1D format. */
				CODE_39: BP.Prop(
					BP.Boolean({
						label: "Code 39 1D",
						default: true,
					})
				),
				/** Code 93 1D format. */
				CODE_93: BP.Prop(
					BP.Boolean({
						label: "Code 93 1D",
						default: true,
					})
				),
				/** Code 128 1D format. */
				CODE_128: BP.Prop(
					BP.Boolean({
						label: "Code 128 1D",
						default: true,
					})
				),
				/** Data Matrix 2D barcode format. */
				DATA_MATRIX: BP.Prop(
					BP.Boolean({
						label: "Data Matrix 2D",
						default: true,
					})
				),
				/** EAN-8 1D format. */
				EAN_8: BP.Prop(
					BP.Boolean({
						label: "EAN-8 1D",
						default: true,
					})
				),
				/** EAN-13 1D format. */
				EAN_13: BP.Prop(
					BP.Boolean({
						label: "EAN-13 1D",
						default: true,
					})
				),
				/** ITF (Interleaved Two of Five) 1D format. */
				ITF: BP.Prop(
					BP.Boolean({
						label: "ITF 1D",
						default: true,
					})
				),
				/** MaxiCode 2D barcode format. */
				MAXICODE: BP.Prop(
					BP.Boolean({
						label: "MaxiCode 2D",
						default: true,
					})
				),
				/** PDF417 format. */
				PDF_417: BP.Prop(
					BP.Boolean({
						label: "PDF417 2D",
						default: true,
					})
				),
				/** RSS 14 */
				RSS_14: BP.Prop(
					BP.Boolean({
						label: "RSS 14",
						default: true,
					})
				),
				/** RSS EXPANDED */
				RSS_EXPANDED: BP.Prop(
					BP.Boolean({
						label: "RSS Expanded",
						default: true,
					})
				),
				/** UPC-A 1D format. */
				UPC_A: BP.Prop(
					BP.Boolean({
						label: "UPC-A 1D",
						default: true,
					})
				),
				/** UPC-E 1D format. */
				UPC_E: BP.Prop(
					BP.Boolean({
						label: "UPC-E 1D",
						default: true,
					})
				),
				/** UPC/EAN extension format. Not a stand-alone format. */
				UPC_EAN_EXTENSION: BP.Prop(
					BP.Boolean({
						label: "UPC/EAN Extension",
						default: true,
					})
				)
			}
		}),
		10,
		propGroups.common
	),

	enhancedAccuracy: BP.Prop(
		BP.Boolean({
			...termsEditor.schemas.qrBarReader.enhancedAccuracy,
			default: false
		}),
		20,
		propGroups.common
	),

	enabled: BP.Prop(
		BP.Boolean({
			...termsEditor.schemas.field.enabled,
			default: true,
			fallbackValue: true,
			constraints: {
				required: true
			}
		}),
		10,
		propGroups.state
	)
};

const HAEComponentQrCodeReader_Events = {
	scaned: {
		...termsEditor.schemas.qrBarReader.events.scaned
	}
};

const HAEComponentQrCode_Definition = defineElementaryComponent<
	typeof HAEComponentQrCodeReader_Props,
	HAEComponentQrCodeReader_State,
	typeof HAEComponentQrCodeReader_Events
>({
	...termsEditor.components.qrBarReader.component,

	name: "codeReader",

	category: "form",

	icon: "mdi/qrcode-scan",

	events: HAEComponentQrCodeReader_Events,

	docUrl: "...",

	order: 220,

	props: HAEComponentQrCodeReader_Props,

	resolve: (spec, state, updateStateAsync) => {
		return (
			state ?? {
				status: TReaderStatus.INITIALIZING,
				value: null,
				setStatus: (status: TReaderStatus) => {
					updateStateAsync((prevState) => ({ ...prevState, status: status }));
				},
				setValue: (value: string|null) => {
					updateStateAsync((prevState) => ({ ...prevState, value: value }));
				},
				clearValue: () => {
					updateStateAsync((prevState) => ({ ...prevState, value: null }));
				}
			}
		);
	},

	getScopeData: (spec, state) => {
		return {
			status: state.status,
			value: state.value,
			clearValue: state.clearValue
		};
	},

	getScopeType: () => {
		return Type.Object({
			props: {}
		});
	}
});

function getFormatArrayFromProp(
	allowedFormats: TGetBlueprintSchemaSpec<ISchemaConstObject<typeof HAEComponentQrCodeReader_Props>>["allowedFormats"]
) {
	const formats: BarcodeFormat[] = [];

	if (allowedFormats.AZTEC) formats.push(BarcodeFormat.AZTEC);
	if (allowedFormats.CODABAR) formats.push(BarcodeFormat.CODABAR);
	if (allowedFormats.CODE_39) formats.push(BarcodeFormat.CODE_39);
	if (allowedFormats.CODE_93) formats.push(BarcodeFormat.CODE_93);
	if (allowedFormats.CODE_128) formats.push(BarcodeFormat.CODE_128);
	if (allowedFormats.DATA_MATRIX) formats.push(BarcodeFormat.DATA_MATRIX);
	if (allowedFormats.EAN_8) formats.push(BarcodeFormat.EAN_8);
	if (allowedFormats.EAN_13) formats.push(BarcodeFormat.EAN_13);
	if (allowedFormats.ITF) formats.push(BarcodeFormat.ITF);
	if (allowedFormats.MAXICODE) formats.push(BarcodeFormat.MAXICODE);
	if (allowedFormats.PDF_417) formats.push(BarcodeFormat.PDF_417);
	if (allowedFormats.RSS_14) formats.push(BarcodeFormat.RSS_14);
	if (allowedFormats.RSS_EXPANDED) formats.push(BarcodeFormat.RSS_EXPANDED);
	if (allowedFormats.UPC_A) formats.push(BarcodeFormat.UPC_A);
	if (allowedFormats.UPC_E) formats.push(BarcodeFormat.UPC_E);
	if (allowedFormats.UPC_EAN_EXTENSION) formats.push(BarcodeFormat.UPC_EAN_EXTENSION);

	return formats;
}

function getDeviceFromCameraPreferenceProp(
	preference: TGetBlueprintSchemaSpec<ISchemaConstObject<typeof HAEComponentQrCodeReader_Props>>["cameraPreference"],
	devices: MediaDeviceInfo[]
): MediaDeviceInfo | undefined {
	switch (preference.type) {
		case "FRONT": {
			return devices.find(
				(device) => (/front|face/gi).test(device.label.toLowerCase())
			) || devices[0];
		}
		case "REAR": {
			return devices.find(
				(device) => (/back|trás|rear|traseira|environment|ambiente/gi).test(device.label.toLowerCase())
			) || devices[devices.length - 1];
		}
		case "FIRST": {
			return devices[0];
		}
		case "LAST": {
			return devices[devices.length - 1];
		}
		case "CUSTOM": {
			return devices[preference.value.CUSTOM.cameraIndex] || devices[0];
		}
		default: {
			return devices[0];
		}
	}
}

const HAEComponentQrCodeReader_React: THAEComponentReact<typeof HAEComponentQrCode_Definition> = ({
	props,
	componentInstance,
	reactComponentClassList
}) => {
	const { enabled, cameraPreference, enhancedAccuracy } = props;
	const allowedFormats = getFormatArrayFromProp(props.allowedFormats);

	// Init the reader
	useEffect(() => {
		if (!enabled) {
			componentInstance.state.setStatus(TReaderStatus.DISABLED);
			return;
		}

		let controls: IScannerControls;

		const setupScanner = async () => {
			const handleData = (result: Result) => {
				if (result) {
					componentInstance.state.setValue(result?.getText() ?? null);

					if (componentInstance.eventEnabled.scaned) {
						componentInstance.eventTriggers.scaned((parentScope) => createSubScope(parentScope, { value: result.getText() }))
					}
				}
			};

			const devices = await BrowserMultiFormatReader.listVideoInputDevices();

			if (devices.length === 0) {
				return null;
			}

			// Selected device by preference
			const selectedDeviceId = getDeviceFromCameraPreferenceProp(cameraPreference, devices)?.deviceId;

			const hints = new Map();
			hints.set(DecodeHintType.POSSIBLE_FORMATS, allowedFormats);

			if (enhancedAccuracy) {
				hints.set(DecodeHintType.TRY_HARDER, true);
			}

			const codeReader = new BrowserMultiFormatReader(hints);
			return codeReader.decodeFromVideoDevice(selectedDeviceId, componentInstance.safePath.join("-"), handleData);
		};

		setupScanner().then((scannerControls) => {
			if (scannerControls) {
				controls = scannerControls;
				componentInstance.state.setStatus(TReaderStatus.READY);
			} else {
				controls = null;
				componentInstance.state.setStatus(TReaderStatus.NO_CAMERA);
			}
		}).catch((err) => {
			console.error(err);
			componentInstance.state.setStatus(TReaderStatus.ERROR);
		});

		return () => {
			if (controls) {
				controls.stop();
			}
		};
	}, [ enabled, componentInstance, allowedFormats.join(","), JSON.stringify(cameraPreference), enhancedAccuracy ]);

	const { classList } = ClassList.getElementClassListAndIdClassName(
		"cmp-qr-bar-code-reader", componentInstance.safePath, { componentClassList: reactComponentClassList }
	);

	return <div className={classList.toClassName()}>
		{
			enabled
				? <video id={componentInstance.safePath.join("-")} />
				: null
		}
	</div>;
};

export const HAEComponentQrCodeReader: THAEComponentDefinition<typeof HAEComponentQrCode_Definition> = {
	...HAEComponentQrCode_Definition,
	reactComponent: HAEComponentQrCodeReader_React
};

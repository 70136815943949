/**
 * hae-ext-components-pro
 *
 * Hexio App Engine: Set of pro components
 *
 * @package hae-ext-components-pro
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { fluentUi as fluentUiEditorComponent } from "./editor/components/FluentThemeProvider";
import { timePicker as timePickerComponent } from "./editor/components/TimePicker";
import { datePicker as datePickerComponent } from "./editor/components/DatePicker";

import { timePicker as timePickerEditorSchema } from "./editor/schemas/TimePicker";
import { datePicker as datePickerEditorSchema } from "./editor/schemas/DatePicker";

export const termsEditor = {
	components: {
		fluentUi: fluentUiEditorComponent,
		datePicker: datePickerComponent,
		timePicker: timePickerComponent
	},

	schemas: {
		datePicker: datePickerEditorSchema,
		timePicker: timePickerEditorSchema
	}
};

export const termsRuntime = {
	components: {}
};
